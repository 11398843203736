import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import dayjs from "dayjs";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import AttachmentIcon from "@mui/icons-material/Attachment";
import Tooltip from "@mui/material/Tooltip";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import "./ticket-details.css";

const TicketDetailsComponent = (props) => {
  const [TicketDetails, setTicketDetails] = useState({});
  const [ticketSubject, setTicketSubject] = useState("");
  const [ticketAnswers, setTicketAnswers] = useState([]);
  const [ticketQuestion, setTicketQuestion] = useState("");
  const [nextReply, setNextReply] = useState("");
  const [ticketId, setTicketId] = useState("");
  const [isLoading, setIsLoding] = useState(false);
  const [replyAttachment, setReplyAttachment] = useState("");
  const [isPrevAttch, SetIsPrevAttch] = useState(false);
  const [prevAttachment, setPrevAttachment] = useState("");

  const [openImagePreview, setOpenImagePreview] = useState(false);

  const userData = JSON.parse(localStorage.getItem("userdata"));

  const handleClose = () => {
    console.log("second");
    console.log(isPrevAttch);
    setOpenImagePreview(false);
  };

  const previewAttachment = (attachment) => {
    SetIsPrevAttch(true);
    setOpenImagePreview(true);
    setPrevAttachment(attachment);
  };

  const closeDialogForPrevAttc = () => {
    console.log("first");
    setOpenImagePreview(false);
    setPrevAttachment("");
    SetIsPrevAttch(false);
  };

  const fetchTicketDetails = async () => {
    try {
      setIsLoding(true);
      const res = await axios.get(`https://api.ykfuture.in/api/v1/ticket/${props.ticketId}/get-ticket-with-answer`);

      if (res.status === 200) {
        setTicketId(res.data.ticket._id);
        setTicketAnswers(res.data.ticketAnswers);
        setTicketSubject(res.data.ticket.subject);
        setTicketQuestion(res.data.ticket.message);
        setTicketDetails(res.data.ticket);
        setIsLoding(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const makeAnswers = async (e) => {
    try {
      e.preventDefault();

      const requestData = new FormData();

      requestData.append("associatedTicket", ticketId);
      requestData.append("message", nextReply);
      requestData.append("answeredBy", userData.role);
      requestData.append("ticket-answer", replyAttachment);

      const response = await axios.post("https://api.ykfuture.in/api/v1/ticketanswers/make-answer", requestData);

      if (response.status === 201) {
        fetchTicketDetails();
        setNextReply("");
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchTicketDetails();
  }, []);

  return (
    <div className="ticket_details-container">
      <div className="ticket-subject">
        {isLoading && <CircularProgress color="secondary" disableShrink size={20} />}
        {!isLoading && <p className="subject">{ticketSubject}</p>}
      </div>

      <div className="question-area">
        {isLoading && <CircularProgress color="secondary" disableShrink size={20} />}
        {!isLoading && <p className="question">{ticketQuestion}</p>}
      </div>

      <div className="answers-area">
        {!isLoading && ticketAnswers.length === 0 && <div className="no-answers">No Replies Found</div>}

        {isLoading && (
          <div className="no-answers">
            <CircularProgress color="secondary" disableShrink />
          </div>
        )}

        {!isLoading &&
          ticketAnswers.length >= 0 &&
          ticketAnswers.map((row) => (
            <div className={row.answeredBy === "admin" ? "ticket-answer" : "reverse-answer"} key={row._id}>
              <div className="user-img-container">
                <span>{row.answeredBy === "admin" ? "Ad" : TicketDetails.raisedBy.userName.slice(0, 3)}</span>
              </div>
              <div className="msg-container">
                <p className="msg">{row.message}</p>

                {row.attachment ? (
                  <div className="msg-date">
                    <Tooltip title="Click To See The Attachment">
                      <IconButton size="small" onClick={() => previewAttachment(row.attachment)}>
                        <AttachmentIcon color="primary" fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </div>
                ) : (
                  ""
                )}
                <p className="msg-date">{dayjs(row.createdOn).format("DD MMM YYYY HH:mm ")}</p>
              </div>
            </div>
          ))}
      </div>

      <div className="reply-area">
        <form action="" onSubmit={makeAnswers}>
          <div className="textbox-container">
            <textarea
              name="next-reply"
              id="next-reply"
              placeholder="Type Your Message"
              value={nextReply}
              onChange={(e) => {
                setNextReply(e.target.value);
              }}
              style={{ width: replyAttachment ? "80%" : "75%" }}
            ></textarea>
            {replyAttachment ? (
              <Tooltip title="Preview Image">
                <IconButton
                  onClick={() => {
                    setOpenImagePreview(true);
                  }}
                >
                  <VisibilityOutlinedIcon color="primary" />
                </IconButton>
              </Tooltip>
            ) : (
              <label htmlFor="attachment">
                <Tooltip title="Upload an attachment">
                  <DriveFolderUploadIcon fontSize="medium" />
                </Tooltip>
              </label>
            )}
            <input
              type="file"
              name="attachment"
              id="attachment"
              style={{ display: "none" }}
              onChange={(e) => {
                setReplyAttachment(e.target.files[0]);
              }}
            />
            <Button variant="outlined" type="submit">
              Submit
            </Button>
          </div>
        </form>
      </div>

      <Dialog open={openImagePreview} onClose={isPrevAttch ? closeDialogForPrevAttc : handleClose}>
        <div
          className="top_of_dialog"
          style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
        >
          <DialogTitle>Preview Attachment</DialogTitle>
          <ClearOutlinedIcon
            style={{ marginRight: "20px", cursor: "pointer" }}
            onClick={isPrevAttch ? closeDialogForPrevAttc : handleClose}
          />
        </div>
        <DialogContent>
          {isPrevAttch && <img src={prevAttachment ? prevAttachment : ""} alt="" style={{ borderRadius: "0%" }} />}

          {!isPrevAttch && (
            <img
              src={replyAttachment ? URL.createObjectURL(replyAttachment) : ""}
              alt=""
              style={{ borderRadius: "0%" }}
            />
          )}

          {/* <img
            src={replyAttachment ? URL.createObjectURL(replyAttachment) : ""}
            alt=""
            style={{ borderRadius: "0%" }}
          /> */}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default TicketDetailsComponent;
