import React, { useState, useEffect } from "react";
import axios from "axios";
import BasicTable from "../Tables/BasicTable";

const EpinPaymentMethods = () => {
  const [epinPaymentMethods, seEpinPaymentMethods] = useState([]);

  const fetchAllEpinPaymentMethods = async () => {
    await axios
      .get("https://api.ykfuture.in/api/v1/epinpaymentmethod/getall")
      .then((res) => {
        seEpinPaymentMethods(res.data.ePinPaymentMethods[0]);
      })
      .catch((err) => console.log(err));
  };

  // update cash method
  const updateCash = async (id, action) => {
    try {
      await axios({
        method: "PATCH",
        url: `https://api.ykfuture.in/api/v1/epinpaymentmethod/${id}`,
        data: {
          cash: action,
        },
      });
      fetchAllEpinPaymentMethods();
    } catch (err) {
      console.log(err);
    }
  };

  // update card method
  const updateCard = async (id, action) => {
    try {
      await axios({
        method: "PATCH",
        url: `https://api.ykfuture.in/api/v1/epinpaymentmethod/${id}`,
        data: {
          card: action,
        },
      });
      fetchAllEpinPaymentMethods();
    } catch (err) {
      console.log(err);
    }
  };

  // update UPI method
  const updateUPI = async (id, action) => {
    try {
      await axios({
        method: "PATCH",
        url: `https://api.ykfuture.in/api/v1/epinpaymentmethod/${id}`,
        data: {
          upi: action,
        },
      });
      fetchAllEpinPaymentMethods();
    } catch (err) {
      console.log(err);
    }
  };

  // update Net Banking method
  const updateNetBanking = async (id, action) => {
    try {
      await axios({
        method: "PATCH",
        url: `https://api.ykfuture.in/api/v1/epinpaymentmethod/${id}`,
        data: {
          netBanking: action,
        },
      });
      fetchAllEpinPaymentMethods();
    } catch (err) {
      console.log(err);
    }
  };

  // update Bank Transfer method
  const updateBankTransfer = async (id, action) => {
    try {
      await axios({
        method: "PATCH",
        url: `https://api.ykfuture.in/api/v1/epinpaymentmethod/${id}`,
        data: {
          bankTransfer: action,
        },
      });
      fetchAllEpinPaymentMethods();
    } catch (err) {
      console.log(err);
    }
  };

  // update wallets method
  const updateWallets = async (id, action) => {
    try {
      await axios({
        method: "PATCH",
        url: `https://api.ykfuture.in/api/v1/epinpaymentmethod/${id}`,
        data: {
          wallets: action,
        },
      });
      fetchAllEpinPaymentMethods();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchAllEpinPaymentMethods();
  }, []);
  return (
    <div className="payment-methods-container">
      <BasicTable
        type="epin"
        width="100%"
        heading="EPin Payment Methods"
        data={epinPaymentMethods}
        cash={updateCash}
        card={updateCard}
        upi={updateUPI}
        netBanking={updateNetBanking}
        bankTransfer={updateBankTransfer}
        wallets={updateWallets}
      />
    </div>
  );
};

export default EpinPaymentMethods;
