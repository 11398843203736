import React from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import Navigation from "../../components/navigation/Navigation";
import InvoiceComponent from "../../components/Invoice/InvoiceComponent";

const Invoice = () => {
  return (
    <div id="profilepage" className="page">
      <Sidebar />
      <div className="right-container">
        <Navigation />
        <div className="rest">
          <div className="invoice-container">
            <InvoiceComponent />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
