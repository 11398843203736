import React from "react";
import Board from "../../components/Trees/Board";
import Sidebar from "../../components/Sidebar/Sidebar";
import Navigation from "../../components/navigation/Navigation";
import "./BoardPage.css";
import { useParams } from "react-router-dom";

const BoardPage = () => {
  const { id } = useParams();
  return (
    <div id="boardpage" className="page">
      <Sidebar />
      <div className="right-container">
        <Navigation />
        <div className="rest">
          <Board boardId={id} />
        </div>
      </div>
    </div>
  );
};

export default BoardPage;
