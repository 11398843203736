import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "@mui/material/Button";
import "./addUserForm.css";

const NewSubscriptionForm = () => {
  const history = useNavigate();
  const userId = localStorage.getItem("id");
  const userData = JSON.parse(localStorage.getItem("userdata"));

  const [notSubscribed, setNotSubscribed] = useState([]);
  const [pausedSubscriptions, setPausedSubscriptions] = useState([]);
  const [activeSubscriptions, setActiveSubscriptions] = useState([]);
  const [deactiveSubscriptions, setDeactiveSubscriptions] = useState([]);

  const [epins, setEpins] = useState([]);
  const [selectedDivs, setSelectedDivs] = useState([]);
  const [epinsAmount, setEpinsAmount] = useState([]);
  const [selectEpinError, setSelectEpinError] = useState(false);
  const [selectEpinErrorMsg, setSelectEpinErrorMsg] = useState("Please Select Epins");

  const [subscriptionId, setSubscriptionId] = useState("");
  const [subscriptionAmount, setSubscriptionAmount] = useState();

  const [isProcessing, setIsProcessing] = useState(false);

  const newArry = useRef(null);

  // fetch all epin of the curent user

  const fetchEpinHandler = async (id) => {
    try {
      const res = await axios({
        method: "GET",
        url: "https://api.ykfuture.in/api/v1/epins/getepinsbyuser",
        headers: {
          id: userId,
        },
      });

      if (res.status === 200) {
        const filteredData = res.data.epins.filter((item) => item.isActive === true);
        setEpins(filteredData);
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const subscriptionDetailsFetcher = async (id) => {
    try {
      const response = await fetch(`https://api.ykfuture.in/api/v1/subscription/${id}`);
      if (!response.ok) {
        throw new Error("Something Went Wrong");
      }
      const data = await response.json();

      setSubscriptionId(data.subscription._id);
      setSubscriptionAmount(data.subscription.subscriptionAmount);
    } catch (err) {
      toast.error(err.response.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const handleDivClick = (divId, epin_Balance) => {
    if (selectedDivs.includes(divId)) {
      setSelectedDivs(selectedDivs.filter((id) => id !== divId));
      setEpinsAmount((prevEpinsAmount) => {
        const index = prevEpinsAmount.indexOf(epin_Balance);
        if (index !== -1) {
          return prevEpinsAmount.slice(0, index).concat(prevEpinsAmount.slice(index + 1));
        }
        return prevEpinsAmount;
      });
    } else {
      setSelectedDivs([...selectedDivs, divId]);
      setEpinsAmount([...epinsAmount, epin_Balance]);
    }
  };

  // fetching Data from Database
  const fetchSubscriptionsHandler = useCallback(async () => {
    try {
      const response = await axios(`https://api.ykfuture.in/api/v1/user/subscriptions/${userId}`);

      setNotSubscribed(response.data.unsubscribedSubscriptions);
    } catch (err) {
      toast.error(err.response.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  }, [userId]);

  useEffect(() => {
    const sum = epinsAmount.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    if (subscriptionAmount <= sum) {
      setSelectEpinError(false);
      let remainingAmount = subscriptionAmount;
      newArry.current = epinsAmount.map((el) => {
        const subtractedValue = Math.min(remainingAmount, el);
        remainingAmount -= subtractedValue;
        return el - subtractedValue;
      });
    } else if (subscriptionAmount > sum) {
      setSelectEpinError(true);
      setSelectEpinErrorMsg("Insufficient Balance !! Please select more Epins or Buy another");
    }
  }, [epinsAmount, subscriptionAmount]);

  const redeemEpin = async () => {
    try {
      const updatedData = {
        user: localStorage.getItem("id"),
        selectedDivs,
        epinsAmount,
        newArry: newArry.current,
      };

      await axios.patch("https://api.ykfuture.in/api/v1/epins/redeemEpin", updatedData);
    } catch (err) {
      toast.error(err.response.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  // create user function
  const buyNewSubscriptionHandler = async (e) => {
    e.preventDefault();
    try {
      setIsProcessing(true);

      const response = await axios.post(`https://api.ykfuture.in/api/v1/auth/${userId}/buynewsubscription`, {
        subscriptionId: subscriptionId,
        isNew: userData.isActive ? false : true,
      });

      if (response.status === 200) {
        toast.success(`Package Bought Sucessfully`, {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        redeemEpin();

        setTimeout(() => {
          history("/");
        }, 2000);
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const findSubscriptionForRenewal = async (e) => {
    try {
      const userId = localStorage.getItem("id");

      const res = await axios.get(`https://api.ykfuture.in/api/v1/user/${userId}/subscriptions`);

      setActiveSubscriptions(res.data.activeSubscriptions);
      setDeactiveSubscriptions(res.data.deactiveSubscriptions);
      setPausedSubscriptions(res.data.pausedSubscriptions);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchSubscriptionsHandler();
    fetchEpinHandler();
    findSubscriptionForRenewal();
  }, [fetchSubscriptionsHandler]);

  return (
    <>
      <form action="" className="addUser" onSubmit={buyNewSubscriptionHandler}>
        <div className="form-part">
          <p className="part-heading">New Subscriptions To Buy</p>
          <div className="subscription-container">
            {notSubscribed.length === 0 && <span>No Subscription Plan Available</span>}

            {notSubscribed.map((data) => (
              <label className="package-card" key={data._id}>
                <input
                  name="package"
                  className="radio"
                  type="radio"
                  value={data._id}
                  onChange={(e) => subscriptionDetailsFetcher(e.target.value)}
                  required
                />
                <span className="plan-details" aria-hidden="true">
                  <span className="plan-type">{data.subscriptionName}</span>
                  <span className="plan-cost">{data.subscriptionAmount}</span>
                  <span className="details">
                    <span className="product-details">
                      <span className="Subscription-validity">
                        Subscription Validity - {data.subscriptionValidity} Days
                      </span>
                      <span className="referral-commision">Referral Commision - {data.referralCommision}</span>
                    </span>
                    <span className="other-details">
                      <span className="gold-limit">Gold Limit - {data.goldLimit}</span>
                      <span className="gold-discount">Gold Disount - {data.goldDiscount}</span>
                    </span>
                  </span>
                </span>
              </label>
            ))}

            {pausedSubscriptions.length > 0 && <p className="part-heading">Subscriptions For Renewal</p>}
            {pausedSubscriptions.map((data) => (
              <label className="package-card" key={data._id}>
                <input
                  name="package"
                  className="radio"
                  type="radio"
                  value={data._id}
                  onChange={(e) => subscriptionDetailsFetcher(e.target.value)}
                  required
                />
                <span className="plan-details" aria-hidden="true">
                  <span className="plan-type">{data.subscriptionName}</span>
                  <span className="plan-cost">{data.boardSplittingCharges}</span>
                  <span className="details">
                    <span className="product-details">
                      <span className="Subscription-validity">
                        Subscription Validity - {data.subscriptionValidity} Days
                      </span>
                      <span className="referral-commision">Referral Commision - {data.referralCommision}</span>
                    </span>
                    <span className="other-details">
                      <span className="gold-limit">Gold Limit - {data.goldLimit}</span>
                      <span className="gold-discount">Gold Disount - {data.goldDiscount}</span>
                    </span>
                  </span>
                </span>
              </label>
            ))}
          </div>
        </div>

        <div className="form-part">
          <p className="part-heading">Please Select an E-pin</p>
          <ul className="input-group">
            {selectEpinError ? (
              <p style={{ color: "red", fontWeight: "bold", margin: "5px" }}>{selectEpinErrorMsg}</p>
            ) : (
              ""
            )}
            {epins.map((epin) => (
              <li
                key={epin._id}
                onClick={() => handleDivClick(epin._id, epin.balance)}
                className={selectedDivs.includes(epin._id) ? "selectable_div selected" : "selectable_div"}
              >
                {epin.uniqueId}
                <p style={{ marginTop: 10 }}>
                  <b>Balance:{epin.balance}</b>
                </p>
              </li>
            ))}
          </ul>

          <div className="input-group">
            {selectEpinError && (
              <Button type="submit" color="secondary" variant="outlined" disabled>
                Submit
              </Button>
            )}

            {!selectEpinError && !isProcessing && (
              <Button type="submit" color="secondary" variant="outlined">
                Submit
              </Button>
            )}

            {isProcessing && (
              <Button type="submit" color="secondary" variant="outlined" disabled>
                Processing
              </Button>
            )}

            <Button onClick={() => history(-1)} color="secondary">
              Back
            </Button>
          </div>
        </div>
      </form>
      <ToastContainer />
    </>
  );
};

export default NewSubscriptionForm;
