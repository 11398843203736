import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Button } from "@mui/material";
import Navigation from "../../components/navigation/Navigation";
import Sidebar from "../../components/Sidebar/Sidebar";
import UserDataTable from "../../components/Tables/UserDataTable";
import AddIcon from "@mui/icons-material/Add";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Packages = () => {
  // form states
  const [subscriptionID, setSubscriptionID] = useState("");
  const [subscriptionName, setSubscriptionName] = useState("");
  const [subscriptionAmount, setSubscriptionAmount] = useState("");
  const [subscriptionValidity, setSubscriptionValidity] = useState("");
  const [goldLimit, setGoldLimit] = useState("");
  const [goldDiscount, setGoldDiscount] = useState("");
  const [referralCommision, setReferralCommision] = useState("");
  const [membersLimit, setMembersLimit] = useState("");
  const [boardSplittingAmount, setBoardSplittingAmount] = useState();
  const [rewardPoint, setRewardPoint] = useState("");
  const [perSectionIncome, setPerSectionIncome] = useState("");

  const [subscription, setSubscription] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditModal(false);
  };

  // fetching Data from Database
  const fetchSubscriptionsHandler = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    const token = localStorage.getItem("token");
    try {
      const response = await fetch("https://api.ykfuture.in/api/v1/subscription/allsubscriptions", {
        headers: {
          token: token,
        },
      });
      if (!response.ok) {
        throw new Error("Something Went Wrong");
      }
      const data = await response.json();

      setSubscription(data.subscriptions);
    } catch (Error) {
      setError(Error.message);
      console.log(Error);
    }
    setIsLoading(false);
  }, []);

  // Create New Subscription
  const subscriptionFormHandler = async (e) => {
    e.preventDefault();
    try {
      const subscriptionData = {
        subscriptionName,
        subscriptionAmount,
        subscriptionValidity,
        goldLimit,
        goldDiscount,
        referralCommision,
        allowedMembersInBoard: membersLimit,
        boardSplittingCharges: boardSplittingAmount,
        rewardPoint,
        sectionIncome: perSectionIncome,
      };

      const id = localStorage.getItem("id");

      const data = await axios({
        method: "POST",
        url: "https://api.ykfuture.in/api/v1/subscription/createnewsubscription",
        data: subscriptionData,
        headers: {
          "Content-Type": "application/json",
          adminID: id,
        },
      });

      if (data.statusText === "Created") {
        toast.success("Package created successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }

      setSubscriptionID("");
      setSubscriptionName("");
      setSubscriptionAmount("");
      setSubscriptionValidity("");
      setGoldLimit("");
      setGoldDiscount("");
      setReferralCommision("");
      setBoardSplittingAmount();
      fetchSubscriptionsHandler();
      setOpen(false);
    } catch (err) {
      toast.error(err.response.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  useEffect(() => {
    fetchSubscriptionsHandler();
  }, [fetchSubscriptionsHandler]);

  const dataLogger = async (id) => {
    setEditModal(true);
    try {
      const response = await fetch(`https://api.ykfuture.in/api/v1/subscription/${id}`);

      if (!response.ok) {
        throw new Error("Something Went Wrong");
      }
      const data = await response.json();
      setSubscriptionID(data.subscription._id);
      setSubscriptionName(data.subscription.subscriptionName);
      setSubscriptionAmount(data.subscription.subscriptionAmount);
      setSubscriptionValidity(data.subscription.subscriptionValidity);
      setGoldLimit(data.subscription.goldLimit);
      setGoldDiscount(data.subscription.goldDiscount);
      setReferralCommision(data.subscription.referralCommision);
      setBoardSplittingAmount(data.subscription.boardSplittingCharges);
    } catch (Error) {
      console.log(Error);
    }
    setIsLoading(false);
  };

  // Update a subscription
  const updateSubscriptionHandler = async (e) => {
    e.preventDefault();
    try {
      const subscriptionData = {
        subscriptionName,
        subscriptionAmount,
        subscriptionValidity,
        goldLimit,
        goldDiscount,
        referralCommision,
        boardSplittingAmount,
      };

      const token = localStorage.getItem("token");

      const data = await axios({
        method: "PATCH",
        url: `https://api.ykfuture.in/api/v1/subscription/${subscriptionID}`,
        data: subscriptionData,
        headers: {
          "Content-Type": "application/json",
          token: token,
        },
      });

      if (data.data.status === "success") {
        toast.success("Package Updated successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }

      setSubscriptionID("");
      setSubscriptionName("");
      setSubscriptionAmount("");
      setSubscriptionValidity("");
      setGoldLimit("");
      setGoldDiscount("");
      setReferralCommision("");
      setBoardSplittingAmount();
      fetchSubscriptionsHandler();
      setEditModal(false);
    } catch (err) {
      toast.error(err.response.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  // delete a package
  const deleteSubscriptionHandler = async (id) => {
    try {
      if (window.confirm("are you sure")) {
        const res = await axios({
          method: "DELETE",
          url: `https://api.ykfuture.in/api/v1/subscription/${id}`,
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (res.status === 204) {
          toast.success("Package Deleted successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      }

      fetchSubscriptionsHandler();
    } catch (err) {
      toast.error(err.response.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  return (
    <div id="adduser" className="page">
      <Sidebar />
      <div className="right-container">
        <Navigation />
        <div className="rest">
          <Button
            startIcon={<AddIcon />}
            variant="outlined"
            color="secondary"
            style={{ cursor: "pointer" }}
            onClick={handleClickOpen}
          >
            Add New Subscription
          </Button>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Add A New Subscription</DialogTitle>
            <DialogContent>
              <form action="" onSubmit={subscriptionFormHandler}>
                <div className="dialog-form-part">
                  <div className="input-group">
                    <label htmlFor="name">
                      Subscription Name <span>*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter Subscription Name"
                      name="name"
                      onChange={(e) => {
                        setSubscriptionName(e.target.value);
                      }}
                      required
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="amount">
                      Subscription Amount <span>*</span>
                    </label>
                    <input
                      type="number"
                      inputMode="numeric"
                      placeholder="Enter Amount"
                      name="amount"
                      // onChange={subscriptionAmountHandler}
                      onChange={(e) => {
                        setSubscriptionAmount(e.target.value);
                      }}
                      required
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="validity">
                      Subscription Validity (in months) <span>*</span>
                    </label>
                    <input
                      type="number"
                      placeholder="Enter Validity - in months"
                      name="validity"
                      onChange={(e) => {
                        setSubscriptionValidity(e.target.value);
                      }}
                      required
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="limit">
                      Gold Limit (in grams) <span>*</span>
                    </label>
                    <input
                      type="number"
                      placeholder="Enter Gold Limit in Grams"
                      name="limit"
                      onChange={(e) => {
                        setGoldLimit(e.target.value);
                      }}
                      required
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="discount">
                      Gold Discount (in grams) <span>*</span>
                    </label>
                    <input
                      type="number"
                      placeholder="Enter Gold Discount - in grams"
                      name="discount"
                      onChange={(e) => {
                        setGoldDiscount(e.target.value);
                      }}
                      required
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="commission">Referral Commision (fixed amount)</label>
                    <input
                      type="number"
                      placeholder="Enter Referral Commision - (fixed amount)"
                      name="commission"
                      onChange={(e) => {
                        setReferralCommision(e.target.value);
                      }}
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="members_allowed">
                      Members Allowed <span>*</span>
                    </label>
                    <select
                      id="selectOption"
                      value={membersLimit}
                      onChange={(e) => {
                        setMembersLimit(e.target.value);
                      }}
                      className="select-element"
                      required
                    >
                      <option value="">-- Select --</option>
                      <option value="7">7</option>
                      <option value="13">13</option>
                    </select>
                  </div>

                  <div className="input-group">
                    <label htmlFor="sectional-income">
                      Per Section Income (Half Board Income) <span>*</span>
                    </label>
                    <input
                      type="number"
                      placeholder="Reward to be given after half Board Completion"
                      name="sectional-income"
                      onChange={(e) => {
                        setPerSectionIncome(e.target.value);
                      }}
                      required
                    />
                  </div>

                  <div className="input-group">
                    <label htmlFor="validity">
                      Board Renewal Charges <span>*</span>
                    </label>
                    <input
                      type="number"
                      placeholder="Enter amount to be taken while splitting of board"
                      name="validity"
                      onChange={(e) => {
                        setBoardSplittingAmount(e.target.value);
                      }}
                      required
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="rewardpoint">
                      Reward Point <span>*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Enter rewardpoint"
                      name="rewardpoint"
                      onChange={(e) => {
                        setRewardPoint(e.target.value);
                      }}
                      required
                    />
                  </div>
                  <div className="input-group">
                    <Button onClick={handleClose} color="error" style={{ marginRight: "10px" }}>
                      Cancel
                    </Button>
                    <Button type="submit" color="secondary">
                      Submit
                    </Button>
                  </div>
                </div>
              </form>
            </DialogContent>
          </Dialog>

          <Dialog open={editModal} onClose={handleClose}>
            <DialogTitle>Edit Subscription</DialogTitle>
            <DialogContent>
              <form action="" onSubmit={updateSubscriptionHandler}>
                <div className="dialog-form-part">
                  <div className="input-group">
                    <label htmlFor="subscription-name">Subscription Name</label>
                    <input
                      type="text"
                      value={subscriptionName}
                      name="subscription-name"
                      id="subscription-name"
                      disabled
                      onChange={(e) => {
                        setSubscriptionName(e.target.value);
                      }}
                    />

                    <label htmlFor="subscription-amount">Subscription Amount</label>
                    <input
                      type="text"
                      value={subscriptionAmount}
                      name="subscription-amount"
                      id="subscription-amount"
                      onChange={(e) => {
                        setSubscriptionAmount(e.target.value);
                      }}
                    />
                  </div>

                  <div className="input-group">
                    <label htmlFor="subscription-validity">Subscription Validity</label>
                    <input
                      type="text"
                      name="subscription-validity"
                      id="subscription-validity"
                      value={subscriptionValidity}
                      onChange={(e) => {
                        setSubscriptionValidity(e.target.value);
                      }}
                    />

                    <label htmlFor="gold-limit">Gold Limit</label>
                    <input
                      type="text"
                      name="gold-limit"
                      id="gold-limit"
                      value={goldLimit}
                      onChange={(e) => {
                        setGoldLimit(e.target.value);
                      }}
                    />
                  </div>

                  <div className="input-group">
                    <label htmlFor="gold-discount">Gold Discount</label>
                    <input
                      type="text"
                      value={goldDiscount}
                      name="gold-discount"
                      id="gold-discount"
                      onChange={(e) => {
                        setGoldDiscount(e.target.value);
                      }}
                    />

                    <label htmlFor="referral-commision">Referral Commision</label>
                    <input
                      type="text"
                      name="referral-commision"
                      id="referral-commision"
                      value={referralCommision}
                      onChange={(e) => {
                        setReferralCommision(e.target.value);
                      }}
                    />
                  </div>

                  <div className="input-group">
                    <label htmlFor="validity">
                      Amount (for board splitting ) <span>*</span>
                    </label>
                    <input
                      type="number"
                      placeholder="Enter amount to be taken while splitting of board"
                      name="validity"
                      value={boardSplittingAmount}
                      onChange={(e) => {
                        setBoardSplittingAmount(e.target.value);
                      }}
                      required
                    />
                  </div>

                  <div className="input-group">
                    <Button onClick={handleClose} color="error" style={{ marginRight: "10px" }}>
                      Cancel
                    </Button>
                    <Button type="submit">Submit</Button>
                  </div>
                </div>
              </form>
            </DialogContent>
          </Dialog>
          <div className="data-table-container">
            {!isLoading && subscription.length > 0 && (
              <UserDataTable
                name="Subscription Name "
                columnOneHeading="Subscription Amount "
                columnTwoHeading="Subscription Validity "
                columnThreeHeading="Gold Limit "
                columnFourHeading="Gold Discount "
                columnFiveHeading="Refferal Commision "
                columnSixHeading="Per Section Income "
                data={subscription}
                logging={dataLogger}
                deletion={deleteSubscriptionHandler}
              />
            )}
          </div>

          {!isLoading && subscription.length === 0 && !error && <p>Found No Subscriptions</p>}

          {!isLoading && error && <p>{error}</p>}

          {isLoading && <CircularProgress color="secondary" disableShrink />}
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default Packages;
