import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Sidebar from "../../components/Sidebar/Sidebar";
import Navigation from "../../components/navigation/Navigation";
import Footer from "../../components/Footer/Footer";
import { Button } from "@mui/material";
import BasicTable from "../../components/Tables/BasicTable";

const Epins = () => {
  const [showEpinRequestsOptions, setShowEpinRequestsOptions] = useState(false);
  const [showAvailableEpins, setShowAvailableEpins] = useState(false);
  const [showTransaction, setShowTransaction] = useState(false);
  const [showEpinRequestData, setShowEPinRequestsData] = useState(false);

  const [availableEpins, setAvailableEPins] = useState([]);
  const [epinTransactions, setEpinTransactions] = useState([]);

  //
  const [requestTitle, setRequestTitle] = useState("Epins");
  const [epinRequestData, setEPinRequestsData] = useState([]);

  const showEPinsHandler = () => {
    setShowEpinRequestsOptions(true);
    setShowAvailableEpins(false);
    setShowTransaction(false);
    setShowEPinRequestsData(false);
  };

  const availableEPinsHandler = async () => {
    try {
      setShowEpinRequestsOptions(false);
      setShowAvailableEpins(true);
      setShowEPinRequestsData(false);

      const res = await axios({
        method: "GET",
        url: `https://api.ykfuture.in/api/v1/epins/getepinsbyuser?isActive=true`,

        headers: {
          id: localStorage.getItem("id"),
        },
      });

      setAvailableEPins(res.data.epins);
    } catch (err) {
      console.log(err);
    }
  };

  const getEpinTransaction = async (id) => {
    try {
      const res = await axios(`https://api.ykfuture.in/api/v1/transactions/get-epin-transaction/${id}`);

      setShowTransaction(true);
      setEpinTransactions(res.data.transaction);
    } catch (err) {
      console.log(err);
    }
  };

  const getNewRequests = async () => {
    try {
      const userName = localStorage.getItem("username");
      const res = await axios.get(`https://api.ykfuture.in/api/v1/epinrequests/${userName}/get-new-requests`);

      if (res.status === 200) {
        setEPinRequestsData(res.data.epinRequests);
      }

      setShowEPinRequestsData(true);
      setRequestTitle("New Epins Request By You ");
    } catch (err) {
      console.log(err);
    }
  };

  const getAcceptedRequests = async () => {
    try {
      const userName = localStorage.getItem("username");
      const res = await axios.get(`https://api.ykfuture.in/api/v1/epinrequests/${userName}/get-accepted-requests`);

      if (res.status === 200) {
        setEPinRequestsData(res.data.epinRequests);
      }

      setShowEPinRequestsData(true);
      setRequestTitle("Accepted Epins Request By Admin ");
    } catch (err) {
      console.log(err);
    }
  };

  const getRejectedRequests = async () => {
    try {
      const userName = localStorage.getItem("username");
      const res = await axios.get(`https://api.ykfuture.in/api/v1/epinrequests/${userName}/get-cancelled-requests`);

      if (res.status === 200) {
        setEPinRequestsData(res.data.epinRequests);
      }

      setShowEPinRequestsData(true);

      setRequestTitle("Rejected/Cancelled Epins");
    } catch (err) {
      console.log(err);
    }
  };

  const getAllRequests = async () => {
    try {
      const userName = localStorage.getItem("username");
      const res = await axios.get(`https://api.ykfuture.in/api/v1/epinrequests/${userName}/get-all-requests`);

      if (res.status === 200) {
        setEPinRequestsData(res.data.epinRequests);
      }

      setShowEPinRequestsData(true);
      setRequestTitle("All Epins Request Made By You ");
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    showEPinsHandler();
    getAllRequests();
  }, []);

  return (
    <div id="boardpage" className="page">
      <Sidebar />
      <div className="right-container">
        <Navigation />
        <div className="rest">
          <div className="epinrequestcontainer">
            <div className="btn-group">
              <Button
                variant="outlined"
                color="success"
                onClick={availableEPinsHandler}
                style={{ marginRight: "10px" }}
              >
                Available Epins
              </Button>

              <Button variant="outlined" color="primary" onClick={showEPinsHandler} style={{ marginRight: "10px" }}>
                Epin Requests
              </Button>

              <Link to="/makeaepinrequest">
                <Button variant="outlined" color="error">
                  Make an EPin Request
                </Button>
              </Link>

              {showAvailableEpins && (
                <>
                  <h4 style={{ marginTop: "20px" }}>Available/Active EPins</h4>
                  <div className="epin-container">
                    {availableEpins.map((el) => (
                      <div className="epin-item" key={el._id} onClick={() => getEpinTransaction(el._id)}>
                        <p className="epin-id">{el.uniqueId}</p>
                        <p className="epin-bal">
                          <span>Bal:</span> {el.balance}
                        </p>
                      </div>
                    ))}
                  </div>
                </>
              )}

              {showTransaction && (
                <div className="transaction-table">
                  <BasicTable
                    heading="Epins Transactions"
                    width="100%"
                    type="transactionsTable"
                    data={epinTransactions}
                  />
                </div>
              )}

              {showEpinRequestsOptions && (
                <div className="filter-btn" style={{ marginTop: "20px" }}>
                  <Button color="primary" onClick={getNewRequests}>
                    New Requests
                  </Button>
                  <Button color="success" onClick={getAcceptedRequests}>
                    Accepted Requests
                  </Button>
                  <Button color="error" onClick={getRejectedRequests}>
                    Rejected/Cancelled Requests
                  </Button>
                  <Button color="warning" onClick={getAllRequests}>
                    All Requests
                  </Button>
                </div>
              )}

              {showEpinRequestData && (
                <BasicTable type="epinrequestsusercheck" width="100%" heading={requestTitle} data={epinRequestData} />
              )}

              {/* {showExpiredEpins && <p>Expired EPins</p>} */}
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default Epins;
