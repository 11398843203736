import React, { useEffect, useState } from "react";
import BasicTable from "../Tables/BasicTable";
import Button from "@mui/material/Button";
import RaiseATickets from "./RaiseATickets";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";

const SupportTicketComponent = () => {
  const [title, setTitle] = useState("");
  const [tickets, setTickets] = useState([]);
  const [showTickets, setShowTickets] = useState(true);
  const [makeCategory, setMakeCategory] = useState(false);

  const [ticketCategory, setTicketCategory] = useState("");

  const userData = JSON.parse(localStorage.getItem("userdata"));

  const fetchAllTickets = async (ticketStatus, title) => {
    try {
      setShowTickets(true);
      setTitle(title);

      let url;
      if (ticketStatus) {
        url =
          userData.role === "admin"
            ? `https://api.ykfuture.in/api/v1/ticket/get-all-tickets?status=${ticketStatus}`
            : `https://api.ykfuture.in/api/v1/ticket/get-all-tickets?raisedBy=${userData._id}&status=${ticketStatus}`;
      } else {
        url =
          userData.role === "admin"
            ? `https://api.ykfuture.in/api/v1/ticket/get-all-tickets`
            : `https://api.ykfuture.in/api/v1/ticket/get-all-tickets?raisedBy=${userData._id}`;
      }

      const respose = await axios.get(url);

      setTickets(respose.data.tickets);
    } catch (err) {
      console.log(err);
    }
  };

  const raiseTicket = async () => {
    setShowTickets(false);
  };

  const showMakeCategory = () => {
    setMakeCategory(true);
  };

  const handleClose = () => {
    setMakeCategory(false);
  };

  const makeCategoryHandler = async (e) => {
    try {
      e.preventDefault();

      const res = await axios.post("https://api.ykfuture.in/api/v1/ticketcategory/createnewticketcategory", {
        category: ticketCategory,
      });

      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchAllTickets(null, "All Tickets");
  }, []);

  return (
    <div className="payment-methods-container">
      <div style={{ textAlign: "right", marginBottom: 20 }}></div>
      <div className="filter-btn" style={{ width: userData.role === "admin" ? "70%" : "90%" }}>
        {/* if admin don't show this  */}

        {userData.role === "user" && (
          <Button variant="outlined" color="primary" onClick={raiseTicket}>
            Raise A Ticket
          </Button>
        )}

        <Button variant="outlined" color="success" onClick={() => fetchAllTickets(null, "All Tickets")}>
          All Tickets
        </Button>
        <Button
          variant="outlined"
          color="error"
          onClick={() => {
            fetchAllTickets("open", "Open Tickets");
          }}
        >
          Open Tickets
        </Button>
        <Button
          variant="outlined"
          color="warning"
          onClick={() => {
            fetchAllTickets("answered", "Answered Tickets");
          }}
        >
          Answered
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            fetchAllTickets("resolved", "Closed/Resolved Tickets");
          }}
        >
          Closed Tickets
        </Button>
      </div>

      {userData.role === "admin" && (
        <Button variant="outlined" color="primary" onClick={showMakeCategory}>
          Make a ticket category
        </Button>
      )}

      <Dialog open={makeCategory} onClose={handleClose}>
        <div
          className="top_of_dialog"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "0px",
          }}
        >
          <DialogTitle>Preview Attachment</DialogTitle>
          <ClearOutlinedIcon style={{ marginRight: "20px", cursor: "pointer" }} onClick={handleClose} />
        </div>
        <DialogContent>
          <form onSubmit={makeCategoryHandler} style={{ marginTop: "0px" }}>
            <div className="form-part" style={{ width: "100%", marginTop: "0px" }}>
              <div className="input-group" style={{ marginTop: "0px" }}>
                <label htmlFor="ticket-category">Category</label>
                <input
                  type="text"
                  id="ticket-category"
                  name="ticket-category"
                  placeholder="Enter The Category"
                  onChange={(e) => setTicketCategory(e.target.value)}
                />
              </div>
              <div className="input-group">
                <Button type="submit" variant="outlined">
                  Submit
                </Button>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>

      {showTickets && <BasicTable type="ticketTable" data={tickets} width="100%" heading={title} />}
      {!showTickets && <RaiseATickets />}
    </div>
  );
};

export default SupportTicketComponent;
