import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import userLogo from "../../user_1.jpg";
import bwUserLogo from "../../images/photo1696510113.jpeg";

import "./Board.css";

const Board = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [boardMembers, setBoardMembers] = useState([]);

  const fetchBoard = async () => {
    try {
      const res = await axios(`https://api.ykfuture.in/api/v1/board/${props.boardId}`);

      if (res.status === 200) {
        setIsLoading(false);
        setBoardMembers(res.data.board);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchBoard();
  }, []);

  return (
    <main>
      {!isLoading && boardMembers.membersLimit === 7 && (
        <div className="board-container seven_member_board">
          <ul className="block first_block">
            <li className="user_container">
              <div className={boardMembers.first ? "user first" : "user first faded_color"}>
                <img src={boardMembers.first ? userLogo : bwUserLogo} alt="" />
              </div>

              <ul className="hover_container">
                <li>
                  <b>{boardMembers.first ? boardMembers.first.userName : "no user"}</b>
                  <sup>{boardMembers.first ? <i>({boardMembers.firstUserPower})</i> : ""}</sup>
                </li>
              </ul>
            </li>
          </ul>
          <div className="board_seprator">
            <div className="board_left_seprate"></div>
            <div className="board_right_seprate"></div>
          </div>
          <ul className="block second_block">
            <li className="user_container">
              <div className={boardMembers.second ? "user first" : "user first faded_color"}>
                <img src={boardMembers.second ? userLogo : bwUserLogo} alt="" />
              </div>

              <ul className="hover_container">
                <li>
                  <b>{boardMembers.second ? boardMembers.second.userName : "no user"}</b>
                  <sup>{boardMembers.second ? <i>({boardMembers.secondUserPower})</i> : ""}</sup>
                </li>
              </ul>
            </li>
            <li className="user_container">
              <div className={boardMembers.third ? "user first" : "user first faded_color"}>
                <img src={boardMembers.third ? userLogo : bwUserLogo} alt="" />
              </div>

              <ul className="hover_container">
                <li>
                  <b>{boardMembers.third ? boardMembers.third.userName : "no user"}</b>
                  <sup>{boardMembers.third ? <i>({boardMembers.thirdUserPower})</i> : ""}</sup>
                </li>
              </ul>
            </li>
          </ul>
          <ul className="block">
            <li className="user_container">
              <div className="board_seprator">
                <div className="board_left_seprate"></div>
                <div className="board_right_seprate"></div>
              </div>
            </li>
            <li className="user_container">
              <div className="board_seprator">
                <div className="board_left_seprate"></div>
                <div className="board_right_seprate"></div>
              </div>
            </li>
          </ul>
          <ul className="block third_block">
            <li className="user_container">
              <div className={boardMembers.fourth ? "user first" : "user first faded_color"}>
                <img src={boardMembers.fourth ? userLogo : bwUserLogo} alt="" />
              </div>

              <ul className="hover_container">
                <li>
                  <b>{boardMembers.fourth ? boardMembers.fourth.userName : "no user"}</b>
                  <sup>{boardMembers.fourth ? <i>({boardMembers.fourthUserPower})</i> : ""}</sup>
                </li>
              </ul>
            </li>
            <li className="user_container">
              <div className={boardMembers.fifth ? "user first" : "user first faded_color"}>
                <img src={boardMembers.fifth ? userLogo : bwUserLogo} alt="" />
              </div>

              <ul className="hover_container">
                <li>
                  <b>{boardMembers.fifth ? boardMembers.fifth.userName : "no user"}</b>
                  <sup>{boardMembers.fifth ? <i>({boardMembers.fifthUserPower})</i> : ""}</sup>
                </li>
              </ul>
            </li>

            <li className="user_container">
              <div className={boardMembers.sixth ? "user first" : "user first faded_color"}>
                <img src={boardMembers.sixth ? userLogo : bwUserLogo} alt="" />
              </div>

              <ul className="hover_container">
                <li>
                  <b>{boardMembers.sixth ? boardMembers.sixth.userName : "no user"}</b>
                  <sup>{boardMembers.sixth ? <i>({boardMembers.sixthUserPower})</i> : ""}</sup>
                </li>
              </ul>
            </li>
            <li className="user_container">
              <div className={boardMembers.seventh ? "user first" : "user first faded_color"}>
                <img src={boardMembers.seventh ? userLogo : bwUserLogo} alt="" />
              </div>

              <ul className="hover_container">
                <li>
                  <b>{boardMembers.seventh ? boardMembers.seventh.userName : "no user"}</b>
                  <sup>{boardMembers.seventh ? <i>({boardMembers.seventhUserPower})</i> : ""}</sup>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      )}

      {!isLoading && boardMembers.membersLimit === 13 && (
        <div className="board-container thirteen_member_board">
          <div className="block first_block">
            <div className="user_container">
              <div className={boardMembers.first ? "user first" : "user first faded_color"}>
                <img src={boardMembers.first ? userLogo : bwUserLogo} alt="" />
              </div>

              <ul className="hover_container">
                <li>
                  <b>{boardMembers.first ? boardMembers.first.userName : "no user"}</b>
                  <sup>{boardMembers.first ? <i>({boardMembers.firstUserPower})</i> : ""}</sup>
                </li>
              </ul>
            </div>
          </div>

          <div className="board_seprator">
            <div className="board_left_seprate"></div>
            <div className="board_right_seprate"></div>
          </div>

          <div className="block second_block">
            <div className="user_container">
              <div className={boardMembers.second ? "user first" : "user first faded_color"}>
                <img src={boardMembers.second ? userLogo : bwUserLogo} alt="" />
              </div>

              <ul className="hover_container">
                <li>
                  <b>{boardMembers.second ? boardMembers.second.userName : "no user"}</b>
                  <sup>{boardMembers.second ? <i>({boardMembers.secondUserPower})</i> : ""}</sup>
                </li>
              </ul>
            </div>

            <div className="user_container">
              <div className={boardMembers.third ? "user first" : "user first faded_color"}>
                <img src={boardMembers.third ? userLogo : bwUserLogo} alt="" />
              </div>

              <ul className="hover_container">
                <li>
                  <b>{boardMembers.third ? boardMembers.third.userName : "no user"}</b>
                  <sup>{boardMembers.third ? <i>({boardMembers.thirdUserPower})</i> : ""}</sup>
                </li>
              </ul>
            </div>

            <div className="user_container">
              <div className={boardMembers.fourth ? "user first" : "user first faded_color"}>
                <img src={boardMembers.fourth ? userLogo : bwUserLogo} alt="" />
              </div>
              <ul className="hover_container">
                <li>
                  <b>{boardMembers.fourth ? boardMembers.fourth.userName : "no user"}</b>
                  <sup>{boardMembers.fourth ? <i>({boardMembers.fourthUserPower})</i> : ""}</sup>
                </li>
              </ul>
            </div>
          </div>

          <div className="block">
            <div className="user_container">
              <div className="board_seprator">
                <div className="board_left_seprate"></div>
                <div className="board_right_seprate"></div>
              </div>
            </div>
            <div className="user_container">
              <div className="board_seprator">
                <div className="board_left_seprate"></div>
                <div className="board_right_seprate"></div>
              </div>
            </div>

            <div className="user_container">
              <div className="board_seprator">
                <div className="board_left_seprate"></div>
                <div className="board_right_seprate"></div>
              </div>
            </div>
          </div>

          <div className="block third_block">
            <div className="user_container">
              <div className={boardMembers.fifth ? "user first" : "user first faded_color"}>
                <img src={boardMembers.fifth ? userLogo : bwUserLogo} alt="" />
              </div>
              <ul className="hover_container">
                <li>
                  <b>{boardMembers.fifth ? boardMembers.fifth.userName : "no user"}</b>
                  <sup>{boardMembers.fifth ? <i>({boardMembers.fifthUserPower})</i> : ""}</sup>
                </li>
              </ul>
            </div>

            <div className="user_container">
              <div className={boardMembers.sixth ? "user first" : "user first faded_color"}>
                <img src={boardMembers.sixth ? userLogo : bwUserLogo} alt="" />
              </div>
              <ul className="hover_container">
                <li>
                  <b>{boardMembers.sixth ? boardMembers.sixth.userName : "no user"}</b>
                  <sup>{boardMembers.sixth ? <i>({boardMembers.sixthUserPower})</i> : ""}</sup>
                </li>
              </ul>
            </div>

            <div className="user_container">
              <div className={boardMembers.seventh ? "user first" : "user first faded_color"}>
                <img src={boardMembers.seventh ? userLogo : bwUserLogo} alt="" />
              </div>
              <ul className="hover_container">
                <li>
                  <b>{boardMembers.seventh ? boardMembers.seventh.userName : "no user"}</b>
                  <sup>{boardMembers.seventh ? <i>({boardMembers.seventhUserPower})</i> : ""}</sup>
                </li>
              </ul>
            </div>

            <div className="user_container">
              <div className={boardMembers.eighth ? "user first" : "user first faded_color"}>
                <img src={boardMembers.eighth ? userLogo : bwUserLogo} alt="" />
              </div>
              <ul className="hover_container">
                <li>
                  <b>{boardMembers.eighth ? boardMembers.eighth.userName : "no user"}</b>
                  <sup>{boardMembers.eighth ? <i>({boardMembers.eighthUserPower})</i> : ""}</sup>
                </li>
              </ul>
            </div>

            <div className="user_container">
              <div className={boardMembers.ninth ? "user first" : "user first faded_color"}>
                <img src={boardMembers.ninth ? userLogo : bwUserLogo} alt="" />
              </div>
              <ul className="hover_container">
                <li>
                  <b>{boardMembers.ninth ? boardMembers.ninth.userName : "no user"}</b>
                  <sup>{boardMembers.ninth ? <i>({boardMembers.ninthUserPower})</i> : ""}</sup>
                </li>
              </ul>
            </div>

            <div className="user_container">
              <div className={boardMembers.tenth ? "user first" : "user first faded_color"}>
                <img src={boardMembers.tenth ? userLogo : bwUserLogo} alt="" />
              </div>
              <ul className="hover_container">
                <li>
                  <b>{boardMembers.tenth ? boardMembers.tenth.userName : "no user"}</b>
                  <sup>{boardMembers.tenth ? <i>({boardMembers.tenthUserPower})</i> : ""}</sup>
                </li>
              </ul>
            </div>

            <div className="user_container">
              <div className={boardMembers.eleventh ? "user first" : "user first faded_color"}>
                <img src={boardMembers.eleventh ? userLogo : bwUserLogo} alt="" />
              </div>
              <ul className="hover_container">
                <li>
                  <b>{boardMembers.eleventh ? boardMembers.eleventh.userName : "no user"}</b>
                  <sup>{boardMembers.eleventh ? <i>({boardMembers.eleventhUserPower})</i> : ""}</sup>
                </li>
              </ul>
            </div>

            <div className="user_container">
              <div className={boardMembers.twelth ? "user first" : "user first faded_color"}>
                <img src={boardMembers.twelth ? userLogo : bwUserLogo} alt="" />
              </div>
              <ul className="hover_container">
                <li>
                  <b>{boardMembers.twelth ? boardMembers.twelth.userName : "no user"}</b>
                  <sup>{boardMembers.twelth ? <i>({boardMembers.twelthUserPower})</i> : ""}</sup>
                </li>
              </ul>
            </div>
            <div className="user_container">
              <div className={boardMembers.thirteenth ? "user first" : "user first faded_color"}>
                <img src={boardMembers.thirteenth ? userLogo : bwUserLogo} alt="" />
              </div>
              <ul className="hover_container">
                <li>
                  <b>{boardMembers.thirteenth ? boardMembers.thirteenth.userName : "no user"}</b>
                  <sup>{boardMembers.thirteenth ? <i>({boardMembers.thirteenthUserPower})</i> : ""}</sup>
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}

      {isLoading && <CircularProgress color="secondary" disableShrink />}
    </main>
  );
};

export default Board;
