import React, { useState, useEffect } from "react";
import BasicTable from "../Tables/BasicTable";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import Papa from "papaparse";
import "react-toastify/dist/ReactToastify.css";

const RedeemRequests = () => {
  const [withdrawlRequest, setWithdrawlRequests] = useState([]);
  const [title, setTitle] = useState("");
  const [csvData, setCsvData] = useState("");

  const fetchAcceptedRequests = async () => {
    try {
      const response = await fetch("https://api.ykfuture.in/api/v1/redeem-request/getallrequest?isAccepted=true");

      const data = await response.json();
      setWithdrawlRequests(data.allRequests);
      setTitle("Accepted Requests");
    } catch (err) {
      console.log(err);
    }
  };

  const fetchRejecteddRequests = async () => {
    try {
      const response = await fetch("https://api.ykfuture.in/api/v1/redeem-request/getallrequest?isCancelled=true");

      const data = await response.json();
      setWithdrawlRequests(data.allRequests);
      setTitle("Rejected Requests");
    } catch (err) {
      console.log(err);
    }
  };
  const fetchNewRequests = async () => {
    try {
      const response = await fetch("https://api.ykfuture.in/api/v1/redeem-request/getallrequest?isNewRequest=true");

      const data = await response.json();
      setWithdrawlRequests(data.allRequests);
      setTitle("New Requests");
    } catch (err) {
      console.log(err);
    }
  };

  const fetchAllRequests = async () => {
    try {
      const response = await fetch("https://api.ykfuture.in/api/v1/redeem-request/getallrequest");

      const data = await response.json();
      setWithdrawlRequests(data.allRequests);
      setTitle("All Withdrawl Requests");
    } catch (err) {
      console.log(err);
    }
  };

  const convertToCSV = (data) => {
    if (data.length === 0) return "";

    // Extract headers from the first row
    const headers = Object.keys(data[0]);
    // Convert data to an array of arrays
    const csvArray = [headers, ...data.map((obj) => headers.map((header) => obj[header]))];

    // Convert the array to a CSV string
    return Papa.unparse(csvArray);
  };

  const handleExportCSV = () => {
    const csv = convertToCSV(withdrawlRequest);

    // Set the CSV data in state
    setCsvData(csv);

    // Create a Blob with the CSV data
    const blob = new Blob([csv], { type: "text/csv" });

    // Create a temporary URL to download the file
    const url = URL.createObjectURL(blob);

    // Create an invisible anchor element to trigger the download
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = `${title}.csv`;

    // Trigger the click event to download the CSV file
    document.body.appendChild(a);
    a.click();

    // Clean up
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  useEffect(() => {
    fetchAllRequests();
  }, []);

  return (
    <div className="payment-methods-container">
      <div style={{ textAlign: "right", marginBottom: 20 }}></div>
      <div className="filter-btn">
        <Button variant="outlined" color="primary" onClick={fetchNewRequests}>
          New Requests
        </Button>
        <Button variant="outlined" color="success" onClick={fetchAcceptedRequests}>
          Accepted Requests
        </Button>
        <Button variant="outlined" color="error" onClick={fetchRejecteddRequests}>
          Rejected Requests
        </Button>
        <Button variant="outlined" color="warning" onClick={fetchAllRequests}>
          All Requests
        </Button>
      </div>
      <div style={{ textAlign: "right", marginBottom: "10px" }}>
        <Button variant="outlined" endIcon={<DownloadIcon />} onClick={handleExportCSV}>
          Dowload csv
        </Button>
      </div>
      <BasicTable type="redeemRequestTable" data={withdrawlRequest} width="100%" heading={title} />
    </div>
  );
};

export default RedeemRequests;
