import React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import Tooltip from "@mui/material/Tooltip";
// import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import "./BasicTable.css";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    borderBottom: "4px #dddddd solid",
    // color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    // color: theme.palette.common.white,
    borderBottom: "1px #222222 solid",
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // backgroundColor: "#ffffff ",
  // color: theme.palette.common.white,
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const BasicTableData = [
  {
    no: "1",
    Id: "Board12345",
  },
  {
    no: "2",
    Id: "Board546785",
  },
  {
    no: "3",
    Id: "Board12356",
  },
  {
    no: "4",
    Id: "Board083",
  },
];

export default function BasicTable(props) {
  if (props.type === "epin") {
    return (
      <div className="table" style={{ width: props.width }}>
        <p className="table-heading">{props.heading}</p>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>S.No.</StyledTableCell>
                <StyledTableCell>Payment Methods</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell align="center">Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* Cash Payment Method */}
              <StyledTableRow>
                <StyledTableCell component="th" scope="row">
                  1
                </StyledTableCell>
                <StyledTableCell>Cash</StyledTableCell>
                <StyledTableCell>
                  {props.data.cash ? (
                    <Button color="success" variant="outlined">
                      On
                    </Button>
                  ) : (
                    <Button color="error" variant="outlined">
                      Off
                    </Button>
                  )}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {props.data.cash ? (
                    <Button color="error" onClick={() => props.cash(props.data._id, false)}>
                      Deactive
                    </Button>
                  ) : (
                    <Button color="success" onClick={() => props.cash(props.data._id, true)}>
                      Activate
                    </Button>
                  )}
                </StyledTableCell>
              </StyledTableRow>

              {/* Card Payment Method */}
              <StyledTableRow>
                <StyledTableCell component="th" scope="row">
                  2
                </StyledTableCell>
                <StyledTableCell>Card</StyledTableCell>
                <StyledTableCell>
                  {props.data.card ? (
                    <Button color="success" variant="outlined">
                      On
                    </Button>
                  ) : (
                    <Button color="error" variant="outlined">
                      Off
                    </Button>
                  )}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {props.data.card ? (
                    <Button color="error" onClick={() => props.card(props.data._id, false)}>
                      Deactive
                    </Button>
                  ) : (
                    <Button color="success" onClick={() => props.card(props.data._id, true)}>
                      Activate
                    </Button>
                  )}
                </StyledTableCell>
              </StyledTableRow>

              {/* UPI Payment Method */}
              <StyledTableRow>
                <StyledTableCell component="th" scope="row">
                  3
                </StyledTableCell>
                <StyledTableCell>UPI</StyledTableCell>
                <StyledTableCell>
                  {props.data.upi ? (
                    <Button color="success" variant="outlined">
                      On
                    </Button>
                  ) : (
                    <Button color="error" variant="outlined">
                      Off
                    </Button>
                  )}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {props.data.upi ? (
                    <Button color="error" onClick={() => props.upi(props.data._id, false)}>
                      Deactive
                    </Button>
                  ) : (
                    <Button color="success" onClick={() => props.upi(props.data._id, true)}>
                      Activate
                    </Button>
                  )}
                </StyledTableCell>
              </StyledTableRow>

              {/* Net Banking Payment Method */}
              <StyledTableRow>
                <StyledTableCell component="th" scope="row">
                  4
                </StyledTableCell>
                <StyledTableCell>Net Banking</StyledTableCell>
                <StyledTableCell>
                  {props.data.netBanking ? (
                    <Button color="success" variant="outlined">
                      On
                    </Button>
                  ) : (
                    <Button color="error" variant="outlined">
                      Off
                    </Button>
                  )}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {props.data.netBanking ? (
                    <Button color="error" onClick={() => props.netBanking(props.data._id, false)}>
                      Deactive
                    </Button>
                  ) : (
                    <Button color="success" onClick={() => props.netBanking(props.data._id, true)}>
                      Activate
                    </Button>
                  )}
                </StyledTableCell>
              </StyledTableRow>

              {/* Bank Transfer */}
              <StyledTableRow>
                <StyledTableCell component="th" scope="row">
                  5
                </StyledTableCell>
                <StyledTableCell>Bank Transfer</StyledTableCell>
                <StyledTableCell>
                  {props.data.bankTransfer ? (
                    <Button color="success" variant="outlined">
                      On
                    </Button>
                  ) : (
                    <Button color="error" variant="outlined">
                      Off
                    </Button>
                  )}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {props.data.bankTransfer ? (
                    <Button color="error" onClick={() => props.bankTransfer(props.data._id, false)}>
                      Deactive
                    </Button>
                  ) : (
                    <Button color="success" onClick={() => props.bankTransfer(props.data._id, true)}>
                      Activate
                    </Button>
                  )}
                </StyledTableCell>
              </StyledTableRow>

              {/* wallets */}
              <StyledTableRow>
                <StyledTableCell component="th" scope="row">
                  6
                </StyledTableCell>
                <StyledTableCell>Wallets</StyledTableCell>
                <StyledTableCell>
                  {props.data.wallets ? (
                    <Button color="success" variant="outlined">
                      On
                    </Button>
                  ) : (
                    <Button color="error" variant="outlined">
                      Off
                    </Button>
                  )}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {props.data.wallets ? (
                    <Button color="error" onClick={() => props.wallets(props.data._id, false)}>
                      Deactive
                    </Button>
                  ) : (
                    <Button color="success" onClick={() => props.wallets(props.data._id, true)}>
                      Activate
                    </Button>
                  )}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  } else if (props.type === "epinrequests") {
    return (
      <div className="table" style={{ width: props.width }}>
        <p className="table-heading">{props.heading}</p>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>S.No.</StyledTableCell>
                <StyledTableCell align="center">Requested By</StyledTableCell>
                <StyledTableCell align="center">Amount Each</StyledTableCell>
                <StyledTableCell align="center">Qty</StyledTableCell>
                <StyledTableCell align="center">Total Amount</StyledTableCell>
                <StyledTableCell align="center">Status</StyledTableCell>
                <StyledTableCell align="center">Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data.map((kycrequests, i) => (
                <StyledTableRow key={kycrequests._id}>
                  <StyledTableCell component="th" scope="row">
                    {(i += 1)}
                  </StyledTableCell>
                  <StyledTableCell align="center">{kycrequests.requestedBy}</StyledTableCell>
                  <StyledTableCell align="center">{kycrequests.requestedAmount}</StyledTableCell>
                  <StyledTableCell align="center">{kycrequests.numberOfEpins}</StyledTableCell>
                  <StyledTableCell align="center">{kycrequests.totalAmount}</StyledTableCell>
                  <StyledTableCell align="center">{kycrequests.Status}</StyledTableCell>
                  <StyledTableCell align="center">
                    <Link to={`${kycrequests._id}`} className="board_link">
                      View Details
                    </Link>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  } else if (props.type === "kycrequests") {
    return (
      <div className="table" style={{ width: props.width }}>
        <p className="table-heading">{props.heading}</p>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>S.No.</StyledTableCell>
                <StyledTableCell align="center">Requested By</StyledTableCell>
                <StyledTableCell align="center">Id Number</StyledTableCell>
                <StyledTableCell align="center">Pan Number</StyledTableCell>
                <StyledTableCell align="center">Bank Account Number</StyledTableCell>
                <StyledTableCell align="center">Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data.map((ePinRequests, i) => (
                <StyledTableRow key={ePinRequests._id}>
                  <StyledTableCell component="th" scope="row">
                    {(i += 1)}
                  </StyledTableCell>
                  <StyledTableCell align="center">{ePinRequests.requestedBy}</StyledTableCell>
                  <StyledTableCell align="center">{ePinRequests.idNumber}</StyledTableCell>
                  <StyledTableCell align="center">{ePinRequests.panNumber}</StyledTableCell>
                  <StyledTableCell align="center">{ePinRequests.bankAccountNumber}</StyledTableCell>
                  <StyledTableCell align="center">
                    <Link to={`${ePinRequests._id}`} className="board_link">
                      View Details
                    </Link>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  } else if (props.type === "epinrequestsusercheck") {
    return (
      <div className="table" style={{ width: props.width }}>
        <p className="table-heading">{props.heading}</p>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>S.No.</StyledTableCell>
                <StyledTableCell align="center">Requested By</StyledTableCell>
                <StyledTableCell align="center">Amount Each</StyledTableCell>
                <StyledTableCell align="center">Qty</StyledTableCell>
                <StyledTableCell align="center">Total Amount</StyledTableCell>
                <StyledTableCell align="center">Payment Method</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data.map((ePinRequests, i) => (
                <StyledTableRow key={ePinRequests._id}>
                  <StyledTableCell component="th" scope="row">
                    {(i += 1)}
                  </StyledTableCell>
                  <StyledTableCell align="center">{ePinRequests.requestedBy}</StyledTableCell>
                  <StyledTableCell align="center">{ePinRequests.requestedAmount}</StyledTableCell>
                  <StyledTableCell align="center">{ePinRequests.numberOfEpins}</StyledTableCell>
                  <StyledTableCell align="center">{ePinRequests.totalAmount}</StyledTableCell>
                  <StyledTableCell align="center">{ePinRequests.paymentMethod}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  } else if (props.type === "transactionsTable") {
    return (
      <div className="table" style={{ width: props.width }}>
        <p className="table-heading">{props.heading}</p>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>S.No.</StyledTableCell>
                <StyledTableCell align="center">Remark</StyledTableCell>
                <StyledTableCell align="center">Date</StyledTableCell>
                <StyledTableCell align="center">Time</StyledTableCell>
                <StyledTableCell align="center">Amount</StyledTableCell>
                <StyledTableCell align="center">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            {props.data.length === 0 ? (
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell style={{ color: "red", margin: "20px" }}>No Transactions Found</StyledTableCell>
                  <StyledTableCell align="center" style={{ color: "red", margin: "20px" }}>
                    -
                  </StyledTableCell>
                  <StyledTableCell align="center" style={{ color: "red", margin: "20px" }}>
                    -
                  </StyledTableCell>
                  <StyledTableCell align="center" style={{ color: "red", margin: "20px" }}>
                    -
                  </StyledTableCell>
                  <StyledTableCell align="center" style={{ color: "red", margin: "20px" }}>
                    -
                  </StyledTableCell>
                  <StyledTableCell align="center" style={{ color: "red", margin: "20px" }}>
                    -
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            ) : (
              <TableBody>
                {props.data.map((element, i) => (
                  <StyledTableRow key={element._id}>
                    <StyledTableCell component="th" scope="row">
                      {(i += 1)}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {element.type === "epin" ? "Money Withdrawn" : "SOmehi"}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {/* {element.createdOn} */}
                      {dayjs(element.createdOn).format("DD MMM YYYY")}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {/* {element.createdOn} */}
                      {dayjs(element.createdOn).format("HH:mm:ss")}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {element.action === "credit" ? (
                        <span style={{ color: "green", fontWeight: "bold" }}>+ {element.amount}</span>
                      ) : (
                        <span style={{ color: "red", fontWeight: "bold" }}>- {element.amount}</span>
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Tooltip title="View Invoice" arrow>
                        <RemoveRedEyeOutlinedIcon fontSize="small" color="primary" style={{ cursor: "pointer" }} />
                      </Tooltip>
                      {/* <Tooltip title="Download Invoice" arrow>
                        <FileDownloadOutlinedIcon fontSize="small" color="primary" style={{ cursor: "pointer" }} />
                      </Tooltip> */}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </div>
    );
  } else if (props.type === "walletTransactionsTable") {
    return (
      <div className="table" style={{ width: props.width }}>
        <p className="table-heading">{props.heading}</p>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>S.No.</StyledTableCell>
                <StyledTableCell align="left">Remark</StyledTableCell>
                <StyledTableCell align="center">Date</StyledTableCell>
                <StyledTableCell align="center">Time</StyledTableCell>
                <StyledTableCell align="center">Amount</StyledTableCell>
              </TableRow>
            </TableHead>
            {props.data.length === 0 ? (
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell style={{ color: "red", margin: "20px" }}>No Transactions Found</StyledTableCell>
                  <StyledTableCell align="center" style={{ color: "red", margin: "20px" }}>
                    -
                  </StyledTableCell>
                  <StyledTableCell align="center" style={{ color: "red", margin: "20px" }}>
                    -
                  </StyledTableCell>
                  <StyledTableCell align="center" style={{ color: "red", margin: "20px" }}>
                    -
                  </StyledTableCell>
                  <StyledTableCell align="center" style={{ color: "red", margin: "20px" }}>
                    -
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            ) : (
              <TableBody>
                {props.data.map((element, i) => (
                  <StyledTableRow
                    key={element._id}
                    className={element.isInactiveTransaction ? "inactive-transaction" : ""}
                    style={{ textTransform: "capitalize" }}
                  >
                    <StyledTableCell component="th" scope="row">
                      {(i += 1)}
                    </StyledTableCell>
                    <StyledTableCell align="left">{element.remark}</StyledTableCell>
                    <StyledTableCell align="center">
                      {/* {element.createdOn} */}
                      {dayjs(element.createdOn).format("DD MMM YYYY")}
                    </StyledTableCell>
                    <StyledTableCell align="center">{dayjs(element.createdOn).format("HH:mm:ss")}</StyledTableCell>
                    <StyledTableCell align="center">
                      {element.action === "added" ? (
                        <span style={{ color: "green", fontWeight: "bold" }}>+ {element.amount}</span>
                      ) : (
                        <span style={{ color: "red", fontWeight: "bold" }}>- {element.amount}</span>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </div>
    );
  } else if (props.type === "pvTransactionTable") {
    return (
      <div className="table" style={{ width: props.width }}>
        <p className="table-heading">{props.heading}</p>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>S.No.</StyledTableCell>
                <StyledTableCell align="center">Date</StyledTableCell>
                <StyledTableCell align="center">Time</StyledTableCell>
                <StyledTableCell align="right">PV</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data.map((row, i) => (
                <StyledTableRow key={row._id}>
                  <StyledTableCell>{(i += 1)}</StyledTableCell>
                  <StyledTableCell align="center" component="th" scope="row">
                    {dayjs(row.createdOn).format("DD MMM YYYY")}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {dayjs.utc(row.createdOn).tz("Asia/Kolkata").format("HH:mm")}
                  </StyledTableCell>
                  <StyledTableCell align="right">{row.pvLeft ? row.pvLeft : row.pvRight}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  } else if (props.type === "luTransactionTable") {
    return (
      <div className="table" style={{ width: props.width }}>
        <p className="table-heading">{props.heading}</p>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>S.No.</StyledTableCell>
                <StyledTableCell align="center">Date</StyledTableCell>
                <StyledTableCell align="center">Time</StyledTableCell>
                <StyledTableCell align="center">Subscription Lu</StyledTableCell>
                <StyledTableCell align="center">Multiplied By</StyledTableCell>
                <StyledTableCell align="center">Total Reward</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data.map((row, i) => (
                <StyledTableRow key={row._id}>
                  <StyledTableCell>{(i += 1)}</StyledTableCell>
                  <StyledTableCell align="center" component="th" scope="row">
                    {dayjs(row.createdOn).format("DD MMM YYYY")}
                  </StyledTableCell>
                  <StyledTableCell align="center">{dayjs(row.createdOn).format("HH:mm")}</StyledTableCell>
                  <StyledTableCell align="center">{row.subscriptionLU}</StyledTableCell>
                  <StyledTableCell align="center">{row.multipliedBy}</StyledTableCell>
                  <StyledTableCell align="center">{row.totalRewardGot}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  } else if (props.type === "redeemRequestTable") {
    return (
      <div className="table" style={{ width: props.width }}>
        <p className="table-heading">{props.heading}</p>

        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">S.No.</StyledTableCell>
                <StyledTableCell align="center">Username</StyledTableCell>
                <StyledTableCell align="center">Requested Amount</StyledTableCell>
                <StyledTableCell align="center">Request Status</StyledTableCell>
                <StyledTableCell align="center">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data.map((row, i) => (
                <StyledTableRow key={row._id}>
                  <StyledTableCell align="center">{(i += 1)}</StyledTableCell>
                  <StyledTableCell align="center">{row.userName}</StyledTableCell>
                  <StyledTableCell align="center">{row.requestedAmount}</StyledTableCell>
                  <StyledTableCell align="center">{row.status}</StyledTableCell>
                  <StyledTableCell align="center">
                    <Link to={`${row._id}`} className="board_link">
                      View Details
                    </Link>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  } else if (props.type === "ticketTable") {
    return (
      <div className="table" style={{ width: props.width }}>
        <p className="table-heading">{props.heading}</p>

        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">S.No.</StyledTableCell>
                <StyledTableCell align="center">Username</StyledTableCell>
                <StyledTableCell align="center">Subject</StyledTableCell>
                <StyledTableCell align="center">Priority</StyledTableCell>
                <StyledTableCell align="center">Request Status</StyledTableCell>
                <StyledTableCell align="center">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data.map((row, i) => (
                <StyledTableRow key={row._id}>
                  <StyledTableCell align="center">{(i += 1)}</StyledTableCell>
                  <StyledTableCell align="center">{row.raisedBy.userName}</StyledTableCell>
                  <StyledTableCell align="center">{row.subject}</StyledTableCell>
                  <StyledTableCell align="center">{row.priority}</StyledTableCell>
                  <StyledTableCell align="center">{row.status}</StyledTableCell>
                  <StyledTableCell align="center">
                    <Link to={`${row.ticketId}`} className="board_link">
                      View Details
                    </Link>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  } else if (props.type === "boards") {
    return (
      <div className="table" style={{ width: props.width }}>
        <p className="table-heading">{props.heading}</p>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>S.No.</StyledTableCell>
                <StyledTableCell align="center">Board ID</StyledTableCell>
                <StyledTableCell align="right">More</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data.map((row, i) => (
                <StyledTableRow key={i}>
                  <StyledTableCell component="th" scope="row">
                    {i + 1}
                  </StyledTableCell>
                  <StyledTableCell align="center">{row}</StyledTableCell>

                  <StyledTableCell align="right">
                    <Link to={`board/boardview/${row}`} className="board_link">
                      More Details
                    </Link>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  } else if (props.type === "allusers") {
    return (
      <div className="table" style={{ width: props.width }}>
        <p className="table-heading">{props.heading}</p>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>S.No.</StyledTableCell>
                <StyledTableCell align="center">Username</StyledTableCell>
                <StyledTableCell align="center">Email</StyledTableCell>
                <StyledTableCell align="center">Phone</StyledTableCell>
                <StyledTableCell align="center">KYc Status</StyledTableCell>
                <StyledTableCell align="right">More</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data.map((el, i) => (
                <StyledTableRow key={i}>
                  <StyledTableCell component="th" scope="row">
                    {i + 1}
                  </StyledTableCell>
                  <StyledTableCell align="center">{el.userName}</StyledTableCell>
                  <StyledTableCell align="center">{el.email}</StyledTableCell>
                  <StyledTableCell align="center">{el.phoneNo}</StyledTableCell>
                  <StyledTableCell align="center">{el.kycStatus}</StyledTableCell>
                  <StyledTableCell align="right">
                    <Link to={`user/${el._id}`} className="board_link">
                      More Details
                    </Link>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  } else if (props.type === "allboards") {
    return (
      <div className="table" style={{ width: props.width }}>
        <p className="table-heading">{props.heading}</p>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>S.No.</StyledTableCell>
                <StyledTableCell align="center">Board ID</StyledTableCell>
                <StyledTableCell align="right">More</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data.map((el, i) => (
                <StyledTableRow key={i}>
                  <StyledTableCell component="th" scope="row">
                    {i + 1}
                  </StyledTableCell>
                  <StyledTableCell align="center">{el.boardId}</StyledTableCell>
                  {/* <StyledTableCell align="center">{el.email}</StyledTableCell>
                  <StyledTableCell align="center">{el.phoneNo}</StyledTableCell> */}

                  <StyledTableCell align="right">
                    <Link to={`boardview/${el.boardId}`} className="board_link">
                      More Details
                    </Link>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  } else if (props.type === "supportTickets") {
    return (
      <div className="table" style={{ width: props.width }}>
        <p className="table-heading">{props.heading}</p>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>S.No.</StyledTableCell>
                <StyledTableCell align="center">Subject</StyledTableCell>
                <StyledTableCell align="center">Status</StyledTableCell>
                <StyledTableCell align="center">Created On</StyledTableCell>
                <StyledTableCell align="center">Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.data.map((ticket, i) => (
                <StyledTableRow key={ticket._id}>
                  <StyledTableCell component="th" scope="row">
                    {(i += 1)}
                  </StyledTableCell>
                  <StyledTableCell align="center">{ticket.subject}</StyledTableCell>
                  <StyledTableCell align="center">{ticket.status}</StyledTableCell>
                  <StyledTableCell align="center">{ticket.createdOn}</StyledTableCell>
                  <StyledTableCell align="center">
                    <Link to={`${ticket._id}`} className="board_link">
                      View Details
                    </Link>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  } else {
    return (
      <div className="table" style={{ width: props.width }}>
        <p className="table-heading">{props.heading}</p>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>S.No.</StyledTableCell>
                <StyledTableCell align="center">Board ID</StyledTableCell>
                <StyledTableCell align="right">More</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {BasicTableData.map((row) => (
                <StyledTableRow key={row.no}>
                  <StyledTableCell component="th" scope="row">
                    {row.no}
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.Id}</StyledTableCell>
                  <StyledTableCell align="right">More Details</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}
