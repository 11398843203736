import React, { useState, useEffect } from "react";
import axios from "axios";
import Navigation from "../../components/navigation/Navigation";
import Sidebar from "../../components/Sidebar/Sidebar";
import PaymentForm from "../../components/Forms/PaymentForm";

const Payment = () => {
  const [epinPaymentMethods, seEpinPaymentMethods] = useState([]);
  const fetchAllEpinPaymentMethods = async () => {
    await axios
      .get("https://api.ykfuture.in/api/v1/epinpaymentmethod/getall")
      .then((res) => {
        seEpinPaymentMethods(res.data.ePinPaymentMethods[0]);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    fetchAllEpinPaymentMethods();
  }, []);
  return (
    <div id="Payment" className="page">
      <Sidebar />
      <div className="right-container">
        <Navigation />
        <div className="rest">
          <PaymentForm data={epinPaymentMethods} />
        </div>
      </div>
    </div>
  );
};

export default Payment;
