import React from 'react'
import Sidebar from '../../components/Sidebar/Sidebar'
import Navigation from '../../components/navigation/Navigation'
import PVComponent from '../../components/Wallet/PVComponent'


const PVRecordPage = () => {
  return (
    <div id="profilepage" className="page">
      <Sidebar />
      <div className="right-container">
        <Navigation />
        <div className="rest">
          <div className="profile-container">
           <PVComponent/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PVRecordPage