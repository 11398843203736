import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const RaiseATickets = () => {
  const [ticketCategories, setTicketCategories] = useState([]);

  //   tickets fields

  const [ticketSubject, setTicketSubject] = useState("");
  const [ticketMessage, setTicketMessage] = useState("");
  const [priority, setPriority] = useState("");
  const [choosedCategory, setChoosedCategory] = useState("");
  const [attachment, setAttachment] = useState("");

  const fetchAllTicketCategories = async () => {
    try {
      const res = await axios.get("https://api.ykfuture.in/api/v1/ticketcategory/getticketcategories");

      console.log(res);

      setTicketCategories(res.data.ticketCategories);
    } catch (err) {
      console.log(err);
    }
  };

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      console.log(choosedCategory);
      const requestData = new FormData();

      requestData.append("raisedBy", localStorage.getItem("id"));
      requestData.append("category", choosedCategory);
      requestData.append("message", ticketMessage);
      requestData.append("subject", ticketSubject);
      requestData.append("priority", priority);
      requestData.append("ticket-attachment", attachment);

      const data = await axios.post("https://api.ykfuture.in/api/v1/ticket/raise-ticket", requestData);

      console.log(data);

      if (data.status === 201) {
        toast.success("Your Ticket Is Raised and Sent to admin", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchAllTicketCategories();
  }, []);

  return (
    <form
      action=""
      encType="multipart/form-data"
      className="addUser"
      style={{ width: "100%" }}
      onSubmit={formSubmitHandler}
    >
      <div className="form-part">
        <div className="input-group">
          <label htmlFor="ticket-subject">
            Subject <span>*</span>
          </label>
          <input
            type="text"
            name="ticket-subject"
            placeholder="Please Enter Your Subject"
            onChange={(event) => {
              setTicketSubject(event.target.value);
            }}
          />
        </div>
        <div className="input-group">
          <label htmlFor="message">
            Message <span>*</span>
          </label>
          <textarea
            type="text"
            name="message"
            placeholder="Please Enter Your Message"
            onChange={(event) => {
              setTicketMessage(event.target.value);
            }}
          />
        </div>
      </div>

      {/* Personal Details */}
      <div className="form-part">
        <div className="input-group">
          <label htmlFor="email">
            Category <span>*</span>
          </label>
          <select
            onChange={(event) => {
              setChoosedCategory(event.target.value);
            }}
          >
            <option>-- select an option --</option>
            {ticketCategories.map((el) => (
              <option key={el._id} value={el.category} style={{ textTransform: "capitalize" }}>
                {el.category}
              </option>
            ))}
          </select>
        </div>

        <div className="input-group">
          <label htmlFor="email">
            Priority <span>*</span>
          </label>
          <select
            onChange={(event) => {
              setPriority(event.target.value);
            }}
          >
            <option disabled>-- select an option --</option>
            <option value="low">Low</option>
            <option value="medium">Medium</option>
            <option value="high">Ligh</option>
          </select>
        </div>
        <div className="input-group">
          <label htmlFor="message">Select Attachment</label>
          <input
            type="file"
            name="message"
            placeholder="Please Enter Your Message"
            style={{ padding: "0px", border: "none", background: "none", boxShadow: "none" }}
            onChange={(event) => {
              setAttachment(event.target.files[0]);
            }}
          />
        </div>
        <div className="input-group">
          <Button type="submit" variant="outlined" color="secondary">
            Submit
          </Button>
        </div>
      </div>
      <ToastContainer />

      {/* <Dialog open={open} onClose={handleClose}>
          {selectEpinError ? (
            <p style={{ color: "red", fontWeight: "bold", margin: "5px" }}>{selectEpinErrorMsg}</p>
          ) : (
            ""
          )}
          <DialogTitle>Please Select Epin to complete the process</DialogTitle>
          <DialogContent>
           
          </DialogContent>
        </Dialog> */}
    </form>
  );
};

export default RaiseATickets;
