import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import AuthContext from "../../context/authContext";
import AreaGraph from "../../components/areaGraph/AreaGraph";
import DashBoardCard from "../../components/Cards/DashBoardCard";
import Navigation from "../../components/navigation/Navigation";
import Sidebar from "../../components/Sidebar/Sidebar";
import UserDataTable from "../../components/Tables/UserDataTable";

import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import RedeemIcon from "@mui/icons-material/Redeem";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import LineGraph from "../../components/Charts/LineChart";
import Footer from "../../components/Footer/Footer";
import BasicTable from "../../components/Tables/BasicTable";
import { AiOutlineLaptop } from "react-icons/ai";
import "./Home.css";

const Home = () => {
  const AuthCntx = useContext(AuthContext);
  const [activeBoards, setActiveBoards] = useState([]);
  const [participatedBoards, getParticipatedBoards] = useState([]);
  const [walletBalance, setWalletBalance] = useState("");
  const [moneyWithdrawn, setMoneyWithdrawn] = useState("");
  const [leadershipName, setLeadershipName] = useState("");
  const [directChildren, setDirectChildren] = useState("");
  const [teamSize, setTeamSize] = useState("");
  const [leadershipRewardPoint, setLeadershipRewardPoint] = useState("");

  const [activatedSubscription, setActivatedSubscription] = useState([]);
  const [userCoupon, setUserCoupon] = useState([]);

  const boughtsubscription = async () => {
    const userId = localStorage.getItem("id");
    let url;
    AuthCntx.role === "admin"
      ? (url = `https://api.ykfuture.in/api/v1/subscription/allsubscriptions`)
      : (url = `https://api.ykfuture.in/api/v1/user/${userId}/subscriptions`);
    try {
      const response = await axios.get(url);

      AuthCntx.role === "admin"
        ? setActivatedSubscription(response.data.subscriptions)
        : setActivatedSubscription(response.data.activeSubscriptions);
    } catch (err) {
      console.log(err);
    }
  };

  const getUserCoupon = async () => {
    try {
      const userId = localStorage.getItem("id");

      const url = `https://api.ykfuture.in/api/v1/user/get-user-coupon/${userId}`;
      const response = await axios(url);

      setUserCoupon(response.data.coupons);
    } catch (err) {}
  };

  const getAllActiveBoards = async () => {
    try {
      const res = await axios(`https://api.ykfuture.in/api/v1/user/${AuthCntx.user_id}`);

      setActiveBoards(res.data.user.activeBoards);
      getParticipatedBoards(res.data.user.participatedBoards);
    } catch (err) {
      console.log(err);
    }
  };

  const getWalletBalance = async () => {
    try {
      const res = await axios(`https://api.ykfuture.in/api/v1/user/wallet-balance/${AuthCntx.user_id}`);

      setWalletBalance(res.data.wallet.walletBalance);
    } catch (err) {
      console.log(err);
    }
  };

  const getMoneyWithdrawn = async () => {
    try {
      const res = await axios(`https://api.ykfuture.in/api/v1/user/money-withdrawn/${AuthCntx.user_id}`);
      setMoneyWithdrawn(res.data.moneyWithdrawn);
    } catch (err) {
      console.log(err);
    }
  };

  const getDirectChildren = async () => {
    try {
      const res = await axios(`https://api.ykfuture.in/api/v1/user/children-length/${AuthCntx.user_id}`);
      setDirectChildren(res.data.directChildren.length);
    } catch (err) {
      console.log(err);
    }
  };

  const getLeadershipName = async () => {
    try {
      const res = await axios(`https://api.ykfuture.in/api/v1/user/leadership-name/${AuthCntx.user_id}`);
      setLeadershipName(res.data.leadershipName);
    } catch (err) {
      console.log(err);
    }
  };

  const getTeamSize = async () => {
    try {
      const res = await axios(`https://api.ykfuture.in/api/v1/user/${AuthCntx.user_id}/team-size`);
      setTeamSize(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getLeadershipRewardPoint = async () => {
    try {
      const res = await axios(`https://api.ykfuture.in/api/v1/user/${AuthCntx.user_id}/lu-point`);
      setLeadershipRewardPoint(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllActiveBoards();
    getWalletBalance();
    getMoneyWithdrawn();
    getDirectChildren();
    getLeadershipName();
    getTeamSize();
    getLeadershipRewardPoint();
    boughtsubscription();
    getUserCoupon();
  }, []);

  return (
    <div id="homepage" className="page">
      <Sidebar />
      <div className="right-container">
        <Navigation />
        <div className="rest">
          <div className="card-container dashBoard_cardContainer">
            <DashBoardCard
              icon={<AccountBalanceWalletOutlinedIcon className="icon" />}
              amount={walletBalance ? `₹ ${walletBalance}` : "Inactive User"}
              heading="Wallet Balance"
            />
            <DashBoardCard
              icon={<PersonOutlineOutlinedIcon className="icon" />}
              amount={leadershipName ? leadershipName : "In-active User"}
              heading="Leadership Name"
            />
            <DashBoardCard
              icon={<ShareOutlinedIcon className="icon" />}
              amount={`₹ ${moneyWithdrawn}`}
              heading="Money Withdrawn"
            />
            <DashBoardCard
              icon={<AccountTreeOutlinedIcon className="icon" />}
              amount={directChildren}
              heading="Referrals"
            />
            <DashBoardCard
              icon={<AiOutlineLaptop className="icon" />}
              amount={walletBalance ? `₹ ${walletBalance}` : "In-active User"}
              heading="Pending Request"
            />
            <DashBoardCard
              icon={<PersonOutlineOutlinedIcon className="icon" />}
              amount={leadershipRewardPoint}
              heading="LU Points"
            />
            <DashBoardCard
              icon={<RedeemIcon className="icon" />}
              amount={`${moneyWithdrawn}`}
              heading="Available EPin"
            />
            <DashBoardCard icon={<GroupAddOutlinedIcon className="icon" />} amount={teamSize} heading="Team Size" />
          </div>

          <div className="graph-container">
            <AreaGraph title={"Income - last year"} />
            <LineGraph title={"Revenue - last year"} />
          </div>
          <div className="table-container">
            <BasicTable type="boards" heading="Active Boards" data={activeBoards} />
            <BasicTable type="boards" heading="Participated Boards" data={participatedBoards} />
          </div>

          {AuthCntx.role === "user" && (
            <div className="data-table-container">
              <UserDataTable
                name="Coupon"
                columnOneHeading="Available Gold"
                columnTwoHeading="Gold Limit"
                columnThreeHeading="Gold Discount"
                columnFourHeading="Coupon Expires At"
                columnFiveHeading="Referral Commission "
                columnSixHeading="Per Section Income"
                data={userCoupon}
                type="avilable_coup"
              />
            </div>
          )}

          <div className="data-table-container">
            <UserDataTable
              name="Subscription Name"
              columnOneHeading="Subscription Amount"
              columnTwoHeading="Subscription Validity"
              columnThreeHeading="Gold Limit"
              columnFourHeading="Gold Discount"
              columnFiveHeading="Referral Commission "
              columnSixHeading="Per Section Income"
              data={activatedSubscription}
              type="activated_subs"
            />
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default Home;
