import React from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import Navigation from "../../components/navigation/Navigation";
import SupportTicketComponent from "../../components/supportTicket/SupportTicket";

const SupportTicket = () => {
  return (
    <div id="profilepage" className="page">
      <Sidebar />
      <div className="right-container">
        <Navigation />
        <div className="rest">
          <div className="profile-container">
            <SupportTicketComponent />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportTicket;
