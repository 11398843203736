import React, { useState, useEffect, useCallback, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import AuthContext from "../../context/authContext";

import "./addUserForm.css";

const AddUserForm = () => {
  const history = useNavigate();

  const [open, setOpen] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);

  const [epins, setEpins] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [password, setPassword] = useState("");
  const [confPass, setConfPass] = useState("");
  const [subscriptionAmount, setSubscriptionAmount] = useState();
  const [subscriptionName, setSubscriptionName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [checked, setChecked] = useState(false);
  const [enableButton, setEnableButton] = useState(false);
  // const [subscription, setSubscription] = useState([]);

  const [activeSubscriptions, setActiveSubscriptions] = useState([]);
  const [pausedSubscriptions, setPausedSubscriptions] = useState([]);
  const [deactiveSubscriptions, setDeactiveSubscriptions] = useState([]);

  const [selectedDivs, setSelectedDivs] = useState([]);
  const [epinsAmount, setEpinsAmount] = useState([]);
  const [selectEpinError, setSelectEpinError] = useState(false);
  const [selectEpinErrorMsg, setSelectEpinErrorMsg] = useState("Please Select Epins");

  const [isUsernameUnique, setIsUsernameUnique] = useState(true);
  const [isUsernameValid, setIsUsernameValid] = useState(true);

  const newArry = useRef(null);
  const handleUsernameInput = (value) => {
    const sanitizedValue = value.replace(/[^a-z0-9]/gi, "").toLowerCase();
    setUserName(sanitizedValue);
    setIsUsernameValid(value === sanitizedValue);
    setTimeout(() => {
      setIsUsernameValid(true);
    }, 2000);
  };

  const userNameHandler = async (event) => {
    try {
      handleUsernameInput(event.target.value);
      if (event.target.value.trim() === "") {
        setIsUsernameUnique(true);
        return;
      }
      const response = await axios.post("https://api.ykfuture.in/api/v1/user/check-username-unique", {
        username: event.target.value,
      });
      setIsUsernameUnique(response.data.isUnique);
    } catch (error) {
      setIsUsernameUnique(false);
    }
  };

  const handleEmailInput = (event) => {
    setEmail(event.target.value);
  };

  const firstNameHandler = (event) => {
    setFirstName(event.target.value);
  };

  const lastNameHandler = (event) => {
    setLastName(event.target.value);
  };

  const mobileHandler = (event) => {
    setMobile(event.target.value);
  };

  const addressHandler = (event) => {
    setAddress(event.target.value);
  };

  const birthHandler = (event) => {
    setDateOfBirth(event.target.value);
  };

  const passwordHandler = (event) => {
    setPassword(event.target.value);
  };

  const confPasswordHandler = (event) => {
    setConfPass(event.target.value);
  };

  const checkBoxHandler = (event) => {
    setChecked(!checked);
    setEnableButton(!enableButton);
  };

  const handleClose = () => {
    console.log("Closing dialog");
    setOpen(false);
  };

  const subscriptionDetailsFetcher = async (id) => {
    try {
      const response = await fetch(`https://api.ykfuture.in/api/v1/subscription/${id}`);
      if (!response.ok) {
        throw new Error("Something Went Wrong");
      }
      const data = await response.json();

      setSubscriptionAmount(data.subscription.subscriptionAmount);
      setSubscriptionName(data.subscription.subscriptionName);
    } catch (err) {
      toast.error(err.response.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const AuthCntx = useContext(AuthContext);

  const testFetch = useCallback(async () => {
    const userId = localStorage.getItem("id");
    let url;
    AuthCntx.role === "admin"
      ? (url = `https://api.ykfuture.in/api/v1/subscription/allsubscriptions`)
      : (url = `https://api.ykfuture.in/api/v1/user/${userId}/subscriptions`);
    try {
      const response = await axios(url);

      setActiveSubscriptions(
        AuthCntx.role === "admin" ? response.data.subscriptions : response.data.activeSubscriptions
      );
      setDeactiveSubscriptions(AuthCntx.role === "admin" ? [] : response.data.deactiveSubscriptions);

      setPausedSubscriptions(AuthCntx.role === "admin" ? [] : response.data.pausedSubscriptions);
    } catch (err) {
      console.log(err);
    }
  }, [AuthCntx.role]);

  // fetch all epin of the curent user

  const fetchEpinHandler = async (id) => {
    try {
      const userID = localStorage.getItem("id");
      const res = await axios({
        method: "GET",
        url: "https://api.ykfuture.in/api/v1/epins/getepinsbyuser",
        headers: {
          id: userID,
        },
      });

      if (res.status === 200) {
        const filteredData = res.data.epins.filter((item) => item.isActive === true);
        setEpins(filteredData);
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      // epin modal opener
      fetchEpinHandler();
      setOpen(true);
    } catch (err) {
      toast.error(err.response.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  useEffect(() => {
    testFetch();
  }, [testFetch]);

  const handleDivClick = (divId, epin_Balance) => {
    if (selectedDivs.includes(divId)) {
      setSelectedDivs(selectedDivs.filter((id) => id !== divId));
      setEpinsAmount((prevEpinsAmount) => {
        const index = prevEpinsAmount.indexOf(epin_Balance);
        if (index !== -1) {
          return prevEpinsAmount.slice(0, index).concat(prevEpinsAmount.slice(index + 1));
        }
        return prevEpinsAmount;
      });
    } else {
      setSelectedDivs([...selectedDivs, divId]);
      setEpinsAmount([...epinsAmount, epin_Balance]);
    }
  };

  const sponsor = localStorage.getItem("username");

  const redeemEpin = async () => {
    try {
      const updatedData = {
        user: localStorage.getItem("id"),
        selectedDivs,
        epinsAmount,
        newArry: newArry.current,
      };

      await axios.patch("https://api.ykfuture.in/api/v1/epins/redeemEpin", updatedData);
    } catch (err) {
      toast.error(err.response.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };
  // create user function
  const createUserHandler = async (e) => {
    e.preventDefault();
    setIsWaiting(true);
    try {
      const response = await axios.post("https://api.ykfuture.in/api/v1/auth/createuserboard", {
        parent: sponsor,
        firstName: firstName,
        lastName: lastName,
        userName: userName,
        email: email,
        mobile: mobile,
        password: password,
        confPass: confPass,
        subscription: subscriptionName,
        address: address,
      });
      if (response.status === 201) {
        toast.success(`User Created Sucessfully`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        // console.log(response.data.user, response.data.user.userName);

        redeemEpin();
        setTimeout(() => {
          history("/");
        }, 2300);
      }
    } catch (err) {
      setOpen(false);
      setIsWaiting(false);

      console.log(err);

      toast.error(err.response.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  useEffect(() => {
    // in this variable we are adding all the amount collected from different epins
    const sum = epinsAmount.reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    if (subscriptionAmount <= sum) {
      setSelectEpinError(false);
      let remainingAmount = subscriptionAmount;
      let gstAmount = (3 * subscriptionAmount) / 100;
      newArry.current = epinsAmount.map((el) => {
        let subtractedValue = Math.min(remainingAmount, el);
        subtractedValue += gstAmount;
        remainingAmount -= subtractedValue;
        return el - subtractedValue;
      });
    } else if (subscriptionAmount > sum) {
      setSelectEpinError(true);
      setSelectEpinErrorMsg("Insufficient Balance !! Please select more Epins or Buy another");
    }
  }, [epinsAmount, subscriptionAmount]);

  return (
    <div style={{ position: "relative" }}>
      <form action="" className="addUser" onSubmit={formSubmitHandler}>
        <div className="form-part">
          <span className="part-heading">Generic Details</span>
          <div className="input-group">
            <label htmlFor="parent-username">
              Parent Username <span>*</span>
            </label>
            <input type="text" name="parent-username" value={sponsor} disabled />
          </div>
          <div className="input-group">
            <label htmlFor="username">
              Username <span>*</span>
            </label>

            {!isUsernameValid && (
              <p className="error-message" style={{ color: "red", fontSize: 14, marginTop: 5 }}>
                Username should contain only lowercase alphanumeric characters.
              </p>
            )}
            <input
              type="text"
              placeholder="Enter a unique Username"
              name="username"
              value={userName}
              onChange={userNameHandler}
            />

            {isUsernameUnique && userName && (
              <p className="success-message" style={{ color: "green", fontSize: 14, marginTop: 5 }}>
                Username is available!
              </p>
            )}
            {!isUsernameUnique && (
              <p style={{ color: "red", fontSize: 14, marginTop: 5 }}>Username is already taken.</p>
            )}
          </div>

          <div className="subscription-container">
            {activeSubscriptions.map((data) => (
              <label className="package-card" key={data._id}>
                <input
                  name="package"
                  className="radio"
                  type="radio"
                  value={data._id}
                  onChange={(e) => subscriptionDetailsFetcher(e.target.value)}
                />
                <span className="plan-details" aria-hidden="true">
                  <span className="plan-type">{data.subscriptionName}</span>
                  <span className="plan-cost">{data.subscriptionAmount} + 3%(GST)</span>
                  <span className="details">
                    <span className="product-details">
                      <span className="Subscription-validity">
                        Subscription Validity - {data.subscriptionValidity} Days
                      </span>
                      <span className="referral-commision">Referral Commision - {data.referralCommision}</span>
                    </span>
                    <span className="other-details">
                      <span className="gold-limit">Gold Limit - {data.goldLimit}</span>
                      <span className="gold-discount">Gold Disount - {data.goldDiscount}</span>
                    </span>
                  </span>
                </span>
              </label>
            ))}

            {pausedSubscriptions.map((data) => (
              <label className="package-card deactivated-package-card" key={data._id}>
                {/* <input name="package" className="radio" type="radio" value={data._id} disabled /> */}
                <span className="plan-details" aria-hidden="true">
                  <span className="plan-type">{data.subscriptionName}</span>
                  <span className="plan-cost">{data.subscriptionAmount} + 3%(GST)</span>
                  <span className="details">
                    <span className="product-details">
                      <span className="Subscription-validity">
                        Subscription Validity - {data.subscriptionValidity} Days
                      </span>
                      <span className="referral-commision">Referral Commision - {data.referralCommision}</span>
                    </span>
                    <span className="other-details">
                      <span className="gold-limit">Gold Limit - {data.goldLimit}</span>
                      <span className="gold-discount">Gold Disount - {data.goldDiscount}</span>
                    </span>
                  </span>
                </span>
                <Chip
                  variant="outlined"
                  color="error"
                  sx={{
                    height: "auto",
                    "& .MuiChip-label": {
                      display: "block",
                      whiteSpace: "normal",
                    },
                  }}
                  label="This is a deactivated subscription. Please Buy It again to reactivate"
                />
              </label>
            ))}

            {deactiveSubscriptions.map((data) => (
              <label className="package-card deactivated-package-card" key={data._id}>
                {/* <input name="package" className="radio" type="radio" value={data._id} disabled /> */}
                <span className="plan-details" aria-hidden="true">
                  <span className="plan-type">{data.subscriptionName}</span>
                  <span className="plan-cost">{data.subscriptionAmount} + 3%(GST)</span>
                  <span className="details">
                    <span className="product-details">
                      <span className="Subscription-validity">
                        Subscription Validity - {data.subscriptionValidity} Days
                      </span>
                      <span className="referral-commision">Referral Commision - {data.referralCommision}</span>
                    </span>
                    <span className="other-details">
                      <span className="gold-limit">Gold Limit - {data.goldLimit}</span>
                      <span className="gold-discount">Gold Disount - {data.goldDiscount}</span>
                    </span>
                  </span>
                </span>
                <Chip
                  variant="outlined"
                  color="error"
                  sx={{
                    height: "auto",
                    "& .MuiChip-label": {
                      display: "block",
                      whiteSpace: "normal",
                    },
                  }}
                  label="This is a deactivated subscription. Please Buy It again to reactivate"
                />
              </label>
            ))}
          </div>
        </div>

        {/* Personal Details */}
        <div className="form-part">
          <p className="part-heading">Personal Information</p>
          <div className="flex-input-group">
            <div className="first-name">
              <label htmlFor="firstname">
                First Name <span>*</span>
              </label>
              <input
                type="text"
                placeholder="Enter First Name"
                name="firstname"
                value={firstName}
                onChange={firstNameHandler}
              />
            </div>
            <div className="last-name">
              <label htmlFor="lastname">
                Last Name <span>*</span>
              </label>
              <input
                type="text"
                placeholder="Enter Last Name"
                name="lastname"
                value={lastName}
                onChange={lastNameHandler}
              />
            </div>
          </div>
          <div className="input-group">
            <label htmlFor="email">
              Email <span>*</span>
            </label>
            <input type="email" placeholder="Enter Email" name="email" value={email} onChange={handleEmailInput} />
          </div>
          <div className="input-group">
            <label htmlFor="mobile">
              Phone No. <span>*</span>
            </label>
            <input
              type="tel"
              placeholder="Enter Mobile Number"
              name="mobile"
              minLength={10}
              maxLength={10}
              value={mobile}
              onChange={mobileHandler}
            />
          </div>
          <div className="input-group">
            <label htmlFor="address">
              Primary Address <span>*</span>
            </label>
            <input type="text" placeholder="Enter Address" name="address" value={address} onChange={addressHandler} />
          </div>
          <div className="input-group">
            <label htmlFor="address">
              Date Of Birth <span>*</span>
            </label>
            <input
              type="date"
              placeholder="Enter Address"
              name="address"
              min="1990-01-01"
              max="2000-12-31"
              value={dateOfBirth}
              onChange={birthHandler}
            />
          </div>
          <div className="input-group">
            <label htmlFor="password">
              Password <span>*</span>
            </label>
            <input
              type="password"
              placeholder="Enter Password"
              name="password"
              value={password}
              onChange={passwordHandler}
            />
          </div>
          <div className="input-group">
            <label htmlFor="confpass">
              Confirm Password<span>*</span>
            </label>
            <input
              type="password"
              placeholder="Confirm Your Password"
              name="confpass"
              value={confPass}
              onChange={confPasswordHandler}
            />
          </div>
          <div className="input-group">
            <input type="checkbox" name="terms" id="terms" onChange={checkBoxHandler} />
            <label htmlFor="termsandconditions">
              I Read & Accept The Terms & Conditions <span>*</span>
            </label>
          </div>
          <div className="input-group">
            {enableButton ? (
              <Button type="submit" variant="outlined" color="secondary">
                Submit
              </Button>
            ) : (
              <Button type="submit" variant="outlined" color="secondary" disabled>
                Submit
              </Button>
            )}
          </div>
        </div>

        {/* EPIN MODAL */}
        <Dialog open={open} onClose={handleClose}>
          {selectEpinError ? (
            <p style={{ color: "red", fontWeight: "bold", margin: "5px" }}>{selectEpinErrorMsg}</p>
          ) : (
            ""
          )}
          <DialogTitle>Please Select Epin to complete the process</DialogTitle>
          <DialogContent>
            <form action="" onSubmit={createUserHandler}>
              <div className="dialog-form-part">
                <ul className="select-container">
                  {epins.map((epin) => (
                    <li
                      key={epin._id}
                      onClick={() => handleDivClick(epin._id, epin.balance)}
                      className={selectedDivs.includes(epin._id) ? "selectable_div selected" : "selectable_div"}
                    >
                      {epin.uniqueId}
                      <p style={{ marginTop: 10 }}>
                        <b>Balance:{epin.balance}</b>
                      </p>
                    </li>
                  ))}
                </ul>

                <div className="input-group">
                  <Button onClick={handleClose} color="error" style={{ marginRight: "10px" }}>
                    Cancel
                  </Button>

                  {selectEpinError && (
                    <Button type="submit" variant="outlined" color="secondary" disabled>
                      Submit
                    </Button>
                  )}

                  {!selectEpinError && !isWaiting && (
                    <Button type="submit" variant="outlined" color="secondary">
                      Submit
                    </Button>
                  )}

                  {!selectEpinError && isWaiting && (
                    // <Button variant="outlined" color="secondary">
                    <CircularProgress size={20} color="secondary" />
                    // </Button>
                  )}
                </div>
              </div>
            </form>
          </DialogContent>
        </Dialog>
      </form>

      <ToastContainer />
    </div>
  );
};

export default AddUserForm;
