import React from "react";
import Navigation from "../../components/navigation/Navigation";
import Sidebar from "../../components/Sidebar/Sidebar";
import AddUserForm from "../../components/Forms/AddUserForm";
import "./AddUser.css";

const AddUser = () => {
  return (
    <div id="adduser" className="page">
      <Sidebar />
      <div className="right-container">
        <Navigation />
        <div className="rest">
          <AddUserForm />
        </div>
      </div>
    </div>
  );
};

export default AddUser;
