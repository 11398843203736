import React from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import Navigation from "../../components/navigation/Navigation";
import RedeemRequests from "../../components/utils/RedeemRequests";

const RedeemRequestsPage = () => {
  return (
    <div id="profilepage" className="page">
      <Sidebar />
      <div className="right-container">
        <Navigation />
        <div className="rest">
          <RedeemRequests />
        </div>
      </div>
    </div>
  );
};

export default RedeemRequestsPage;
