import React, { useState, useContext } from "react";
import Button from "@mui/material/Button";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import AuthContext from "../../context/authContext";

const PaymentForm = (props) => {
  const history = useNavigate();

  const AuthCntxt = useContext(AuthContext);

  const [file, setFile] = useState("");
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  // user-create-function
  const createUserFunction = async (response) => {
    try {
      const verifyStatus = await axios.post("https://api.ykfuture.in/api/v1/payment/verifypayment", {
        response: response,
      });

      if (verifyStatus.status === 200) {
        // create transactin table
        await axios.post("https://api.ykfuture.in/api/v1/transactions/create-transaction", {
          user: localStorage.getItem("id"),
          type: "online-payment",
          amount: props.amountTotal,
          currency: "INR",
          paymentStatus: "completed",
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_order_id: response.razorpay_order_id,
          razorpay_signature: response.razorpay_signature,
        });

        await axios
          .post(
            "https://api.ykfuture.in/api/v1/epinrequests/make-request/online-payment",
            {
              requestedBy: localStorage.getItem("username"),
              requestedAmount: props.amountEach,
              paymentMethod: "online",
              numberOfEpins: props.numEPin,
              totalAmount: props.amountTotal,
            },
            {
              headers: {
                token: AuthCntxt.token,
              },
            }
          )
          .then((res) => {
            if (res.status === 200) {
              toast.success("Epin Request Sent To Admin", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });

              setTimeout(() => {
                history("/epins");
              }, 2300);
            }
          })
          .catch((err) => {
            toast.error(err.response.data.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          });
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  // Payment Handling

  const handleOpenRazorPay = (data) => {
    const options = {
      key: "rzp_test_vAblQkLMcMgpmn", // Enter the Key ID generated from the Dashboard
      amount: data.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: data.currency,
      name: "My Company B@C",
      description: "Test Transaction by akash",
      // "image": "https://example.com/your_logo",
      order_id: data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      handler: function (response) {
        // post data to transaction table
        createUserFunction(response);
      },
      prefill: {
        name: "Gaurav Kumar",
        email: "gaurav.kumar@example.com",
        contact: "9000090000",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  const handlePayment = (amount) => {
    const _data = { amount: amount };
    axios
      .post("https://api.ykfuture.in/api/v1/payment/createorder", _data)
      .then((res) => {
        handleOpenRazorPay(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const clickHandler = () => {
    handlePayment(props.amountTotal);
  };

  return (
    <>
      <div className="tab-container">
        <p>Total Amount: {props.amountTotal}</p>
        <div className="custom-tabs-container">
          <div className="container">
            <div className="block-container">
              <div className="bloc-tabs">
                <Button color="secondary" className="tabs" onClick={clickHandler}>
                  Pay Online
                </Button>

                {props.data.bankTransfer && (
                  <Button
                    color="secondary"
                    className={toggleState === 5 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(5)}
                  >
                    Bank Transfer
                  </Button>
                )}

                {props.data.cash && (
                  <Button
                    color="secondary"
                    className={toggleState === 6 ? "tabs active-tabs" : "tabs"}
                    onClick={() => toggleTab(6)}
                  >
                    Payment By Cash
                  </Button>
                )}
              </div>
            </div>

            <div className="content-tabs">
              {props.data.bankTransfer && (
                <div className={toggleState === 5 ? "content  active-content" : "content"}>
                  <form action="" encType="multipart/form-data" onSubmit={props.bankTransferEPinRequest}>
                    <div className="payment-form-part">
                      <span className="part-heading">Bank Transfer</span>
                      <div className="input-group">
                        <label htmlFor="receipt">
                          Upload Receipt<span>*</span>
                        </label>
                        <input
                          type="file"
                          id="avatar"
                          name="receipt"
                          accept="image/png, image/jpeg"
                          onChange={(e) => setFile(e.target.files[0])}
                        ></input>
                      </div>
                      <div className="input-group">
                        <Button
                          type="submit"
                          variant="outlined"
                          color="secondary"
                          onClick={() => {
                            props.receiptHandler(file);
                          }}
                        >
                          Submit
                        </Button>
                      </div>
                      <div className="bank-section">
                        <div className="bank">
                          <p className="bank-name">HDFC Bank</p>
                          <div className="other-details">
                            <p className="account-number">A/c No: &nbsp; 98756423567</p>
                            <p className="ifsc">IFSC : HDFC9836441739</p>
                          </div>
                        </div>
                        <div className="bank">
                          <p className="bank-name">ICICI Bank</p>
                          <div className="other-details">
                            <p className="account-number">A/c No: &nbsp; 98756423567</p>
                            <p className="ifsc">IFSC : ICICI9836441739</p>
                          </div>
                        </div>
                        <div className="bank">
                          <p className="bank-name">CANARA Bank</p>
                          <div className="other-details">
                            <p className="account-number">A/c No: &nbsp; 98756423567</p>
                            <p className="ifsc">IFSC : CANA9836441739</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              )}

              {props.data.cash && (
                <div className={toggleState === 6 ? "content  active-content" : "content"}>
                  <form action="" encType="multipart/form-data" onSubmit={props.cashPaymentHandler}>
                    <div className="payment-form-part">
                      <span className="part-heading">Payment By Cash</span>
                      <div className="input-group">
                        <label htmlFor="receipt">
                          Upload Receipt<span>*</span>
                        </label>
                        <input
                          type="file"
                          id="avatar"
                          name="receipt"
                          accept="image/png, image/jpeg"
                          onChange={(e) => setFile(e.target.files[0])}
                        ></input>
                      </div>
                      <div className="input-group">
                        <Button
                          type="submit"
                          variant="outlined"
                          color="secondary"
                          onClick={() => {
                            props.receiptHandler(file);
                          }}
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default PaymentForm;
