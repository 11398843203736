import React, { useState } from "react";

const AuthContext = React.createContext({
  token: "",
  isLoggedIn: false,
  role: "user",
  userName: "",
  userData: {},
  login: (token) => {},
  logout: () => {},
});

export const AuthContextProvider = (props) => {
  const initialToken = localStorage.getItem("token");
  const initialRole = localStorage.getItem("role");
  const initialUserId = localStorage.getItem("id");
  const initialUserName = localStorage.getItem("username");

  const [token, setToken] = useState(initialToken);
  const [role, setRole] = useState(initialRole);
  const [userId, setUserId] = useState(initialUserId);
  const [userName, setUseName] = useState(initialUserName);

  const userIsLoggedIn = !!token;
  const userRole = role;
  const user_id = userId;
  const newUserName = userName;

  const loginHandler = (token, role, id, userName, userData) => {
    setToken(token);
    setRole(role);
    setUserId(id);
    setUseName(userName);

    localStorage.setItem("token", token);
    localStorage.setItem("role", role);
    localStorage.setItem("id", id);
    localStorage.setItem("username", userName);
    localStorage.setItem("userdata", JSON.stringify(userData));
  };

  const logoutHandler = () => {
    window.location.assign("/");

    setToken(null);
    setRole(null);
    setUserId(null);
    setUseName(null);

    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("id");
    localStorage.removeItem("username");
    localStorage.removeItem("userdata");
  };

  const contextValue = {
    token: token,
    isLoggedIn: userIsLoggedIn,
    role: userRole,
    user_id: user_id,
    userName: newUserName,

    login: loginHandler,
    logout: logoutHandler,
  };

  return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>;
};

export default AuthContext;
