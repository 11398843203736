import React from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import Navigation from "../../components/navigation/Navigation";
import Footer from "../../components/Footer/Footer";
import EPinRequests from "../../components/utils/EPinRequests";

const AllEpinRequestes = () => {
  return (
    <div id="boardpage" className="page">
      <Sidebar />
      <div className="right-container">
        <Navigation />
        <div className="rest">
          <EPinRequests />
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default AllEpinRequestes;
