import React, { useEffect, useState, useCallback } from "react";
import Navigation from "../../components/navigation/Navigation";
import Sidebar from "../../components/Sidebar/Sidebar";
import BasicTable from "../../components/Tables/BasicTable";
import axios from "axios";

const AllBoardsPage = () => {
  const [allBoards, setAllBoards] = useState([]);

  const getBoardsHandler = useCallback(async () => {
    const response = await axios.get("https://api.ykfuture.in/api/v1/board/getallboards");

    setAllBoards(response.data.boards);
  }, []);

  useEffect(() => {
    getBoardsHandler();
  }, [getBoardsHandler]);

  return (
    <div id="homepage" className="page">
      <Sidebar />
      <div className="right-container">
        <Navigation />
        <div className="rest">
          <BasicTable width="100%" type="allboards" heading="All Boards" data={allBoards} />
        </div>
      </div>
    </div>
  );
};

export default AllBoardsPage;
