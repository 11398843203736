import React from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import Navigation from "../../components/navigation/Navigation";
import Footer from "../../components/Footer/Footer";
import KycRequests from "../../components/KYC/KycRequests";

const AllKycRequests = () => {
  return (
    <div id="boardpage" className="page">
      <Sidebar />
      <div className="right-container">
        <Navigation />
        <div className="rest">
          <KycRequests />
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default AllKycRequests;
