import React, { useState, useEffect, useCallback } from "react";
import "./profile.css";

import mobSiteLogo from "../../profile.jpg";
import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import defaultProfileImg from "../../images/profile.png";
import { RxCross1 } from "react-icons/rx";
import Divider from "@mui/material/Divider";
import DoneAllIcon from "@mui/icons-material/DoneAll";

const ProfileComponent = (props) => {
  const [userDetails, setUserDetails] = useState([]);
  const [open, setOpen] = useState(false);
  const [kycDialog, setKycDialog] = useState(false);
  const [profilePicDialog, setProfilePicDialog] = useState(false);

  // Edit Params
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [address, setAddress] = useState("");

  // KYC Form Details
  const [idNumber, setIdNumber] = useState("");
  const [idFile, setIdFile] = useState();
  const [panNumber, setPanNumber] = useState("");
  const [panFile, setPanFile] = useState();
  const [accountHolderName, setAccountHolderName] = useState("");
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [bankIFSC, setBankIFSC] = useState("");
  const [bankBranchName, setBankBranchName] = useState("");
  const [bankFile, setBankFile] = useState("");

  // profile-img
  const [profileImg, setProfileImg] = useState("");

  // Change-password
  const [changePasswordDialog, setChangePasswordDialog] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [passwordForChange, setPasswordForChange] = useState("");
  const [confirmPasswordForChange, setConfirmPasswordForChange] = useState("");
  const [passwordNotSame, setPasswordNotSame] = useState(false);

  const [KycDetails, setKycDetails] = useState("");

  // verify-email
  const [otpDialog, setOtpDialog] = useState(false);
  const [otp, setOtp] = useState("");
  const [isSendingEmail, setIsSendingEmail] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setKycDialog(false);
    setProfilePicDialog(false);
    setChangePasswordDialog(false);
    setOtpDialog(false);
  };

  const userId = localStorage.getItem("id");

  const getUserDetails = useCallback(async () => {
    try {
      const response = await axios.get(`https://api.ykfuture.in/api/v1/user/${userId}`);
      setUserDetails(response.data.user);
    } catch (err) {
      console.log(err);
    }
  }, [userId]);

  const editUserHandler = async (e) => {
    try {
      e.preventDefault();
      const data = {
        id: localStorage.getItem("id"),
        firstName: firstName ? firstName : userDetails.firstName,
        lastName: lastName ? lastName : userDetails.lastName,
        email: email ? email : userDetails.email,
        address: address ? address : userDetails.address,
        phoneNo: phoneNo ? phoneNo : userDetails.phoneNo,
      };
      const response = await axios.patch("https://api.ykfuture.in/api/v1/auth/updateme", data);
      if (response.status === 200) {
        toast.success("Updated successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        setOpen(false);

        setFirstName("");
        setLastName("");
        setEmail("");
        setPhoneNo("");
        setAddress("");

        getUserDetails();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const verifyEmailHandler = async () => {
    setIsSendingEmail(true);

    try {
      const res = await axios.post("https://api.ykfuture.in/api/v1/user/verify-email", {
        userName: localStorage.getItem("username"),
      });
      if (res.status === 200) {
        setOtpDialog(true);
        setIsSendingEmail(false);

        toast.success("OTP Sent to your mail", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (err) {
      toast.message(err.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const submitOtpHandler = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post("https://api.ykfuture.in/api/v1/user/verify-otp", {
        otp: otp,
      });

      if (res.status === 200) {
        setOtpDialog(false);
        setOtp("");
        getUserDetails();
        toast.success("Email Verified Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (err) {
      setOtpDialog(false);

      toast.message(err.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  useEffect(() => {
    getUserDetails();
  }, [getUserDetails]);

  const handleClickOpen = () => {
    setOpen(true);
    setFirstName(userDetails.firstName);
    setLastName(userDetails.lastName);
    setEmail(userDetails.email);
    setPhoneNo(userDetails.phoneNo);
    setAddress(userDetails.address);
  };

  const handleKycDialogOpen = () => {
    setKycDialog(true);
  };

  const kycFormHandler = async (e) => {
    e.preventDefault();
    try {
      const requestData = new FormData();

      requestData.append("userName", localStorage.getItem("username"));
      requestData.append("user", localStorage.getItem("id"));
      requestData.append("idNumber", idNumber);
      requestData.append("idFile", idFile);
      requestData.append("panNumber", panNumber);
      requestData.append("panFile", panFile);
      requestData.append("accountHolderName", accountHolderName);
      requestData.append("bankIfsc", bankIFSC);
      requestData.append("bankBrachName", bankBranchName);
      requestData.append("bankFile", bankFile);
      requestData.append("bankAccountNumber", bankAccountNumber);

      const data = await axios.post("https://api.ykfuture.in/api/v1/kyc/make-kyc-request", requestData);

      if (data.status === 200) {
        toast.success("Requested Sent For Verification", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        setKycDialog(false);

        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    } catch (err) {
      toast.error(err.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      setKycDialog(false);

      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  };

  const handleProfilePic = () => {
    setProfilePicDialog(true);
  };

  const profileImgHandler = async (e) => {
    e.preventDefault();
    try {
      const requestData = new FormData();

      requestData.append("userName", localStorage.getItem("username"));
      requestData.append("profileImg", profileImg);

      const data = await axios.post("https://api.ykfuture.in/api/v1/user/upload-profile-pic", requestData);

      if (data.status === 200) {
        toast.success("Profile Pic Uploaded Sucessfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        setProfilePicDialog(false);

        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    } catch (err) {
      toast.error(err.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      setProfilePicDialog(false);

      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
  };

  const handlePasswordChangeDialog = () => {
    setChangePasswordDialog(true);
  };

  const changePasswordHanler = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.patch(
        "https://api.ykfuture.in/api/v1/auth/updatepassword",
        {
          passwordCurrent: currentPassword,
          password: passwordForChange,
          passwordConfirm: confirmPasswordForChange,
        },
        {
          headers: {
            authorization: localStorage.getItem("token"),
          },
        }
      );

      if (res.status === 200) {
        toast.success("Password Changed Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        setChangePasswordDialog(false);

        setCurrentPassword("");
        setPasswordForChange("");
        setConfirmPasswordForChange("");
      }
    } catch (err) {
      toast.error(err.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const checkPasswordEquality = useCallback(() => {
    if (confirmPasswordForChange.length > 0) {
      if (passwordForChange === confirmPasswordForChange) {
        setPasswordNotSame(false);
      } else {
        setPasswordNotSame(true);
      }
    } else {
      setPasswordNotSame(false);
    }
  }, [confirmPasswordForChange]);

  // get kyc details
  const getKycDetails = async () => {
    try {
      const res = await axios.get(`https://api.ykfuture.in/api/v1/user/get-kyc-details/${userDetails._id}`);

      setKycDetails(res.data.KycDetails);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    checkPasswordEquality();

    if (userDetails.kycStatus === "verified") {
      getKycDetails();
    }
  }, [confirmPasswordForChange, checkPasswordEquality]);

  if (props.type === "user-view") {
    return (
      <>
        <div className="profile-component">
          <div className="top">
            <div className="edit-button-container">
              <Button
                variant="contained"
                color="secondary"
                className="edit-btn"
                endIcon={<EditIcon />}
                onClick={handleClickOpen}
              >
                Edit
              </Button>
            </div>

            <div className="left">
              <div className="img-container" onClick={handleProfilePic}>
                <img src={userDetails.profileImg ? userDetails.profileImg : defaultProfileImg} alt="profile-img" />
              </div>

              <Dialog open={profilePicDialog} onClose={handleClose}>
                <DialogTitle className="Registration_title">
                  <span>Upload Profile Pic</span>
                  <RxCross1 style={{ cursor: "pointer" }} onClick={handleClose} />
                </DialogTitle>
                <DialogContent>
                  <form action="" encType="multipart/form-data" onSubmit={profileImgHandler}>
                    <div className="dialog-form-part">
                      <div className="input-group">
                        <label htmlFor="profile-img">Choose an image (to upload as profile pic)</label>
                        <input
                          type="file"
                          id="profile-img"
                          name="profile-img"
                          accept="image/png, image/jpeg, image/jpg"
                          onChange={(e) => setProfileImg(e.target.files[0])}
                        />
                      </div>
                      <div className="input-group">
                        <Button onClick={handleClose} color="error" style={{ marginRight: "10px" }}>
                          Cancel
                        </Button>
                        <Button type="submit" color="secondary">
                          Submit
                        </Button>
                      </div>
                    </div>
                  </form>
                </DialogContent>
              </Dialog>
              <p className="username">{userDetails.userName}</p>
              <p className="sponsorId">({userDetails.sponsorId})</p>
              <div className="left-side-button-container">
                {userDetails.isEmailVerified ? (
                  <Button color="success" variant="contained" endIcon={<DoneAllIcon />}>
                    Email Verified
                  </Button>
                ) : (
                  <Button color="error" variant="outlined" onClick={verifyEmailHandler}>
                    {isSendingEmail ? "Please Wait..." : "Verify Email"}
                  </Button>
                )}

                <Dialog open={otpDialog} onClose={handleClose}>
                  <DialogTitle className="Registration_title">
                    <span>Verify Your Email</span>
                    <RxCross1 style={{ cursor: "pointer" }} onClick={handleClose} />
                  </DialogTitle>
                  <DialogContent>
                    <form action="" method="post" onSubmit={submitOtpHandler}>
                      <div className="dialog-form-part">
                        <div className="input-group_chngepass">
                          <label htmlFor="otp">
                            Enter OTP (sent to email)<span>*</span>
                          </label>
                          <input
                            type="number"
                            id="otp"
                            name="otp"
                            placeholder="Please Enter Your Current Password"
                            value={otp}
                            required
                            onChange={(e) => setOtp(e.target.value)}
                          />
                        </div>
                        <div className="input-group_chngepass">
                          <Button onClick={handleClose} color="error" style={{ marginRight: "10px" }}>
                            Cancel
                          </Button>
                          <Button type="submit" color="secondary">
                            Submit
                          </Button>
                        </div>
                      </div>
                    </form>
                  </DialogContent>
                </Dialog>
                {userDetails.kycStatus === "pending" && (
                  <Button color="error" variant="outlined" className="request-epin" onClick={handleKycDialogOpen}>
                    kyc Pending
                  </Button>
                )}

                {userDetails.kycStatus === "review" && (
                  <Button color="warning" variant="contained" className="request-epin">
                    Sent For Review
                  </Button>
                )}

                {userDetails.kycStatus === "verified" && (
                  <Button color="success" variant="contained" className="request-epin" endIcon={<DoneAllIcon />}>
                    kyc Verified
                  </Button>
                )}

                {/* KYC DiaLOG */}
                <Dialog open={kycDialog} onClose={handleClose}>
                  <DialogTitle className="Registration_title">
                    <span>KYC Form</span>
                    <RxCross1 style={{ cursor: "pointer" }} onClick={handleClose} />
                  </DialogTitle>
                  <DialogContent>
                    <form action="" onSubmit={kycFormHandler}>
                      <div className="dialog-form-part">
                        <div className="personalIdProfContainer kyc_head">
                          <div className="__header">
                            <span>Personal Id Proof</span>
                          </div>
                          <Divider sx={{ margin: "0.3em 0" }} />

                          <div className="InputContainer">
                            <div className="input-group">
                              <label htmlFor="id-card-no">Id Number</label>
                              <span>*</span>
                              <p>(as on card provided)</p>
                              <input
                                type="text"
                                name="id-card-on"
                                value={idNumber}
                                onChange={(e) => {
                                  setIdNumber(e.target.value);
                                }}
                                placeholder="XXXXXXXX"
                              />
                            </div>
                            <div className="input-group">
                              <label htmlFor="id-card">ID Card</label> <span>*</span>
                              <p>(Adhar Card / Voter Card / Driving Licence)</p>
                              <input
                                type="file"
                                name="id-card"
                                accept="image/png, image/jpeg"
                                onChange={(e) => {
                                  setIdFile(e.target.files[0]);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="panCardContainer kyc_head">
                          <div className="__header">
                            <span>Pan Card details</span>
                          </div>
                          <Divider sx={{ margin: "0.3em 0" }} />
                          <div className="InputContainer">
                            <div className="input-group">
                              <label htmlFor="pan-card-no">PAN Number</label>
                              <span>*</span>
                              <p>(as on card provided)</p>
                              <input
                                type="text"
                                name="pan-card-on"
                                placeholder="xxxxxxxxxx"
                                value={panNumber}
                                onChange={(e) => {
                                  setPanNumber(e.target.value);
                                }}
                              />
                            </div>
                            <div className="input-group">
                              <label htmlFor="pan-card">Pan Card</label>
                              <span>*</span>
                              <input
                                type="file"
                                name="pan-card"
                                accept="image/png, image/jpeg"
                                onChange={(e) => {
                                  setPanFile(e.target.files[0]);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="bankAccountContainer kyc_head">
                          <div className="__header">
                            <span>Bank Account Details</span>
                          </div>
                          <Divider sx={{ margin: "0.3em 0" }} />
                          <div className="InputContainer">
                            <div className="input-group">
                              <label htmlFor="account-name">Account Holder Name</label>
                              <span>*</span>
                              <input
                                type="text"
                                name="account-name"
                                placeholder="Enter The Name of Account Holder"
                                onChange={(e) => {
                                  setAccountHolderName(e.target.value);
                                }}
                              />
                            </div>
                            <div className="input-group">
                              <label htmlFor="account-number">Bank Account Number</label>
                              <span>*</span>
                              <input
                                type="number"
                                name="account-number"
                                placeholder="Enter Your Bank Account Number"
                                value={bankAccountNumber}
                                onChange={(e) => {
                                  setBankAccountNumber(e.target.value);
                                }}
                              />
                            </div>
                            <div className="input-group">
                              <label htmlFor="ifsc">IFSC Code</label>
                              <span>*</span>
                              <input
                                type="text"
                                name="ifsc"
                                value={bankIFSC}
                                placeholder="Enter IFSC Your Bank "
                                onChange={(e) => {
                                  setBankIFSC(e.target.value);
                                }}
                              />
                            </div>
                            <div className="input-group">
                              <label htmlFor="branch-name">Branch Name</label>
                              <span>*</span>
                              <input
                                type="text"
                                name="branch-name"
                                placeholder="Enter Your Bank's Branch Name"
                                value={bankBranchName}
                                onChange={(e) => {
                                  setBankBranchName(e.target.value);
                                }}
                              />
                            </div>
                            <div className="input-group">
                              <label htmlFor="bank-document">Upload (any bank document)</label>
                              <span>*</span>
                              <input
                                type="file"
                                name="bank-document"
                                accept="image/png, image/jpeg"
                                onChange={(e) => {
                                  setBankFile(e.target.files[0]);
                                }}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="input-group">
                          <Button onClick={handleClose} color="error">
                            Cancel
                          </Button>
                          <Button type="submit" color="secondary">
                            Submit
                          </Button>
                        </div>
                      </div>
                    </form>
                  </DialogContent>
                </Dialog>
              </div>

              <Button variant="outlined" onClick={handlePasswordChangeDialog}>
                Change Password{" "}
              </Button>

              <Dialog open={changePasswordDialog} onClose={handleClose}>
                <DialogTitle className="Registration_title">
                  <span>Change Password</span>
                  <RxCross1 style={{ cursor: "pointer" }} onClick={handleClose} />
                </DialogTitle>
                <DialogContent>
                  <form action="" method="post" encType="multipart/form-data" onSubmit={changePasswordHanler}>
                    <div className="dialog-form-part">
                      <div className="input-group">
                        <label htmlFor="current-password">
                          Enter Your Current Password <span>*</span>
                        </label>
                        <input
                          type="password"
                          id="current-password"
                          name="current-password"
                          placeholder="Please Enter Your Current Password"
                          value={currentPassword}
                          required
                          onChange={(e) => setCurrentPassword(e.target.value)}
                        />
                      </div>
                      <div className="input-group">
                        <label htmlFor="password">
                          Enter Your Password <span>*</span>
                        </label>
                        <input
                          type="password"
                          id="password"
                          name="password"
                          placeholder="Please Enter Your Password"
                          value={passwordForChange}
                          required
                          onChange={(e) => setPasswordForChange(e.target.value)}
                        />
                        {passwordForChange && passwordForChange.length < 8 && (
                          <p style={{ color: "red" }}> The Length of the password Should be 8 characters</p>
                        )}
                      </div>
                      <div className="input-group">
                        <label htmlFor="conf-password">
                          Confirm Your Password <span>*</span>
                        </label>
                        {passwordForChange && passwordForChange.length >= 8 && (
                          <input
                            type="password"
                            id="conf-password"
                            name="conf-password"
                            placeholder="Confirm Your Password"
                            value={confirmPasswordForChange}
                            required
                            onChange={(e) => setConfirmPasswordForChange(e.target.value)}
                          />
                        )}

                        {!passwordForChange && (
                          <input type="passwordForChange" placeholder="Please Fill the Password First" disabled />
                        )}

                        {passwordForChange && passwordForChange.length < 8 && (
                          <input
                            type="password"
                            placeholder="The Length of the password should be 8 characters"
                            disabled
                          />
                        )}
                        {passwordNotSame && <p style={{ color: "red" }}> Passwords Do Not Match</p>}
                      </div>
                      <div className="input-group">
                        <Button onClick={handleClose} color="error" style={{ marginRight: "10px" }}>
                          Cancel
                        </Button>
                        <Button type="submit" color="secondary">
                          Submit
                        </Button>
                      </div>
                    </div>
                  </form>
                </DialogContent>
              </Dialog>
            </div>
            <div className="right">
              <div className="general-info">
                <div className="details-wrapper">
                  <p className="heading">Username:</p>
                  <input
                    type="text"
                    className="result"
                    value={userDetails.userName ? userDetails.userName : "something went wrong"}
                    disabled
                  />
                </div>
                <div className="FirstNdLastNameWrapper">
                  <div className="details-wrapper">
                    <p className="heading">First Name:</p>
                    <input
                      type="text"
                      className="result"
                      value={userDetails.firstName ? userDetails.firstName : "something went wrong"}
                      disabled
                    />
                  </div>
                  {userDetails.lastName ? (
                    <div className="details-wrapper">
                      <p className="heading">Last Name:</p>
                      <input
                        type="text"
                        className="result"
                        value={userDetails.lastName ? userDetails.lastName : "something went wrong"}
                        disabled
                      />
                    </div>
                  ) : (
                    " "
                  )}
                </div>
                <div className="details-wrapper">
                  <p className="heading">Mobile:</p>
                  <input
                    type="number"
                    className="result"
                    value={userDetails.phoneNo ? userDetails.phoneNo : "something went wrong"}
                    disabled
                  />
                </div>
                <div className="details-wrapper">
                  <p className="heading">Email:</p>
                  <input
                    type="text"
                    className="result"
                    value={userDetails.email ? userDetails.email : "something went wrong"}
                    disabled
                  />
                </div>
                <div className="details-wrapper">
                  <p className="heading">DOB:</p>
                  <input
                    type="text"
                    className="result"
                    // add DOB through backend Ask
                    value={"12/11/2000"}
                    disabled
                  />
                </div>
                <div className="details-wrapper">
                  <p className="heading">Address:</p>
                  <input
                    type="text"
                    className="result"
                    value={userDetails.address ? userDetails.address : "something went wrong"}
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
          {userDetails.KycDetails === "verified" && (
            <div className="top" style={{ marginTop: "20px", alignItems: "center" }}>
              <div className="left">
                <div className="img-container">
                  <img src={KycDetails.idFile} alt="profile-img" />
                </div>

                <div className="img-container">
                  <img src={KycDetails.panFile} alt="profile-img" />
                </div>

                <div className="img-container">
                  <img src={KycDetails.bankDetailsFile} alt="profile-img" />
                </div>
              </div>
              <div className="right">
                <div className="general-info">
                  <div className="details-wrapper">
                    <p className="heading">ID Number:</p>
                    <input type="text" className="result" value={KycDetails.idNumber} disabled />
                  </div>
                  <div className="details-wrapper">
                    <p className="heading">Pan Number</p>
                    <input type="text" className="result" value={KycDetails.panNumber} disabled />
                  </div>
                  <div className="details-wrapper">
                    <p className="heading">Bank Account Holder Name:</p>
                    <input type="text" className="result" value={KycDetails.accountHolderName} disabled />
                  </div>
                  <div className="details-wrapper">
                    <p className="heading">Bank Account Number:</p>
                    <input type="number" className="result" value={KycDetails.bankAccountNumber} disabled />
                  </div>
                  <div className="details-wrapper">
                    <p className="heading">Bank IFSC:</p>
                    <input type="text" className="result" value={KycDetails.bankIfsc} disabled />
                  </div>
                  <div className="details-wrapper">
                    <p className="heading">Bank Branch:</p>
                    <input type="text" className="result" value={KycDetails.bankBrachName} disabled />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <Dialog open={open} fullWidth={true} maxWidth="xs" onClose={handleClose}>
          <DialogTitle>Change Your Personal Details</DialogTitle>
          <DialogContent>
            <form action="" onSubmit={editUserHandler}>
              <div className="dialog-form-part profile_edit_dialog">
                <div className="input-group">
                  <label htmlFor="firstName">First Name</label>
                  <input
                    type="text"
                    name="firstName"
                    value={firstName}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    type="text"
                    name="lastName"
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                  />
                </div>

                <div className="input-group">
                  <label htmlFor="email">Email :</label>
                  <input
                    type="text"
                    name="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>

                <div className="input-group">
                  <label htmlFor="phone-no">Phone No.</label>
                  <input
                    type="text"
                    name="phone-no"
                    value={phoneNo}
                    onChange={(e) => {
                      setPhoneNo(e.target.value);
                    }}
                  />
                </div>

                <div className="input-group">
                  <label htmlFor="address">Address</label>
                  <input
                    type="text"
                    name="address"
                    value={address}
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                  />
                </div>

                <div className="input-group">
                  <Button onClick={handleClose} color="error" style={{ marginRight: "10px" }}>
                    Cancel
                  </Button>
                  <Button type="submit" color="secondary">
                    Submit
                  </Button>
                </div>
              </div>
            </form>
          </DialogContent>
        </Dialog>

        <ToastContainer />
      </>
    );
  } else {
    return (
      <div className="profile-component">
        <div className="top">
          <div className="left">
            <div className="img-container">
              <img src={mobSiteLogo} alt="profile-img" />
            </div>
            <p className="username">{userDetails.userName}</p>
          </div>
          <div className="right">
            <div className="general-info">
              <div className="details-wrapper">
                <p className="heading">Username:</p>
                <input type="text" className="result" value={userDetails.userName} disabled />
              </div>
              <div className="details-wrapper">
                <p className="heading">Full Name:</p>
                <input
                  type="text"
                  className="result"
                  value={`${userDetails.firstName} ${userDetails.lastName}`}
                  disabled
                />
              </div>

              <div className="details-wrapper">
                <p className="heading">Mobile:</p>
                <input type="number" className="result" value={userDetails.phoneNo} disabled />
              </div>
              <div className="details-wrapper">
                <p className="heading">Email:</p>
                <input type="text" className="result" value={userDetails.email} disabled />
              </div>
              <div className="details-wrapper">
                <p className="heading">Address:</p>
                <input type="text" className="result" value={userDetails.address} disabled />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default ProfileComponent;
