import React, { useState, useEffect } from "react";
import axios from "axios";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import "./genelogyTree.css";

const GenelogyTree = () => {
  const [curentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem("userdata")));

  const [children, setChildren] = useState({
    childrenLevelOne: [],
    childrenLevelTwo: [],
    childrenLevelThree: [],
    childrenLevelFour: [],
    childrenLevelFive: [],
    childrenLevelSix: [],
    childrenLevelSeven: [],
    childrenLevelEight: [],
    childrenLevelNine: [],
    childrenLevelTen: [],
    childrenLevelEleven: [],
    childrenLevelTwelve: [],
    childrenLevelThirteen: [],
    childrenLevelFourteen: [],
    childrenLevelFifteen: [],
    childrenLevelSixteen: [],
    childrenLevelSeventeen: [],
    childrenLevelEighteen: [],
    childrenLevelNineteen: [],
    childrenLevelTwenty: [],
    childrenLevelTwentyOne: [],
    childrenLevelTwentyTwo: [],
    childrenLevelTwentyThree: [],
    childrenLevelTwentyFour: [],
    childrenLevelTwentyFive: [],
  });

  const [showChildren, setShowChildren] = useState({
    showLevelOneChild: false,
    showLevelTwoChild: false,
    showLevelThreeChild: false,
    showLevelFourChild: false,
    showLevelFiveChild: false,
    showLevelSixChild: false,
    showLevelSevenChild: false,
    showLevelEightChild: false,
    showLevelNineChild: false,
    showLevelTenChild: false,
    showLevelElevenChild: false,
    showLevelTwelveChild: false,
    showLevelThirteenChild: false,
    showLevelFourteenChild: false,
    showLevelFifteenChild: false,
    showLevelSixteenChild: false,
    showLevelSeventeenChild: false,
    showLevelEighteenChild: false,
    showLevelNineteenChild: false,
    showLevelTwentyChild: false,
    showLevelTwentyOneChild: false,
    showLevelTwentyTwoChild: false,
    showLevelTwentyThreeChild: false,
    showLevelTwentyFourChild: false,
    showLevelTwentyFiveChild: false,
  });

  const [parent, setParent] = useState({
    parentOfLevelOne: "",
    parentOfLevelTwo: "",
    parentOfLevelThree: "",
    parentOfLevelFour: "",
    parentOfLevelFive: "",
    parentOfLevelSix: "",
    parentOfLevelSeven: "",
    parentOfLevelEight: "",
    parentOfLevelNine: "",
    parentOfLevelTen: "",
    parentOfLevelEleven: "",
    parentOfLevelTwelve: "",
    parentOfLevelThirteen: "",
    parentOfLevelFourteen: "",
    parentOfLevelFifteen: "",
    parentOfLevelSixteen: "",
    parentOfLevelSeventeen: "",
    parentOfLevelEighteen: "",
    parentOfLevelNineteen: "",
    parentOfLevelTwenty: "",
    parentOfLevelTwentyOne: "",
    parentOfLevelTwentyTwo: "",
    parentOfLevelTwentyThree: "",
    parentOfLevelTwentyFour: "",
    parentOfLevelTwentyFive: "",
  });

  const getChildren = async (id) => {
    try {
      const res = await axios.get(`https://api.ykfuture.in/api/v1/user/${id}/direct-children`);
      return res;
    } catch (err) {
      console.log(err);
    }
  };

  const getChildrenLevelOne = async () => {
    try {
      const data = await getChildren(curentUser._id);
      setShowChildren((prevData) => {
        return { ...prevData, showLevelOneChild: true };
      });

      setChildren((prevData) => {
        return { ...prevData, childrenLevelOne: data.data };
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getChildrenLevelTwo = async (id, grandChildOf) => {
    try {
      const data = await getChildren(id);

      if (data.status === 200) {
        setShowChildren((prevData) => {
          return { ...prevData, showLevelTwoChild: true };
        });

        setChildren((prevData) => {
          return { ...prevData, childrenLevelTwo: data.data };
        });

        setParent((prevData) => {
          return { ...prevData, parentOfLevelTwo: grandChildOf };
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getChildrenLevelThree = async (id, grandChildOf) => {
    try {
      const data = await getChildren(id);

      if (data.status === 200) {
        setShowChildren((prevData) => {
          return { ...prevData, showLevelThreeChild: true };
        });

        setChildren((prevData) => {
          return { ...prevData, childrenLevelThree: data.data };
        });

        setParent((prevData) => {
          return { ...prevData, parentOfLevelThree: grandChildOf };
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getChildrenLevelFour = async (id, grandChildOf) => {
    try {
      const data = await getChildren(id);

      if (data.status === 200) {
        setShowChildren((prevData) => {
          return { ...prevData, showLevelFourChild: true };
        });

        setChildren((prevData) => {
          return { ...prevData, childrenLevelFour: data.data };
        });

        setParent((prevData) => {
          return { ...prevData, parentOfLevelFour: grandChildOf };
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getChildrenLevelFive = async (id, grandChildOf) => {
    try {
      const data = await getChildren(id);

      if (data.status === 200) {
        setShowChildren((prevData) => {
          return { ...prevData, showLevelFiveChild: true };
        });

        setChildren((prevData) => {
          return { ...prevData, childrenLevelFive: data.data };
        });

        setParent((prevData) => {
          return { ...prevData, parentOfLevelFive: grandChildOf };
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getChildrenLevelSix = async (id, grandChildOf) => {
    try {
      const data = await getChildren(id);

      if (data.status === 200) {
        setShowChildren((prevData) => {
          return { ...prevData, showLevelSixChild: true };
        });

        setChildren((prevData) => {
          return { ...prevData, childrenLevelSix: data.data };
        });

        setParent((prevData) => {
          return { ...prevData, parentOfLevelSix: grandChildOf };
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getChildrenLevelSeven = async (id, grandChildOf) => {
    try {
      const data = await getChildren(id);

      if (data.status === 200) {
        setShowChildren((prevData) => {
          return { ...prevData, showLevelSevenChild: true };
        });

        setChildren((prevData) => {
          return { ...prevData, childrenLevelSeven: data.data };
        });

        setParent((prevData) => {
          return { ...prevData, parentOfLevelSeven: grandChildOf };
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getChildrenLevelEight = async (id, grandChildOf) => {
    try {
      const data = await getChildren(id);

      if (data.status === 200) {
        setShowChildren((prevData) => {
          return { ...prevData, showLevelEightChild: true };
        });

        setChildren((prevData) => {
          return { ...prevData, childrenLevelEight: data.data };
        });

        setParent((prevData) => {
          return { ...prevData, parentOfLevelEight: grandChildOf };
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getChildrenLevelNine = async (id, grandChildOf) => {
    try {
      const data = await getChildren(id);

      if (data.status === 200) {
        setShowChildren((prevData) => {
          return { ...prevData, showLevelNineChild: true };
        });

        setChildren((prevData) => {
          return { ...prevData, childrenLevelNine: data.data };
        });

        setParent((prevData) => {
          return { ...prevData, parentOfLevelNine: grandChildOf };
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getChildrenLevelTen = async (id, grandChildOf) => {
    try {
      const data = await getChildren(id);

      if (data.status === 200) {
        setShowChildren((prevData) => {
          return { ...prevData, showLevelTenChild: true };
        });

        setChildren((prevData) => {
          return { ...prevData, childrenLevelTen: data.data };
        });

        setParent((prevData) => {
          return { ...prevData, parentOfLevelTen: grandChildOf };
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getChildrenLevelEleven = async (id, grandChildOf) => {
    try {
      const data = await getChildren(id);

      if (data.status === 200) {
        setShowChildren((prevData) => {
          return { ...prevData, showLevelElevenChild: true };
        });

        setChildren((prevData) => {
          return { ...prevData, childrenLevelEleven: data.data };
        });

        setParent((prevData) => {
          return { ...prevData, parentOfLevelEleven: grandChildOf };
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getChildrenLevelTwelve = async (id, grandChildOf) => {
    try {
      const data = await getChildren(id);

      if (data.status === 200) {
        setShowChildren((prevData) => {
          return { ...prevData, showLevelTwelveChild: true };
        });

        setChildren((prevData) => {
          return { ...prevData, childrenLevelTwelve: data.data };
        });

        setParent((prevData) => {
          return { ...prevData, parentOfLevelTwelve: grandChildOf };
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getChildrenLevelThirteen = async (id, grandChildOf) => {
    try {
      const data = await getChildren(id);

      if (data.status === 200) {
        setShowChildren((prevData) => {
          return { ...prevData, showLevelThirteenChild: true };
        });

        setChildren((prevData) => {
          return { ...prevData, childrenLevelThirteen: data.data };
        });

        setParent((prevData) => {
          return { ...prevData, parentOfLevelThirteen: grandChildOf };
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getChildrenLevelFourteen = async (id, grandChildOf) => {
    try {
      const data = await getChildren(id);

      if (data.status === 200) {
        setShowChildren((prevData) => {
          return { ...prevData, showLevelFourteenChild: true };
        });

        setChildren((prevData) => {
          return { ...prevData, childrenLevelFourteen: data.data };
        });

        setParent((prevData) => {
          return { ...prevData, parentOfLevelFourteen: grandChildOf };
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getChildrenLevelFifteen = async (id, grandChildOf) => {
    try {
      const data = await getChildren(id);

      if (data.status === 200) {
        setShowChildren((prevData) => {
          return { ...prevData, showLevelFifteenChild: true };
        });

        setChildren((prevData) => {
          return { ...prevData, childrenLevelFifteen: data.data };
        });

        setParent((prevData) => {
          return { ...prevData, parentOfLevelFifteen: grandChildOf };
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getChildrenLevelSixteen = async (id, grandChildOf) => {
    try {
      const data = await getChildren(id);

      if (data.status === 200) {
        setShowChildren((prevData) => {
          return { ...prevData, showLevelSixteenChild: true };
        });

        setChildren((prevData) => {
          return { ...prevData, childrenLevelSixteen: data.data };
        });

        setParent((prevData) => {
          return { ...prevData, parentOfLevelSixteen: grandChildOf };
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getChildrenLevelSeventeen = async (id, grandChildOf) => {
    try {
      const data = await getChildren(id);

      if (data.status === 200) {
        setShowChildren((prevData) => {
          return { ...prevData, showLevelSeventeenChild: true };
        });

        setChildren((prevData) => {
          return { ...prevData, childrenLevelSeventeen: data.data };
        });

        setParent((prevData) => {
          return { ...prevData, parentOfLevelSeventeen: grandChildOf };
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getChildrenLevelEighteen = async (id, grandChildOf) => {
    try {
      const data = await getChildren(id);

      if (data.status === 200) {
        setShowChildren((prevData) => {
          return { ...prevData, showLevelEighteenChild: true };
        });

        setChildren((prevData) => {
          return { ...prevData, childrenLevelEighteen: data.data };
        });

        setParent((prevData) => {
          return { ...prevData, parentOfLevelEighteen: grandChildOf };
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getChildrenLevelNineteen = async (id, grandChildOf) => {
    try {
      const data = await getChildren(id);

      if (data.status === 200) {
        setShowChildren((prevData) => {
          return { ...prevData, showLevelNineteenChild: true };
        });

        setChildren((prevData) => {
          return { ...prevData, childrenLevelNineteen: data.data };
        });

        setParent((prevData) => {
          return { ...prevData, parentOfLevelNineteen: grandChildOf };
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getChildrenLevelTwenty = async (id, grandChildOf) => {
    try {
      const data = await getChildren(id);

      if (data.status === 200) {
        setShowChildren((prevData) => {
          return { ...prevData, showLevelTwentyChild: true };
        });

        setChildren((prevData) => {
          return { ...prevData, childrenLevelTwenty: data.data };
        });

        setParent((prevData) => {
          return { ...prevData, parentOfLevelTwenty: grandChildOf };
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getChildrenLevelTwentyOne = async (id, grandChildOf) => {
    try {
      const data = await getChildren(id);

      if (data.status === 200) {
        setShowChildren((prevData) => {
          return { ...prevData, showLevelTwentyOneChild: true };
        });

        setChildren((prevData) => {
          return { ...prevData, childrenLevelTwentyOne: data.data };
        });

        setParent((prevData) => {
          return { ...prevData, parentOfLevelTwentyOne: grandChildOf };
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getChildrenLevelTwentyTwo = async (id, grandChildOf) => {
    try {
      const data = await getChildren(id);

      if (data.status === 200) {
        setShowChildren((prevData) => {
          return { ...prevData, showLevelTwentyTwoChild: true };
        });

        setChildren((prevData) => {
          return { ...prevData, childrenLevelTwentyTwo: data.data };
        });

        setParent((prevData) => {
          return { ...prevData, parentOfLevelTwentyTwo: grandChildOf };
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getChildrenLevelTwentyThree = async (id, grandChildOf) => {
    try {
      const data = await getChildren(id);

      if (data.status === 200) {
        setShowChildren((prevData) => {
          return { ...prevData, showLevelTwentyThreeChild: true };
        });

        setChildren((prevData) => {
          return { ...prevData, childrenLevelTwentyThree: data.data };
        });

        setParent((prevData) => {
          return { ...prevData, parentOfLevelTwentyThree: grandChildOf };
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getChildrenLevelTwentyFour = async (id, grandChildOf) => {
    try {
      const data = await getChildren(id);

      if (data.status === 200) {
        setShowChildren((prevData) => {
          return { ...prevData, showLevelTwentyFourChild: true };
        });

        setChildren((prevData) => {
          return { ...prevData, childrenLevelTwentyFour: data.data };
        });

        setParent((prevData) => {
          return { ...prevData, parentOfLevelTwentyFour: grandChildOf };
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getChildrenLevelTwentyFive = async (id, grandChildOf) => {
    try {
      const data = await getChildren(id);

      if (data.status === 200) {
        setShowChildren((prevData) => {
          return { ...prevData, showLevelTwentyFiveChild: true };
        });

        setChildren((prevData) => {
          return { ...prevData, childrenLevelTwentyFive: data.data };
        });

        setParent((prevData) => {
          return { ...prevData, parentOfLevelTwentyFive: grandChildOf };
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getChildrenLevelOne();
  }, []);

  return (
    <div className="genelogy-tree-container">
      <div className="parent_line">
        <div className="tree_card">
          <ul className="username_icon_container">
            <li className="user_name">
              {curentUser.firstName} {curentUser.lastName ? curentUser.lastName : ""}
              <span style={{ marginTop: "-10px" }}>
                <InfoOutlinedIcon className="info-icon" fontSize="20" color="secondary" />
              </span>
              <ul className="other_details_container">
                <li>Leadership Name : {curentUser.leadershipName} </li>
                <li>Leadership Unit : {curentUser.leadershipRewardPoint}</li>
                <li>Total Income : {curentUser.moneyWithdrawn}</li>
                <li>Team Size : {curentUser.teamSize}</li>
              </ul>
            </li>
          </ul>
          <div className="user_username">{curentUser.userName}</div>
        </div>
      </div>
      {/* sepretor */}

      {/* Level -1 */}
      {showChildren.showLevelOneChild && (
        <>
          <div className="seprator">
            <div className="left_seprate"></div>
            <div className="right_seprate"></div>
          </div>
          <div
            className="children_line"
            style={
              children.childrenLevelOne.length < 5 ? { justifyContent: "center" } : { justifyContent: "flex-start" }
            }
          >
            {children.childrenLevelOne.map((child) => (
              <ul className="tree_card" key={child._id}>
                <li className="user_name">
                  {child.firstName} {child.lastName ? child.lastName : ""}
                  <span style={{ marginTop: "-10px" }}>
                    <InfoOutlinedIcon className="info-icon" fontSize="20" color="secondary" />
                  </span>
                  <ul className="other_details_container">
                    <li>Leadership Name : {child.leadershipName} </li>
                    <li>Leadership Unit : {child.leadershipRewardPoint}</li>
                    <li>Total Income : {child.moneyWithdrawn}</li>
                  </ul>
                </li>
                <div className="user_username">{child.userName}</div>
                <div className="clickable_button" onClick={() => getChildrenLevelTwo(child._id, child.userName)}>
                  {child.directChildren.length}
                </div>
              </ul>
            ))}
          </div>
        </>
      )}

      {/* Level -2 */}

      {/* sepretor */}
      {showChildren.showLevelTwoChild && (
        <>
          <div className="user_line">
            <div className="tree_card">{parent.parentOfLevelTwo}</div>
          </div>
          <div className="seprator">
            <div className="left_seprate"></div>
            <div className="right_seprate"></div>
          </div>

          {children.childrenLevelTwo.length === 0 && (
            <div
              className="children_line"
              style={
                children.childrenLevelTwo.length < 5 ? { justifyContent: "center" } : { justifyContent: "flex-start" }
              }
            >
              No Children Found For this user
            </div>
          )}

          {children.childrenLevelTwo.length > 0 && (
            <div
              className="children_line"
              style={
                children.childrenLevelTwo.length < 5 ? { justifyContent: "center" } : { justifyContent: "flex-start" }
              }
            >
              {children.childrenLevelTwo.map((child) => (
                <ul className="tree_card" key={child._id}>
                  <li className="user_name">
                    {child.firstName} {child.lastName ? child.lastName : ""}
                    <span style={{ marginTop: "-10px" }}>
                      <InfoOutlinedIcon className="info-icon" fontSize="20" color="secondary" />
                    </span>
                    <ul className="other_details_container">
                      <li>Leadership Name : {child.leadershipName} </li>
                      <li>Leadership Unit : {child.leadershipRewardPoint}</li>
                      <li>Total Income : {child.moneyWithdrawn}</li>
                    </ul>
                  </li>
                  <div className="user_username">{child.userName}</div>
                  <div className="clickable_button" onClick={() => getChildrenLevelThree(child._id, child.userName)}>
                    {child.directChildren.length}
                  </div>
                </ul>
              ))}
            </div>
          )}
        </>
      )}

      {/* Level -3 */}

      {/* sepretor */}
      {showChildren.showLevelThreeChild && (
        <>
          <div className="user_line">
            <div className="tree_card">{parent.parentOfLevelThree}</div>
          </div>
          <div className="seprator">
            <div className="left_seprate"></div>
            <div className="right_seprate"></div>
          </div>

          {children.childrenLevelThree.length === 0 && (
            <div
              className="children_line"
              style={
                children.childrenLevelThree.length < 5 ? { justifyContent: "center" } : { justifyContent: "flex-start" }
              }
            >
              No Children Found For this user
            </div>
          )}

          {children.childrenLevelThree.length > 0 && (
            <div
              className="children_line"
              style={
                children.childrenLevelThree.length < 5 ? { justifyContent: "center" } : { justifyContent: "flex-start" }
              }
            >
              {children.childrenLevelThree.map((child) => (
                <ul className="tree_card" key={child._id}>
                  <li className="user_name">
                    {child.firstName} {child.lastName ? child.lastName : ""}
                    <span style={{ marginTop: "-10px" }}>
                      <InfoOutlinedIcon className="info-icon" fontSize="20" color="secondary" />
                    </span>
                    <ul className="other_details_container">
                      <li>Leadership Name : {child.leadershipName} </li>
                      <li>Leadership Unit : {child.leadershipRewardPoint}</li>
                      <li>Total Income : {child.moneyWithdrawn}</li>
                    </ul>
                  </li>
                  <div className="user_username">{child.userName}</div>
                  <div className="clickable_button" onClick={() => getChildrenLevelFour(child._id, child.userName)}>
                    {child.directChildren.length}
                  </div>
                </ul>
              ))}
            </div>
          )}
        </>
      )}

      {/* Level -4 */}

      {/* sepretor */}
      {showChildren.showLevelFourChild && (
        <>
          <div className="user_line">
            <div className="tree_card">{parent.parentOfLevelFour}</div>
          </div>
          <div className="seprator">
            <div className="left_seprate"></div>
            <div className="right_seprate"></div>
          </div>

          {children.childrenLevelFour.length === 0 && (
            <div
              className="children_line"
              style={
                children.childrenLevelFour.length < 5 ? { justifyContent: "center" } : { justifyContent: "flex-start" }
              }
            >
              No Children Found For this user
            </div>
          )}

          {children.childrenLevelFour.length > 0 && (
            <div
              className="children_line"
              style={
                children.childrenLevelFour.length < 5 ? { justifyContent: "center" } : { justifyContent: "flex-start" }
              }
            >
              {children.childrenLevelFour.map((child) => (
                <ul className="tree_card" key={child._id}>
                  <li className="user_name">
                    {child.firstName} {child.lastName ? child.lastName : ""}
                    <span style={{ marginTop: "-10px" }}>
                      <InfoOutlinedIcon className="info-icon" fontSize="20" color="secondary" />
                    </span>
                    <ul className="other_details_container">
                      <li>Leadership Name : {child.leadershipName} </li>
                      <li>Leadership Unit : {child.leadershipRewardPoint}</li>
                      <li>Total Income : {child.moneyWithdrawn}</li>
                    </ul>
                  </li>
                  <div className="user_username">{child.userName}</div>
                  <div className="clickable_button" onClick={() => getChildrenLevelFive(child._id, child.userName)}>
                    {child.directChildren.length}
                  </div>
                </ul>
              ))}
            </div>
          )}
        </>
      )}

      {/* Level -5 */}

      {/* sepretor */}
      {showChildren.showLevelFiveChild && (
        <>
          <div className="user_line">
            <div className="tree_card">{parent.parentOfLevelFive}</div>
          </div>
          <div className="seprator">
            <div className="left_seprate"></div>
            <div className="right_seprate"></div>
          </div>

          {children.childrenLevelFive.length === 0 && (
            <div
              className="children_line"
              style={
                children.childrenLevelFive.length < 5 ? { justifyContent: "center" } : { justifyContent: "flex-start" }
              }
            >
              No Children Found For this user
            </div>
          )}

          {children.childrenLevelFive.length > 0 && (
            <div
              className="children_line"
              style={
                children.childrenLevelFive.length < 5 ? { justifyContent: "center" } : { justifyContent: "flex-start" }
              }
            >
              {children.childrenLevelFive.map((child) => (
                <ul className="tree_card" key={child._id}>
                  <li className="user_name">
                    {child.firstName} {child.lastName ? child.lastName : ""}
                    <span style={{ marginTop: "-10px" }}>
                      <InfoOutlinedIcon className="info-icon" fontSize="20" color="secondary" />
                    </span>
                    <ul className="other_details_container">
                      <li>Leadership Name : {child.leadershipName} </li>
                      <li>Leadership Unit : {child.leadershipRewardPoint}</li>
                      <li>Total Income : {child.moneyWithdrawn}</li>
                    </ul>
                  </li>
                  <div className="user_username">{child.userName}</div>
                  <div className="clickable_button" onClick={() => getChildrenLevelSix(child._id, child.userName)}>
                    {child.directChildren.length}
                  </div>
                </ul>
              ))}
            </div>
          )}
        </>
      )}

      {/* Level -6 */}

      {/* sepretor */}
      {showChildren.showLevelSixChild && (
        <>
          <div className="user_line">
            <div className="tree_card">{parent.parentOfLevelSix}</div>
          </div>
          <div className="seprator">
            <div className="left_seprate"></div>
            <div className="right_seprate"></div>
          </div>

          {children.childrenLevelSix.length === 0 && (
            <div
              className="children_line"
              style={
                children.childrenLevelSix.length < 5 ? { justifyContent: "center" } : { justifyContent: "flex-start" }
              }
            >
              No Children Found For this user
            </div>
          )}

          {children.childrenLevelSix.length > 0 && (
            <div
              className="children_line"
              style={
                children.childrenLevelSix.length < 5 ? { justifyContent: "center" } : { justifyContent: "flex-start" }
              }
            >
              {children.childrenLevelSix.map((child) => (
                <ul className="tree_card" key={child._id}>
                  <li className="user_name">
                    {child.firstName} {child.lastName ? child.lastName : ""}
                    <span style={{ marginTop: "-10px" }}>
                      <InfoOutlinedIcon className="info-icon" fontSize="20" color="secondary" />
                    </span>
                    <ul className="other_details_container">
                      <li>Leadership Name : {child.leadershipName} </li>
                      <li>Leadership Unit : {child.leadershipRewardPoint}</li>
                      <li>Total Income : {child.moneyWithdrawn}</li>
                    </ul>
                  </li>
                  <div className="user_username">{child.userName}</div>
                  <div className="clickable_button" onClick={() => getChildrenLevelSeven(child._id, child.userName)}>
                    {child.directChildren.length}
                  </div>
                </ul>
              ))}
            </div>
          )}
        </>
      )}

      {/* Level -7 */}

      {/* sepretor */}
      {showChildren.showLevelSevenChild && (
        <>
          <div className="user_line">
            <div className="tree_card">{parent.parentOfLevelSeven}</div>
          </div>
          <div className="seprator">
            <div className="left_seprate"></div>
            <div className="right_seprate"></div>
          </div>

          {children.childrenLevelSeven.length === 0 && (
            <div
              className="children_line"
              style={
                children.childrenLevelSeven.length < 5 ? { justifyContent: "center" } : { justifyContent: "flex-start" }
              }
            >
              No Children Found For this user
            </div>
          )}

          {children.childrenLevelSeven.length > 0 && (
            <div
              className="children_line"
              style={
                children.childrenLevelSeven.length < 5 ? { justifyContent: "center" } : { justifyContent: "flex-start" }
              }
            >
              {children.childrenLevelSeven.map((child) => (
                <ul className="tree_card" key={child._id}>
                  <li className="user_name">
                    {child.firstName} {child.lastName ? child.lastName : ""}
                    <span style={{ marginTop: "-10px" }}>
                      <InfoOutlinedIcon className="info-icon" fontSize="20" color="secondary" />
                    </span>
                    <ul className="other_details_container">
                      <li>Leadership Name : {child.leadershipName} </li>
                      <li>Leadership Unit : {child.leadershipRewardPoint}</li>
                      <li>Total Income : {child.moneyWithdrawn}</li>
                    </ul>
                  </li>
                  <div className="user_username">{child.userName}</div>
                  <div className="clickable_button" onClick={() => getChildrenLevelEight(child._id, child.userName)}>
                    {child.directChildren.length}
                  </div>
                </ul>
              ))}
            </div>
          )}
        </>
      )}

      {/* Level -8 */}

      {/* sepretor */}
      {showChildren.showLevelEightChild && (
        <>
          <div className="user_line">
            <div className="tree_card">{parent.parentOfLevelEIght}</div>
          </div>
          <div className="seprator">
            <div className="left_seprate"></div>
            <div className="right_seprate"></div>
          </div>

          {children.childrenLevelEight.length === 0 && (
            <div
              className="children_line"
              style={
                children.childrenLevelEight.length < 5 ? { justifyContent: "center" } : { justifyContent: "flex-start" }
              }
            >
              No Children Found For this user
            </div>
          )}

          {children.childrenLevelEight.length > 0 && (
            <div
              className="children_line"
              style={
                children.childrenLevelEight.length < 5 ? { justifyContent: "center" } : { justifyContent: "flex-start" }
              }
            >
              {children.childrenLevelEight.map((child) => (
                <ul className="tree_card" key={child._id}>
                  <li className="user_name">
                    {child.firstName} {child.lastName ? child.lastName : ""}
                    <span style={{ marginTop: "-10px" }}>
                      <InfoOutlinedIcon className="info-icon" fontSize="20" color="secondary" />
                    </span>
                    <ul className="other_details_container">
                      <li>Leadership Name : {child.leadershipName} </li>
                      <li>Leadership Unit : {child.leadershipRewardPoint}</li>
                      <li>Total Income : {child.moneyWithdrawn}</li>
                    </ul>
                  </li>
                  <div className="user_username">{child.userName}</div>
                  <div className="clickable_button" onClick={() => getChildrenLevelNine(child._id, child.userName)}>
                    {child.directChildren.length}
                  </div>
                </ul>
              ))}
            </div>
          )}
        </>
      )}

      {/* Level -9 */}

      {/* sepretor */}
      {showChildren.showLevelNineChild && (
        <>
          <div className="user_line">
            <div className="tree_card">{parent.parentOfNineTwo}</div>
          </div>
          <div className="seprator">
            <div className="left_seprate"></div>
            <div className="right_seprate"></div>
          </div>

          {children.childrenLevelNine.length === 0 && (
            <div
              className="children_line"
              style={
                children.childrenLevelNine.length < 5 ? { justifyContent: "center" } : { justifyContent: "flex-start" }
              }
            >
              No Children Found For this user
            </div>
          )}

          {children.childrenLevelNine.length > 0 && (
            <div
              className="children_line"
              style={
                children.childrenLevelNine.length < 5 ? { justifyContent: "center" } : { justifyContent: "flex-start" }
              }
            >
              {children.childrenLevelNine.map((child) => (
                <ul className="tree_card" key={child._id}>
                  <li className="user_name">
                    {child.firstName} {child.lastName ? child.lastName : ""}
                    <span style={{ marginTop: "-10px" }}>
                      <InfoOutlinedIcon className="info-icon" fontSize="20" color="secondary" />
                    </span>
                    <ul className="other_details_container">
                      <li>Leadership Name : {child.leadershipName} </li>
                      <li>Leadership Unit : {child.leadershipRewardPoint}</li>
                      <li>Total Income : {child.moneyWithdrawn}</li>
                    </ul>
                  </li>
                  <div className="user_username">{child.userName}</div>
                  <div className="clickable_button" onClick={() => getChildrenLevelTen(child._id, child.userName)}>
                    {child.directChildren.length}
                  </div>
                </ul>
              ))}
            </div>
          )}
        </>
      )}

      {/* Level -10 */}

      {/* sepretor */}
      {showChildren.showLevelTenChild && (
        <>
          <div className="user_line">
            <div className="tree_card">{parent.parentOfLevelTen}</div>
          </div>
          <div className="seprator">
            <div className="left_seprate"></div>
            <div className="right_seprate"></div>
          </div>

          {children.childrenLevelTen.length === 0 && (
            <div
              className="children_line"
              style={
                children.childrenLevelTen.length < 5 ? { justifyContent: "center" } : { justifyContent: "flex-start" }
              }
            >
              No Children Found For this user
            </div>
          )}

          {children.childrenLevelTen.length > 0 && (
            <div
              className="children_line"
              style={
                children.childrenLevelTen.length < 5 ? { justifyContent: "center" } : { justifyContent: "flex-start" }
              }
            >
              {children.childrenLevelTen.map((child) => (
                <ul className="tree_card" key={child._id}>
                  <li className="user_name">
                    {child.firstName} {child.lastName ? child.lastName : ""}
                    <span style={{ marginTop: "-10px" }}>
                      <InfoOutlinedIcon className="info-icon" fontSize="20" color="secondary" />
                    </span>
                    <ul className="other_details_container">
                      <li>Leadership Name : {child.leadershipName} </li>
                      <li>Leadership Unit : {child.leadershipRewardPoint}</li>
                      <li>Total Income : {child.moneyWithdrawn}</li>
                    </ul>
                  </li>
                  <div className="user_username">{child.userName}</div>
                  <div className="clickable_button" onClick={() => getChildrenLevelEleven(child._id, child.userName)}>
                    {child.directChildren.length}
                  </div>
                </ul>
              ))}
            </div>
          )}
        </>
      )}

      {/* --------------------------------------------STARTS HERE----------------------------------------- */}
      {/* Level -11 */}

      {/* sepretor */}
      {showChildren.showLevelElevenChild && (
        <>
          <div className="user_line">
            <div className="tree_card">{parent.parentOfLevelEleven}</div>
          </div>
          <div className="seprator">
            <div className="left_seprate"></div>
            <div className="right_seprate"></div>
          </div>

          {children.childrenLevelEleven.length === 0 && (
            <div
              className="children_line"
              style={
                children.childrenLevelEleven.length < 5
                  ? { justifyContent: "center" }
                  : { justifyContent: "flex-start" }
              }
            >
              No Children Found For this user
            </div>
          )}

          {children.childrenLevelEleven.length > 0 && (
            <div
              className="children_line"
              style={
                children.childrenLevelEleven.length < 5
                  ? { justifyContent: "center" }
                  : { justifyContent: "flex-start" }
              }
            >
              {children.childrenLevelEleven.map((child) => (
                <ul className="tree_card" key={child._id}>
                  <li className="user_name">
                    {child.firstName} {child.lastName ? child.lastName : ""}
                    <span style={{ marginTop: "-10px" }}>
                      <InfoOutlinedIcon className="info-icon" fontSize="20" color="secondary" />
                    </span>
                    <ul className="other_details_container">
                      <li>Leadership Name : {child.leadershipName} </li>
                      <li>Leadership Unit : {child.leadershipRewardPoint}</li>
                      <li>Total Income : {child.moneyWithdrawn}</li>
                    </ul>
                  </li>
                  <div className="user_username">{child.userName}</div>
                  <div className="clickable_button" onClick={() => getChildrenLevelTwelve(child._id, child.userName)}>
                    {child.directChildren.length}
                  </div>
                </ul>
              ))}
            </div>
          )}
        </>
      )}

      {/* Level -12 */}

      {/* sepretor */}
      {showChildren.showLevelTwelveChild && (
        <>
          <div className="user_line">
            <div className="tree_card">{parent.parentOfLevelTwelve}</div>
          </div>
          <div className="seprator">
            <div className="left_seprate"></div>
            <div className="right_seprate"></div>
          </div>

          {children.childrenLevelTwelve.length === 0 && (
            <div
              className="children_line"
              style={
                children.childrenLevelTwelve.length < 5
                  ? { justifyContent: "center" }
                  : { justifyContent: "flex-start" }
              }
            >
              No Children Found For this user
            </div>
          )}

          {children.childrenLevelTwelve.length > 0 && (
            <div
              className="children_line"
              style={
                children.childrenLevelTwelve.length < 5
                  ? { justifyContent: "center" }
                  : { justifyContent: "flex-start" }
              }
            >
              {children.childrenLevelTwelve.map((child) => (
                <ul className="tree_card" key={child._id}>
                  <li className="user_name">
                    {child.firstName} {child.lastName ? child.lastName : ""}
                    <span style={{ marginTop: "-10px" }}>
                      <InfoOutlinedIcon className="info-icon" fontSize="20" color="secondary" />
                    </span>
                    <ul className="other_details_container">
                      <li>Leadership Name : {child.leadershipName} </li>
                      <li>Leadership Unit : {child.leadershipRewardPoint}</li>
                      <li>Total Income : {child.moneyWithdrawn}</li>
                    </ul>
                  </li>
                  <div className="user_username">{child.userName}</div>
                  <div className="clickable_button" onClick={() => getChildrenLevelThirteen(child._id, child.userName)}>
                    {child.directChildren.length}
                  </div>
                </ul>
              ))}
            </div>
          )}
        </>
      )}

      {/* Level -13 */}

      {/* sepretor */}
      {showChildren.showLevelThirteenChild && (
        <>
          <div className="user_line">
            <div className="tree_card">{parent.parentOfLevelThirteen}</div>
          </div>
          <div className="seprator">
            <div className="left_seprate"></div>
            <div className="right_seprate"></div>
          </div>

          {children.childrenLevelThirteen.length === 0 && (
            <div
              className="children_line"
              style={
                children.childrenLevelThirteen.length < 5
                  ? { justifyContent: "center" }
                  : { justifyContent: "flex-start" }
              }
            >
              No Children Found For this user
            </div>
          )}

          {children.childrenLevelThirteen.length > 0 && (
            <div
              className="children_line"
              style={
                children.childrenLevelThirteen.length < 5
                  ? { justifyContent: "center" }
                  : { justifyContent: "flex-start" }
              }
            >
              {children.childrenLevelThirteen.map((child) => (
                <ul className="tree_card" key={child._id}>
                  <li className="user_name">
                    {child.firstName} {child.lastName ? child.lastName : ""}
                    <span style={{ marginTop: "-10px" }}>
                      <InfoOutlinedIcon className="info-icon" fontSize="20" color="secondary" />
                    </span>
                    <ul className="other_details_container">
                      <li>Leadership Name : {child.leadershipName} </li>
                      <li>Leadership Unit : {child.leadershipRewardPoint}</li>
                      <li>Total Income : {child.moneyWithdrawn}</li>
                    </ul>
                  </li>
                  <div className="user_username">{child.userName}</div>
                  <div className="clickable_button" onClick={() => getChildrenLevelFourteen(child._id, child.userName)}>
                    {child.directChildren.length}
                  </div>
                </ul>
              ))}
            </div>
          )}
        </>
      )}

      {/* Level -14 */}

      {/* sepretor */}
      {showChildren.showLevelFourteenChild && (
        <>
          <div className="user_line">
            <div className="tree_card">{parent.parentOfLevelFourteen}</div>
          </div>
          <div className="seprator">
            <div className="left_seprate"></div>
            <div className="right_seprate"></div>
          </div>

          {children.childrenLevelFourteen.length === 0 && (
            <div
              className="children_line"
              style={
                children.childrenLevelFourteen.length < 5
                  ? { justifyContent: "center" }
                  : { justifyContent: "flex-start" }
              }
            >
              No Children Found For this user
            </div>
          )}

          {children.childrenLevelFourteen.length > 0 && (
            <div
              className="children_line"
              style={
                children.childrenLevelFourteen.length < 5
                  ? { justifyContent: "center" }
                  : { justifyContent: "flex-start" }
              }
            >
              {children.childrenLevelFourteen.map((child) => (
                <ul className="tree_card" key={child._id}>
                  <li className="user_name">
                    {child.firstName} {child.lastName ? child.lastName : ""}
                    <span style={{ marginTop: "-10px" }}>
                      <InfoOutlinedIcon className="info-icon" fontSize="20" color="secondary" />
                    </span>
                    <ul className="other_details_container">
                      <li>Leadership Name : {child.leadershipName} </li>
                      <li>Leadership Unit : {child.leadershipRewardPoint}</li>
                      <li>Total Income : {child.moneyWithdrawn}</li>
                    </ul>
                  </li>
                  <div className="user_username">{child.userName}</div>
                  <div className="clickable_button" onClick={() => getChildrenLevelFifteen(child._id, child.userName)}>
                    {child.directChildren.length}
                  </div>
                </ul>
              ))}
            </div>
          )}
        </>
      )}

      {/* Level -15 */}

      {/* sepretor */}
      {showChildren.showLevelFifteenChild && (
        <>
          <div className="user_line">
            <div className="tree_card">{parent.parentOfLevelFifteen}</div>
          </div>
          <div className="seprator">
            <div className="left_seprate"></div>
            <div className="right_seprate"></div>
          </div>

          {children.childrenLevelFifteen.length === 0 && (
            <div
              className="children_line"
              style={
                children.childrenLevelFifteen.length < 5
                  ? { justifyContent: "center" }
                  : { justifyContent: "flex-start" }
              }
            >
              No Children Found For this user
            </div>
          )}

          {children.childrenLevelFifteen.length > 0 && (
            <div
              className="children_line"
              style={
                children.childrenLevelFifteen.length < 5
                  ? { justifyContent: "center" }
                  : { justifyContent: "flex-start" }
              }
            >
              {children.childrenLevelFifteen.map((child) => (
                <ul className="tree_card" key={child._id}>
                  <li className="user_name">
                    {child.firstName} {child.lastName ? child.lastName : ""}
                    <span style={{ marginTop: "-10px" }}>
                      <InfoOutlinedIcon className="info-icon" fontSize="20" color="secondary" />
                    </span>
                    <ul className="other_details_container">
                      <li>Leadership Name : {child.leadershipName} </li>
                      <li>Leadership Unit : {child.leadershipRewardPoint}</li>
                      <li>Total Income : {child.moneyWithdrawn}</li>
                    </ul>
                  </li>
                  <div className="user_username">{child.userName}</div>
                  <div className="clickable_button" onClick={() => getChildrenLevelSixteen(child._id, child.userName)}>
                    {child.directChildren.length}
                  </div>
                </ul>
              ))}
            </div>
          )}
        </>
      )}

      {/* Level -16 */}

      {/* sepretor */}
      {showChildren.showLevelSixteenChild && (
        <>
          <div className="user_line">
            <div className="tree_card">{parent.parentOfLevelSixteen}</div>
          </div>
          <div className="seprator">
            <div className="left_seprate"></div>
            <div className="right_seprate"></div>
          </div>

          {children.childrenLevelSixteen.length === 0 && (
            <div
              className="children_line"
              style={
                children.childrenLevelSixteen.length < 5
                  ? { justifyContent: "center" }
                  : { justifyContent: "flex-start" }
              }
            >
              No Children Found For this user
            </div>
          )}

          {children.childrenLevelSixteen.length > 0 && (
            <div
              className="children_line"
              style={
                children.childrenLevelSixteen.length < 5
                  ? { justifyContent: "center" }
                  : { justifyContent: "flex-start" }
              }
            >
              {children.childrenLevelSixteen.map((child) => (
                <ul className="tree_card" key={child._id}>
                  <li className="user_name">
                    {child.firstName} {child.lastName ? child.lastName : ""}
                    <span style={{ marginTop: "-10px" }}>
                      <InfoOutlinedIcon className="info-icon" fontSize="20" color="secondary" />
                    </span>
                    <ul className="other_details_container">
                      <li>Leadership Name : {child.leadershipName} </li>
                      <li>Leadership Unit : {child.leadershipRewardPoint}</li>
                      <li>Total Income : {child.moneyWithdrawn}</li>
                    </ul>
                  </li>
                  <div className="user_username">{child.userName}</div>
                  <div
                    className="clickable_button"
                    onClick={() => getChildrenLevelSeventeen(child._id, child.userName)}
                  >
                    {child.directChildren.length}
                  </div>
                </ul>
              ))}
            </div>
          )}
        </>
      )}

      {/* Level -17 */}

      {/* sepretor */}
      {showChildren.showLevelSeventeenChild && (
        <>
          <div className="user_line">
            <div className="tree_card">{parent.parentOfLevelSeventeen}</div>
          </div>
          <div className="seprator">
            <div className="left_seprate"></div>
            <div className="right_seprate"></div>
          </div>

          {children.childrenLevelSeventeen.length === 0 && (
            <div
              className="children_line"
              style={
                children.childrenLevelSeventeen.length < 5
                  ? { justifyContent: "center" }
                  : { justifyContent: "flex-start" }
              }
            >
              No Children Found For this user
            </div>
          )}

          {children.childrenLevelSeventeen.length > 0 && (
            <div
              className="children_line"
              style={
                children.childrenLevelSeventeen.length < 5
                  ? { justifyContent: "center" }
                  : { justifyContent: "flex-start" }
              }
            >
              {children.childrenLevelSeventeen.map((child) => (
                <ul className="tree_card" key={child._id}>
                  <li className="user_name">
                    {child.firstName} {child.lastName ? child.lastName : ""}
                    <span style={{ marginTop: "-10px" }}>
                      <InfoOutlinedIcon className="info-icon" fontSize="20" color="secondary" />
                    </span>
                    <ul className="other_details_container">
                      <li>Leadership Name : {child.leadershipName} </li>
                      <li>Leadership Unit : {child.leadershipRewardPoint}</li>
                      <li>Total Income : {child.moneyWithdrawn}</li>
                    </ul>
                  </li>
                  <div className="user_username">{child.userName}</div>
                  <div className="clickable_button" onClick={() => getChildrenLevelEighteen(child._id, child.userName)}>
                    {child.directChildren.length}
                  </div>
                </ul>
              ))}
            </div>
          )}
        </>
      )}

      {/* Level -18 */}

      {/* sepretor */}
      {showChildren.showLevelEighteenChild && (
        <>
          <div className="user_line">
            <div className="tree_card">{parent.parentOfLevelEighteen}</div>
          </div>
          <div className="seprator">
            <div className="left_seprate"></div>
            <div className="right_seprate"></div>
          </div>

          {children.childrenLevelEighteen.length === 0 && (
            <div
              className="children_line"
              style={
                children.childrenLevelEighteen.length < 5
                  ? { justifyContent: "center" }
                  : { justifyContent: "flex-start" }
              }
            >
              No Children Found For this user
            </div>
          )}

          {children.childrenLevelEighteen.length > 0 && (
            <div
              className="children_line"
              style={
                children.childrenLevelEighteen.length < 5
                  ? { justifyContent: "center" }
                  : { justifyContent: "flex-start" }
              }
            >
              {children.childrenLevelEighteen.map((child) => (
                <ul className="tree_card" key={child._id}>
                  <li className="user_name">
                    {child.firstName} {child.lastName ? child.lastName : ""}
                    <span style={{ marginTop: "-10px" }}>
                      <InfoOutlinedIcon className="info-icon" fontSize="20" color="secondary" />
                    </span>
                    <ul className="other_details_container">
                      <li>Leadership Name : {child.leadershipName} </li>
                      <li>Leadership Unit : {child.leadershipRewardPoint}</li>
                      <li>Total Income : {child.moneyWithdrawn}</li>
                    </ul>
                  </li>
                  <div className="user_username">{child.userName}</div>
                  <div className="clickable_button" onClick={() => getChildrenLevelNineteen(child._id, child.userName)}>
                    {child.directChildren.length}
                  </div>
                </ul>
              ))}
            </div>
          )}
        </>
      )}

      {/* Level -19 */}

      {/* sepretor */}
      {showChildren.showLevelNineteenChild && (
        <>
          <div className="user_line">
            <div className="tree_card">{parent.parentOfLevelNineteen}</div>
          </div>
          <div className="seprator">
            <div className="left_seprate"></div>
            <div className="right_seprate"></div>
          </div>

          {children.childrenLevelNineteen.length === 0 && (
            <div
              className="children_line"
              style={
                children.childrenLevelNineteen.length < 5
                  ? { justifyContent: "center" }
                  : { justifyContent: "flex-start" }
              }
            >
              No Children Found For this user
            </div>
          )}

          {children.childrenLevelNineteen.length > 0 && (
            <div
              className="children_line"
              style={
                children.childrenLevelNineteen.length < 5
                  ? { justifyContent: "center" }
                  : { justifyContent: "flex-start" }
              }
            >
              {children.childrenLevelNineteen.map((child) => (
                <ul className="tree_card" key={child._id}>
                  <li className="user_name">
                    {child.firstName} {child.lastName ? child.lastName : ""}
                    <span style={{ marginTop: "-10px" }}>
                      <InfoOutlinedIcon className="info-icon" fontSize="20" color="secondary" />
                    </span>
                    <ul className="other_details_container">
                      <li>Leadership Name : {child.leadershipName} </li>
                      <li>Leadership Unit : {child.leadershipRewardPoint}</li>
                      <li>Total Income : {child.moneyWithdrawn}</li>
                    </ul>
                  </li>
                  <div className="user_username">{child.userName}</div>
                  <div className="clickable_button" onClick={() => getChildrenLevelTwenty(child._id, child.userName)}>
                    {child.directChildren.length}
                  </div>
                </ul>
              ))}
            </div>
          )}
        </>
      )}

      {/* Level -20 */}

      {/* sepretor */}
      {showChildren.showLevelTwentyChild && (
        <>
          <div className="user_line">
            <div className="tree_card">{parent.parentOfLevelTwenty}</div>
          </div>
          <div className="seprator">
            <div className="left_seprate"></div>
            <div className="right_seprate"></div>
          </div>

          {children.childrenLevelTwenty.length === 0 && (
            <div
              className="children_line"
              style={
                children.childrenLevelTwenty.length < 5
                  ? { justifyContent: "center" }
                  : { justifyContent: "flex-start" }
              }
            >
              No Children Found For this user
            </div>
          )}

          {children.childrenLevelTwenty.length > 0 && (
            <div
              className="children_line"
              style={
                children.childrenLevelTwenty.length < 5
                  ? { justifyContent: "center" }
                  : { justifyContent: "flex-start" }
              }
            >
              {children.childrenLevelTwenty.map((child) => (
                <ul className="tree_card" key={child._id}>
                  <li className="user_name">
                    {child.firstName} {child.lastName ? child.lastName : ""}
                    <span style={{ marginTop: "-10px" }}>
                      <InfoOutlinedIcon className="info-icon" fontSize="20" color="secondary" />
                    </span>
                    <ul className="other_details_container">
                      <li>Leadership Name : {child.leadershipName} </li>
                      <li>Leadership Unit : {child.leadershipRewardPoint}</li>
                      <li>Total Income : {child.moneyWithdrawn}</li>
                    </ul>
                  </li>
                  <div className="user_username">{child.userName}</div>
                  <div
                    className="clickable_button"
                    onClick={() => getChildrenLevelTwentyOne(child._id, child.userName)}
                  >
                    {child.directChildren.length}
                  </div>
                </ul>
              ))}
            </div>
          )}
        </>
      )}

      {/* Level -21 */}

      {/* sepretor */}
      {showChildren.showLevelTwentyOneChild && (
        <>
          <div className="user_line">
            <div className="tree_card">{parent.parentOfLevelTwentyOne}</div>
          </div>
          <div className="seprator">
            <div className="left_seprate"></div>
            <div className="right_seprate"></div>
          </div>

          {children.childrenLevelTwentyOne.length === 0 && (
            <div
              className="children_line"
              style={
                children.childrenLevelTwentyOne.length < 5
                  ? { justifyContent: "center" }
                  : { justifyContent: "flex-start" }
              }
            >
              No Children Found For this user
            </div>
          )}

          {children.childrenLevelTwentyOne.length > 0 && (
            <div
              className="children_line"
              style={
                children.childrenLevelTwentyOne.length < 5
                  ? { justifyContent: "center" }
                  : { justifyContent: "flex-start" }
              }
            >
              {children.childrenLevelTwentyOne.map((child) => (
                <ul className="tree_card" key={child._id}>
                  <li className="user_name">
                    {child.firstName} {child.lastName ? child.lastName : ""}
                    <span style={{ marginTop: "-10px" }}>
                      <InfoOutlinedIcon className="info-icon" fontSize="20" color="secondary" />
                    </span>
                    <ul className="other_details_container">
                      <li>Leadership Name : {child.leadershipName} </li>
                      <li>Leadership Unit : {child.leadershipRewardPoint}</li>
                      <li>Total Income : {child.moneyWithdrawn}</li>
                    </ul>
                  </li>
                  <div className="user_username">{child.userName}</div>
                  <div
                    className="clickable_button"
                    onClick={() => getChildrenLevelTwentyTwo(child._id, child.userName)}
                  >
                    {child.directChildren.length}
                  </div>
                </ul>
              ))}
            </div>
          )}
        </>
      )}

      {/* Level -22 */}

      {/* sepretor */}
      {showChildren.showLevelTwentyTwoChild && (
        <>
          <div className="user_line">
            <div className="tree_card">{parent.parentOfLevelTwentyTwo}</div>
          </div>
          <div className="seprator">
            <div className="left_seprate"></div>
            <div className="right_seprate"></div>
          </div>

          {children.getChildrenLevelTwentyTwo.length === 0 && (
            <div
              className="children_line"
              style={
                children.getChildrenLevelTwentyTwo.length < 5
                  ? { justifyContent: "center" }
                  : { justifyContent: "flex-start" }
              }
            >
              No Children Found For this user
            </div>
          )}

          {children.getChildrenLevelTwentyTwo.length > 0 && (
            <div
              className="children_line"
              style={
                children.getChildrenLevelTwentyTwo.length < 5
                  ? { justifyContent: "center" }
                  : { justifyContent: "flex-start" }
              }
            >
              {children.getChildrenLevelTwentyTwo.map((child) => (
                <ul className="tree_card" key={child._id}>
                  <li className="user_name">
                    {child.firstName} {child.lastName ? child.lastName : ""}
                    <span style={{ marginTop: "-10px" }}>
                      <InfoOutlinedIcon className="info-icon" fontSize="20" color="secondary" />
                    </span>
                    <ul className="other_details_container">
                      <li>Leadership Name : {child.leadershipName} </li>
                      <li>Leadership Unit : {child.leadershipRewardPoint}</li>
                      <li>Total Income : {child.moneyWithdrawn}</li>
                    </ul>
                  </li>
                  <div className="user_username">{child.userName}</div>
                  <div
                    className="clickable_button"
                    onClick={() => getChildrenLevelTwentyThree(child._id, child.userName)}
                  >
                    {child.directChildren.length}
                  </div>
                </ul>
              ))}
            </div>
          )}
        </>
      )}

      {/* Level -23 */}

      {/* sepretor */}
      {showChildren.showLevelTwentyThreeChild && (
        <>
          <div className="user_line">
            <div className="tree_card">{parent.parentOfLevelTwentyThree}</div>
          </div>
          <div className="seprator">
            <div className="left_seprate"></div>
            <div className="right_seprate"></div>
          </div>

          {children.childrenLevelTwentyThree.length === 0 && (
            <div
              className="children_line"
              style={
                children.childrenLevelTwentyThree.length < 5
                  ? { justifyContent: "center" }
                  : { justifyContent: "flex-start" }
              }
            >
              No Children Found For this user
            </div>
          )}

          {children.childrenLevelTwentyThree.length > 0 && (
            <div
              className="children_line"
              style={
                children.childrenLevelTwentyThree.length < 5
                  ? { justifyContent: "center" }
                  : { justifyContent: "flex-start" }
              }
            >
              {children.childrenLevelTwentyThree.map((child) => (
                <ul className="tree_card" key={child._id}>
                  <li className="user_name">
                    {child.firstName} {child.lastName ? child.lastName : ""}
                    <span style={{ marginTop: "-10px" }}>
                      <InfoOutlinedIcon className="info-icon" fontSize="20" color="secondary" />
                    </span>
                    <ul className="other_details_container">
                      <li>Leadership Name : {child.leadershipName} </li>
                      <li>Leadership Unit : {child.leadershipRewardPoint}</li>
                      <li>Total Income : {child.moneyWithdrawn}</li>
                    </ul>
                  </li>
                  <div className="user_username">{child.userName}</div>
                  <div
                    className="clickable_button"
                    onClick={() => getChildrenLevelTwentyFour(child._id, child.userName)}
                  >
                    {child.directChildren.length}
                  </div>
                </ul>
              ))}
            </div>
          )}
        </>
      )}

      {/* Level -24 */}

      {/* sepretor */}
      {showChildren.showLevelTwentyFourChild && (
        <>
          <div className="user_line">
            <div className="tree_card">{parent.parentOfLevelTwentyFour}</div>
          </div>
          <div className="seprator">
            <div className="left_seprate"></div>
            <div className="right_seprate"></div>
          </div>

          {children.childrenLevelTwentyFour.length === 0 && (
            <div
              className="children_line"
              style={
                children.childrenLevelTwentyFour.length < 5
                  ? { justifyContent: "center" }
                  : { justifyContent: "flex-start" }
              }
            >
              No Children Found For this user
            </div>
          )}

          {children.childrenLevelTwentyFour.length > 0 && (
            <div
              className="children_line"
              style={
                children.childrenLevelTwentyFour.length < 5
                  ? { justifyContent: "center" }
                  : { justifyContent: "flex-start" }
              }
            >
              {children.childrenLevelTwentyFour.map((child) => (
                <ul className="tree_card" key={child._id}>
                  <li className="user_name">
                    {child.firstName} {child.lastName ? child.lastName : ""}
                    <span style={{ marginTop: "-10px" }}>
                      <InfoOutlinedIcon className="info-icon" fontSize="20" color="secondary" />
                    </span>
                    <ul className="other_details_container">
                      <li>Leadership Name : {child.leadershipName} </li>
                      <li>Leadership Unit : {child.leadershipRewardPoint}</li>
                      <li>Total Income : {child.moneyWithdrawn}</li>
                    </ul>
                  </li>
                  <div className="user_username">{child.userName}</div>
                  <div
                    className="clickable_button"
                    onClick={() => getChildrenLevelTwentyFive(child._id, child.userName)}
                  >
                    {child.directChildren.length}
                  </div>
                </ul>
              ))}
            </div>
          )}
        </>
      )}

      {/* Level -25 */}

      {/* sepretor */}
      {showChildren.showLevelTwentyFiveChild && (
        <>
          <div className="user_line">
            <div className="tree_card">{parent.parentOfLevelTwentyFive}</div>
          </div>
          <div className="seprator">
            <div className="left_seprate"></div>
            <div className="right_seprate"></div>
          </div>

          {children.childrenLevelTwentyFive.length === 0 && (
            <div
              className="children_line"
              style={
                children.childrenLevelTwentyFive.length < 5
                  ? { justifyContent: "center" }
                  : { justifyContent: "flex-start" }
              }
            >
              No Children Found For this user
            </div>
          )}

          {children.childrenLevelTwentyFive.length > 0 && (
            <div
              className="children_line"
              style={
                children.childrenLevelTwentyFive.length < 5
                  ? { justifyContent: "center" }
                  : { justifyContent: "flex-start" }
              }
            >
              {children.childrenLevelTwentyFive.map((child) => (
                <ul className="tree_card" key={child._id}>
                  <li className="user_name">
                    {child.firstName} {child.lastName ? child.lastName : ""}
                    <span style={{ marginTop: "-10px" }}>
                      <InfoOutlinedIcon className="info-icon" fontSize="20" color="secondary" />
                    </span>
                    <ul className="other_details_container">
                      <li>Leadership Name : {child.leadershipName} </li>
                      <li>Leadership Unit : {child.leadershipRewardPoint}</li>
                      <li>Total Income : {child.moneyWithdrawn}</li>
                    </ul>
                  </li>
                  <div className="user_username">{child.userName}</div>
                  <div className="clickable_button">{child.directChildren.length}</div>
                </ul>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default GenelogyTree;
