import React, { useEffect, useState, useCallback } from "react";
// import AuthContext from "../../context/authContext";
import Navigation from "../../components/navigation/Navigation";
import Sidebar from "../../components/Sidebar/Sidebar";
import BasicTable from "../../components/Tables/BasicTable";
import axios from "axios";

const AllUser = () => {
  //   const AuthCntx = useContext(AuthContext);

  const [allUsers, setAllUsers] = useState([]);

  const getUserHandler = useCallback(async () => {
    const response = await axios.get("https://api.ykfuture.in/api/v1/user/allusers");
    setAllUsers(response.data.users);
  }, []);

  useEffect(() => {
    getUserHandler();
  }, [getUserHandler]);

  return (
    <div id="homepage" className="page">
      <Sidebar />
      <div className="right-container">
        <Navigation />
        <div className="rest">
          <BasicTable width="100%" type="allusers" heading="All Users" data={allUsers} />
        </div>
      </div>
    </div>
  );
};

export default AllUser;
