import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import AuthContext from "../../context/authContext";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Divider from "@mui/material/Divider";

import { RxCross1 } from "react-icons/rx";
const LoginForm = (props) => {
  const history = useNavigate();
  const [open, setOpen] = useState(false);
  const [openCreateUserDialog, setOpenCreateUserDialog] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userName, setUserName] = useState("");

  // signup
  const [sponsor, setSponsor] = useState("");
  const [newUserFirstName, setNewUserFirstName] = useState("");
  const [newUserLastName, setNewUserLastName] = useState("");
  const [newUserUserName, setNewUserUserName] = useState("");
  const [newUserEmail, setNewUserEmail] = useState("");
  const [newUserMobile, setNewUserMobile] = useState("");
  const [newUserAddress, setNewUserAddress] = useState("");
  const [newUserPassword, setNewUserPassword] = useState("");
  const [newUserConfirmPassword, setNewUserConfirmPassword] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");

  const authCntxt = useContext(AuthContext);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenCreateUserDialog(false);
  };

  const loginHandler = async (e) => {
    e.preventDefault();
    try {
      const res = await axios({
        method: "POST",
        url: "https://api.ykfuture.in/api/v1/auth/login",
        data: {
          email,
          password,
        },
      });
      if (res.status === 200) {
        authCntxt.login(res.data.token, res.data.user.role, res.data.user._id, res.data.user.userName, res.data.user);
      }
      history("/");
    } catch (err) {
      toast.error(err.response.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const forgetPasswordHandler = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post("https://api.ykfuture.in/api/v1/auth/forgotpassword", {
        userName: userName,
      });

      setOpen(false);

      if (res.status === 200) {
        toast.success("Password rest link sent to your email", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const openCreateUserDialogHandler = () => {
    setOpenCreateUserDialog(true);
  };

  const createUserHandler = async (e) => {
    e.preventDefault();

    try {
      const data = {
        firstName: newUserFirstName,
        lastName: newUserLastName,
        userName: newUserUserName,
        email: newUserEmail,
        mobile: newUserMobile,
        address: newUserAddress,
        password: newUserPassword,
        confPass: newUserConfirmPassword,
        sponsor,
        dateOfBirth,
      };
      const response = await axios.post("https://api.ykfuture.in/api/v1/auth/create-self-user", data);

      if (response.status === 201) {
        toast.success("User Created Sucessfully !!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        setNewUserFirstName("");
        setNewUserLastName("");
        setNewUserUserName("");
        setNewUserEmail("");
        setNewUserMobile("");
        setNewUserAddress("");
        setNewUserPassword("");
        setNewUserConfirmPassword("");
        setDateOfBirth("");
        setSponsor("");
        setOpenCreateUserDialog(false);
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  return (
    <>
      <form action="" className="addUser" onSubmit={loginHandler}>
        <div className="form-part">
          <span className="part-heading">{props.heading}</span>
          <div className="input-group">
            <label htmlFor="username">
              Username <span>*</span>
            </label>
            <input
              type="text"
              placeholder="Enter Your Username"
              name="username"
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="password">
              Password <span>*</span>
            </label>
            <input
              type="password"
              placeholder="Enter Your password"
              name="password"
              required
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div className="flex-input-group">
            <Link style={{ color: "#1976d2", fontWeight: "100" }} onClick={openCreateUserDialogHandler}>
              Register Now
            </Link>
            <Link style={{ color: "#f35e63", fontWeight: "100" }} onClick={handleClickOpen}>
              Forgot Password ?
            </Link>
          </div>

          <div className="input-group">
            <Button type="submit" variant="outlined" color="secondary">
              Login
            </Button>
          </div>
        </div>
      </form>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Forgot Password ? Don't Worry</DialogTitle>

        <DialogContent>
          <form action="" onSubmit={forgetPasswordHandler}>
            <div className="dialog-form-part">
              <div className="input-group">
                <label htmlFor="name">
                  Username <span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Username to find your account"
                  name="name"
                  onChange={(e) => {
                    setUserName(e.target.value);
                  }}
                  required
                />
              </div>
              <div className="input-group">
                <Button onClick={handleClose} color="error" style={{ marginRight: "10px" }}>
                  Cancel
                </Button>
                <Button type="submit" color="secondary">
                  Submit
                </Button>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>

      {/* Create User Dialog */}
      <Dialog open={openCreateUserDialog} onClose={handleClose}>
        <DialogTitle
          className="Registration_title"
          sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
        >
          <span>Not Registered? Signup Here</span>
          <span onClick={handleClose}>
            <RxCross1 />
          </span>
        </DialogTitle>
        <Divider sx={{ margin: "0.3em 0" }} />

        <DialogContent>
          <form action="" onSubmit={createUserHandler}>
            <div className="dialog-form-part">
              <div className="inputContainer">
                <div className="input-group">
                  <label htmlFor="user-name">
                    User Name <span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Username"
                    name="user-name"
                    value={newUserUserName}
                    onChange={(e) => setNewUserUserName(e.target.value)}
                    required
                  />
                </div>
                {/* Sponser Id Added Aks -> Update the backend accordingly */}
                <div className="input-group">
                  <label htmlFor="Sponser-Id">
                    Sponser Id <span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Sponser Id"
                    name="sponser-id"
                    value={sponsor}
                    onChange={(e) => setSponsor(e.target.value)}
                    required
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="first-name">
                    First Name <span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Your First Name"
                    name="first-name"
                    value={newUserFirstName}
                    onChange={(e) => setNewUserFirstName(e.target.value)}
                    required
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="name">
                    Last Name <span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Your Last Name"
                    name="last-name"
                    value={newUserLastName}
                    onChange={(e) => setNewUserLastName(e.target.value)}
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="user-email">
                    Email <span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Email"
                    name="user-email"
                    value={newUserEmail}
                    onChange={(e) => setNewUserEmail(e.target.value)}
                    required
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="user-phone">
                    Phone No. <span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Phone Number"
                    name="user-phone"
                    value={newUserMobile}
                    onChange={(e) => setNewUserMobile(e.target.value)}
                    required
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="user-add">
                    Address <span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Phone Number"
                    name="user-add"
                    value={newUserAddress}
                    onChange={(e) => setNewUserAddress(e.target.value)}
                    required
                  />
                </div>

                <div className="input-group">
                  <label htmlFor="address">
                    Date Of Birth <span>*</span>
                  </label>
                  <input
                    type="date"
                    placeholder="Enter Address"
                    name="address"
                    min="1990-01-01"
                    max="2000-12-31"
                    value={dateOfBirth}
                    onChange={(e) => setDateOfBirth(e.target.value)}
                  />
                </div>

                <div className="input-group">
                  <label htmlFor="user-pass">
                    Password <span>*</span>
                  </label>
                  <input
                    type="password"
                    placeholder="Confirm Your Password"
                    name="user-pass"
                    value={newUserPassword}
                    onChange={(e) => setNewUserPassword(e.target.value)}
                    required
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="user-conf-pass">
                    Confirm Password <span>*</span>
                  </label>
                  <input
                    type="password"
                    placeholder="Confirm Your Password"
                    name="user-conf-pass"
                    value={newUserConfirmPassword}
                    onChange={(e) => setNewUserConfirmPassword(e.target.value)}
                    required
                  />
                </div>
              </div>

              <div className="input-group submitBtn_">
                <Button onClick={handleClose} color="error" style={{ marginRight: "10px" }}>
                  Cancel
                </Button>
                <Button type="submit" color="secondary">
                  Submit
                </Button>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      <ToastContainer />
    </>
  );
};

export default LoginForm;
