import React, { useContext, useState, useEffect } from "react";
import BasicTable from "../Tables/BasicTable";
import axios from "axios";
import AuthContext from "../../context/authContext";
import "./walletComponent.css";

const PVComponent = () => {
  const AuthCntx = useContext(AuthContext);
  const [luTransaction, setLuTransactions] = useState([]);

  const getPvTransactions = async () => {
    try {
      const res = await axios.get(`https://api.ykfuture.in/api/v1/pv/get-transaction/${AuthCntx.user_id}`);
      setLuTransactions(res.data.transactions);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getPvTransactions();
  }, []);

  return (
    <div className="epinrequestcontainer">
      <div className="wallet-container-pv">
        <div className="wallet-balance">
          <span className="balance-heading">Leadership Points : </span>
        </div>
      </div>
      <div className="transaction-table-flex">
        <BasicTable width="100%" type="luTransactionTable" data={luTransaction} />
      </div>
    </div>
  );
};

export default PVComponent;
