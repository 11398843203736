import React from "react";
import Navigation from "../../components/navigation/Navigation";
import Sidebar from "../../components/Sidebar/Sidebar";
import NewSubscriptionForm from "../../components/Forms/NewSubscriptionForm";

const BuyNewSubscription = () => {
  return (
    <div id="buy_new_subscription" className="page">
      <Sidebar />
      <div className="right-container">
        <Navigation />
        <div className="rest">
          <NewSubscriptionForm />
        </div>
      </div>
    </div>
  );
};

export default BuyNewSubscription;
