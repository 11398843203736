import React, { useEffect, useState, useCallback } from "react";
import "./resetPassword.css";
import siteLogo from "../../mobile-logo.png";
import Button from "@mui/material/Button";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const ResetPassword = () => {
  const history = useNavigate();

  const { token } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordNotSame, setPasswordNotSame] = useState(false);

  const [passwordUpdated, setPasswordUpdated] = useState(false);
  const [errorMessage, setErrorMessage] = useState("This is a demo error text");
  const [error, setError] = useState(false);

  const checkPasswordEquality = useCallback(() => {
    if (confirmPassword.length > 0) {
      if (password === confirmPassword) {
        setPasswordNotSame(false);
      } else {
        setPasswordNotSame(true);
      }
    } else {
      setPasswordNotSame(false);
    }
  }, [confirmPassword]);

  const confirmPasswordHandler = (e) => {
    setConfirmPassword(e.target.value);
    checkPasswordEquality();
  };

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.patch(`https://api.ykfuture.in/api/v1/auth/resetpassword/${token}`, {
        password: password,
        confirmPassword: confirmPassword,
      });

      if (res.status === 200) {
        setPasswordUpdated(true);

        setTimeout(() => {
          history("/");
        }, 2500);
      }
    } catch (err) {
      setError(true);
      setErrorMessage(err.message);
    }
  };

  useEffect(() => {
    checkPasswordEquality();
  }, [confirmPassword, checkPasswordEquality]);

  return (
    <div className="_wrapper">
      <div className="img_container">
        <img src={siteLogo} alt="" />
      </div>
      <div className="form_container">
        {passwordUpdated && (
          <p style={{ color: "green", textAlign: "center", marginBottom: "10px" }}>
            Your Password Updated Successfully !!
          </p>
        )}
        {error && <p style={{ color: "red", textAlign: "center", marginBottom: "10px" }}>{errorMessage}</p>}
        <form onSubmit={formSubmitHandler}>
          <div className="form_style">
            <span className="part-heading">Reset Your Password</span>
            <div className="input-group">
              <label className="label" htmlFor="pass">
                Password
              </label>
              <input
                type="password"
                placeholder="Enter New Password"
                name="pass"
                id="pass"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

              {password && password.length < 8 && (
                <p style={{ color: "red" }}> The Length of the password Should be 8 characters</p>
              )}
            </div>

            <div className="input-group">
              <label className="label" htmlFor="pass-change">
                Confirm Password
              </label>

              {password && password.length >= 8 && (
                <input
                  type="password"
                  placeholder="Confirm Your Password"
                  name="pass-change"
                  id="pass-change"
                  value={confirmPassword}
                  onChange={(e) => {
                    confirmPasswordHandler(e);
                  }}
                />
              )}

              {!password && <input type="password" placeholder="Please Fill the Password First" disabled />}

              {password && password.length < 8 && (
                <input type="password" placeholder="The Length of the password should be 8 characters" disabled />
              )}
              {passwordNotSame && <p style={{ color: "red" }}> Passwords Do Not Match</p>}
            </div>

            <div className="input-group">
              <Button type="submit" variant="outlined" color="secondary">
                Submit
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
