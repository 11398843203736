import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../../components/Sidebar/Sidebar";
import Navigation from "../../components/navigation/Navigation";
import Footer from "../../components/Footer/Footer";
import { Button } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../EpinRequests/epinRequestDetails.css";

const RequestDetailsPage = () => {
  const [ePinRequestsDetails, setEPinRequestsDetails] = useState([]);
  const params = useParams();
  const history = useNavigate();

  const fetchEpinRequestDetails = async () => {
    try {
      const response = await fetch(`https://api.ykfuture.in/api/v1/redeem-request/${params.id}`);

      const data = await response.json();
      setEPinRequestsDetails(data.request);
    } catch (err) {
      console.log(err);
    }
  };

  const acceptTheRequest = async () => {
    try {
      const res = await axios({
        method: "PATCH",
        url: `https://api.ykfuture.in/api/v1/redeem-request/${params.id}`,
        data: {
          isAccepted: true,
          isNewRequest: false,
          status: "accepted",
        },
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (res.status === "200") {
        toast.success("Request Accepted !! ", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        setTimeout(() => {
          history("/allepinrequests");
        }, 2300);
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const rejectTheRequest = async () => {
    try {
      await axios({
        method: "PATCH",
        url: `https://api.ykfuture.in/api/v1/redeem-request/${params.id}`,
        data: {
          isCancelled: true,
          isNewRequest: false,
          status: "rejected",
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (err) {
      toast.error(err.response.data.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  useEffect(() => {
    fetchEpinRequestDetails();
  }, []);

  return (
    <div id="boardpage" className="page">
      <Sidebar />
      <div className="right-container">
        <Navigation />
        <div className="rest">
          <div className="epin-request-container">
            <div className="epin-right-container">
              <table>
                <tbody>
                  <tr>
                    <th>Company</th>
                    <th>Contact</th>
                  </tr>
                  <tr>
                    <td>Requested By</td>
                    <td>{ePinRequestsDetails.userName}</td>
                  </tr>
                  <tr>
                    <td>Amount (for each)</td>
                    <td>{ePinRequestsDetails.requestedAmount}</td>
                  </tr>
                </tbody>
              </table>
              {!ePinRequestsDetails.isCancelled && !ePinRequestsDetails.isAccepted && (
                <div className="epin-button-container">
                  <Button color="success" variant="outlined" onClick={acceptTheRequest}>
                    Accept
                  </Button>

                  <Button color="error" variant="outlined" onClick={rejectTheRequest}>
                    Reject
                  </Button>
                </div>
              )}
            </div>
          </div>
          <ToastContainer />
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default RequestDetailsPage;
