import React, { useState, useEffect } from "react";
import BasicTable from "../Tables/BasicTable";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const EPinRequests = () => {
  const history = useNavigate();
  const [ePinNum, setEpinNum] = useState(0);
  const [epinAmount, setEPinAmount] = useState(0);

  const [ePinRequests, setEPinRequests] = useState([]);
  const [editModal, setEditModal] = useState(false);

  const [title, setTitle] = useState("");

  const amountHandler = (e) => {
    setEPinAmount(e.target.value);
  };

  const ePinsNumberHandler = (e) => {
    setEpinNum(e.target.value);
  };

  const fetchAllEpinRequests = async () => {
    try {
      const response = await fetch("https://api.ykfuture.in/api/v1/epinrequests/getallrequest");

      const data = await response.json();
      setEPinRequests(data.AllePinRequest);
      setTitle("All Epin Requests");
    } catch (err) {
      console.log(err);
    }
  };

  const fetchAcceptedRequests = async () => {
    try {
      const response = await fetch("https://api.ykfuture.in/api/v1/epinrequests/getallrequest?isAccepted=true");

      const data = await response.json();
      setEPinRequests(data.AllePinRequest);
      setTitle("Accepted Requests");
    } catch (err) {
      console.log(err);
    }
  };

  const fetchRejecteddRequests = async () => {
    try {
      const response = await fetch("https://api.ykfuture.in/api/v1/epinrequests/getallrequest?isCancelled=true");

      const data = await response.json();
      setEPinRequests(data.AllePinRequest);
      setTitle("Rejected Requests");
    } catch (err) {
      console.log(err);
    }
  };
  const fetchNewRequests = async () => {
    try {
      const response = await fetch("https://api.ykfuture.in/api/v1/epinrequests/getallrequest?isNewRequest=true");

      const data = await response.json();
      setEPinRequests(data.AllePinRequest);
      setTitle("New Requests");
    } catch (err) {
      console.log(err);
    }
  };

  const handleClose = () => {
    setEditModal(false);
  };

  const handleClickOpen = () => {
    setEditModal(true);
  };

  const acceptTheRequest = async (e) => {
    e.preventDefault();
    try {
      const userName = localStorage.getItem("username");
      const ePinData = {
        forUser: userName,
        totalAmount: epinAmount,
        numOfEpins: ePinNum,
      };

      const data = await fetch("https://api.ykfuture.in/api/v1/epins/createepins", {
        method: "POST",
        body: JSON.stringify(ePinData),
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (data.ok) {
        toast.success("Epins Created Successfully!! ", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }

      setTimeout(() => {
        history("/");
      }, 2300);
    } catch (err) {
      toast.error("Something Went Wrong ! Please Try Later", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  useEffect(() => {
    fetchAllEpinRequests();
  }, [epinAmount, ePinNum]);

  return (
    <>
      <div className="payment-methods-container">
        <div style={{ textAlign: "right", marginBottom: 20 }}>
          <Button variant="outlined" color="secondary" onClick={handleClickOpen}>
            Generate EPin for Self
          </Button>
          <Dialog open={editModal} onClose={handleClose}>
            <DialogTitle>Create EPins For Self</DialogTitle>
            <DialogContent>
              <form action="" onSubmit={acceptTheRequest}>
                <div className="dialog-form-part">
                  <div className="input-group">
                    <label htmlFor="epinnum">
                      Number of EPins <span>*</span>
                    </label>
                    <input
                      type="number"
                      placeholder="Enter Number of EPins"
                      name="epinnum"
                      onChange={ePinsNumberHandler}
                    />
                  </div>

                  <div className="input-group">
                    <label htmlFor="amount-each">
                      Amount for Each Epin <span>*</span>
                    </label>
                    <input
                      type="number"
                      placeholder="Enter Amount for each EPin"
                      name="amount-each"
                      onChange={amountHandler}
                    />
                  </div>
                  <div className="input-group">
                    <Button onClick={handleClose} color="error" style={{ marginRight: "10px" }}>
                      Cancel
                    </Button>
                    <Button type="submit" color="secondary">
                      Submit
                    </Button>
                  </div>
                </div>
              </form>
            </DialogContent>
          </Dialog>
        </div>
        <div className="filter-btn">
          <Button variant="outlined" color="primary" onClick={fetchNewRequests}>
            New Requests
          </Button>
          <Button variant="outlined" color="success" onClick={fetchAcceptedRequests}>
            Accepted Requests
          </Button>
          <Button variant="outlined" color="error" onClick={fetchRejecteddRequests}>
            Rejected Requests
          </Button>
          <Button variant="outlined" color="warning" onClick={fetchAllEpinRequests}>
            All Requests
          </Button>
        </div>
        <BasicTable type="epinrequests" width="100%" heading={title} data={ePinRequests} />
      </div>
      <ToastContainer />
    </>
  );
};

export default EPinRequests;
