import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import dayjs from "dayjs";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import AuthContext from "../../context/authContext";
import "./BasicTable.css";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    borderBottom: "4px #dddddd solid",
    // color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    // color: theme.palette.common.white,
    borderBottom: "1px #222222 solid",
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // backgroundColor: "#000000 ",
  // color: theme.palette.common.white,
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const UserDataTable = (props) => {
  // console.log(props);
  const AuthCntx = React.useContext(AuthContext);
  if (props.type === "activated_subs") {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>{props.name}</StyledTableCell>
              <StyledTableCell align="center">
                {props.columnOneHeading} <p>( rupees )</p>
              </StyledTableCell>
              <StyledTableCell align="center">
                {props.columnTwoHeading} <p>( days )</p>
              </StyledTableCell>
              <StyledTableCell align="center">
                {props.columnThreeHeading} <p>( grams )</p>
              </StyledTableCell>
              <StyledTableCell align="center">
                {props.columnFourHeading} <p>( % )</p>
              </StyledTableCell>
              <StyledTableCell align="center">
                {props.columnFiveHeading}
                <p>( rupees )</p>
              </StyledTableCell>
              <StyledTableCell align="center">
                {props.columnSixHeading} <p>( rupees )</p>
              </StyledTableCell>

              {AuthCntx.role === "admin" ? <StyledTableCell align="center">More Actions</StyledTableCell> : ""}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((row) => (
              <StyledTableRow key={row._id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <StyledTableCell component="th" scope="row">
                  {row.subscriptionName}
                </StyledTableCell>
                <StyledTableCell align="center">{row.subscriptionAmount}</StyledTableCell>
                <StyledTableCell align="center">{row.subscriptionValidity}</StyledTableCell>
                <StyledTableCell align="center">{row.goldLimit}</StyledTableCell>
                <StyledTableCell align="center">{row.goldDiscount}</StyledTableCell>
                <StyledTableCell align="center">{row.referralCommision ? row.referralCommision : "-"}</StyledTableCell>
                <StyledTableCell align="center">{row.perSectionIncome}</StyledTableCell>

                {AuthCntx.role === "admin" ? (
                  <StyledTableCell align="center">
                    <div className="more-icons-wrapper">
                      <Tooltip title="Edit Package" arrow>
                        <EditIcon color="secondary" onClick={() => props.logging(row._id)} />
                      </Tooltip>
                    </div>
                  </StyledTableCell>
                ) : (
                  ""
                )}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else if (props.type === "avilable_coup" && AuthCntx.role === "user") {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>{props.name}</StyledTableCell>
              <StyledTableCell align="center">{props.columnOneHeading}</StyledTableCell>
              <StyledTableCell align="center">{props.columnTwoHeading}</StyledTableCell>
              <StyledTableCell align="center">{props.columnThreeHeading}</StyledTableCell>
              <StyledTableCell align="center">{props.columnFourHeading}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((row) => (
              <StyledTableRow key={row._id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <StyledTableCell component="th" scope="row">
                  {row.couponCode}
                </StyledTableCell>
                <StyledTableCell align="center">{row.goldBalance}</StyledTableCell>
                <StyledTableCell align="center">{row.goldLimit}</StyledTableCell>
                <StyledTableCell align="center">{row.discountOnGold}%</StyledTableCell>
                <StyledTableCell align="center">{dayjs(row.expiresAt).format("DD MMM YYYY")}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>{props.name}</StyledTableCell>
              <StyledTableCell align="center">
                {props.columnOneHeading} <p>( rupees )</p>
              </StyledTableCell>
              <StyledTableCell align="center">
                {props.columnTwoHeading} <p>( days )</p>
              </StyledTableCell>
              <StyledTableCell align="center">
                {props.columnThreeHeading} <p>( grams )</p>
              </StyledTableCell>
              <StyledTableCell align="center">
                {props.columnFourHeading} <p>( % )</p>
              </StyledTableCell>
              <StyledTableCell align="center">
                {props.columnFiveHeading}
                <p>( rupees )</p>
              </StyledTableCell>
              <StyledTableCell align="center">
                {props.columnSixHeading} <p>( rupees )</p>
              </StyledTableCell>

              {AuthCntx.role === "admin" ? <StyledTableCell align="center">More Actions</StyledTableCell> : ""}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((row) => (
              <StyledTableRow key={row._id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <StyledTableCell component="th" scope="row">
                  {row.subscriptionName}
                </StyledTableCell>
                <StyledTableCell align="center">{row.subscriptionAmount}</StyledTableCell>
                <StyledTableCell align="center">{row.subscriptionValidity}</StyledTableCell>
                <StyledTableCell align="center">{row.goldLimit}</StyledTableCell>
                <StyledTableCell align="center">{row.goldDiscount}</StyledTableCell>
                <StyledTableCell align="center">{row.referralCommision ? row.referralCommision : "-"}</StyledTableCell>
                <StyledTableCell align="center">{row.perSectionIncome}</StyledTableCell>

                {AuthCntx.role === "admin" ? (
                  <StyledTableCell align="center">
                    <div className="more-icons-wrapper">
                      <Tooltip title="Edit Package" arrow>
                        <EditIcon color="secondary" onClick={() => props.logging(row._id)} />
                      </Tooltip>
                    </div>
                  </StyledTableCell>
                ) : (
                  ""
                )}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
};

export default UserDataTable;
