import React, { useState, useEffect } from "react";
import BasicTable from "../Tables/BasicTable";
import Button from "@mui/material/Button";
import "react-toastify/dist/ReactToastify.css";

const KycRequests = () => {
  const [kycRequests, setKycRequests] = useState([]);

  const [title, setTitle] = useState("");

  const fetchAllKycRequests = async () => {
    try {
      const response = await fetch("https://api.ykfuture.in/api/v1/kyc/get-all-requests");

      const data = await response.json();
      setKycRequests(data.kycRequests);
      setTitle("All Kyc Requests");
    } catch (err) {
      console.log(err);
    }
  };

  const fetchAcceptedRequests = async () => {
    try {
      const response = await fetch("https://api.ykfuture.in/api/v1/kyc/get-all-requests?isAccepted=true");

      const data = await response.json();
      setKycRequests(data.kycRequests);
      setTitle("Accepted Requests");
    } catch (err) {
      console.log(err);
    }
  };

  const fetchRejecteddRequests = async () => {
    try {
      const response = await fetch("https://api.ykfuture.in/api/v1/kyc/get-all-requests?isCancelled=true");

      const data = await response.json();
      setKycRequests(data.kycRequests);
      setTitle("Rejected Requests");
    } catch (err) {
      console.log(err);
    }
  };
  const fetchNewRequests = async () => {
    try {
      const response = await fetch("https://api.ykfuture.in/api/v1/kyc/get-all-requests?isNewRequest=true");

      const data = await response.json();
      setKycRequests(data.kycRequests);
      setTitle("New Requests");
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchAllKycRequests();
  }, []);

  return (
    <>
      <div className="payment-methods-container">
        <div className="filter-btn">
          <Button variant="outlined" color="primary" onClick={fetchNewRequests}>
            New Requests
          </Button>
          <Button variant="outlined" color="success" onClick={fetchAcceptedRequests}>
            Accepted Requests
          </Button>
          <Button variant="outlined" color="error" onClick={fetchRejecteddRequests}>
            Rejected Requests
          </Button>
          <Button variant="outlined" color="warning" onClick={fetchAllKycRequests}>
            All Requests
          </Button>
        </div>
        <BasicTable type="kycrequests" width="100%" heading={title} data={kycRequests} />
      </div>
    </>
  );
};

export default KycRequests;
