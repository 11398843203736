import React, { useContext, useState, useEffect } from "react";
import BasicTable from "../Tables/BasicTable";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import AuthContext from "../../context/authContext";
import "./walletComponent.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const WalletComponent = () => {
  const AuthCntx = useContext(AuthContext);

  const userData = JSON.parse(localStorage.getItem("userdata"));

  const [walletBalance, setWalletBalance] = useState("");
  const [totalWalletBalance, setTotalWalletBalance] = useState("");
  const [luBalance, setLUBalance] = useState();
  const [walletTransactions, setWalletTransactions] = useState([]);

  const [open, setOpen] = useState(false);

  const [widthdrawlAmount, setWithdrawlAmount] = useState("");

  const getWalletById = async () => {
    try {
      const res = await axios(`https://api.ykfuture.in/api/v1/wallet/findwallet/${AuthCntx.user_id}`);
      setWalletBalance(res.data.wallet.walletBalance);
      setTotalWalletBalance(res.data.wallet.totalBalance);
      setLUBalance(res.data.wallet.lUBalance);
    } catch (err) {
      console.log(err);
    }
  };

  const getWalletTransactions = async () => {
    try {
      const res = await axios(`https://api.ykfuture.in/api/v1/wallet/wallettransactions/${AuthCntx.user_id}`);

      setWalletTransactions(res.data.transactions);
    } catch (err) {
      console.log(err);
    }
  };

  const openRequestModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const createNewRequestHandler = async (e) => {
    e.preventDefault();
    try {
      const data = {
        user: localStorage.getItem("id"),
        requestedAmount: widthdrawlAmount,
        userName: localStorage.getItem("username"),
      };

      const res = await axios.post("https://api.ykfuture.in/api/v1/redeem-request/create-request", data);

      if (res.status === 200) {
        toast.success("Request Sent Successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }

      setOpen(false);
      setWithdrawlAmount("");
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getWalletById();
    getWalletTransactions();
  }, []);

  return (
    <div className="epinrequestcontainer">
      <div className="wallet-container">
        <div className="wallet-balance">
          <span className="balance-heading">Income : ₹ {walletBalance}</span>
          <span className="balance-heading">Leadership Reward : ₹ {luBalance}</span>
          <span className="balance-heading">Total : ₹ {walletBalance + luBalance}</span>

          {/* {walletBalance > 200 && ( */}
          {userData.kycStatus === "pending" && (
            <div>
              <Button variant="outlined" disabled>
                Make A Withdrawl Request
              </Button>
              <p style={{ color: "red", textAlign: "center" }}>Please Verify Kyc First</p>
            </div>
          )}

          {userData.kycStatus === "verified" && (
            <Button variant="outlined" onClick={openRequestModal}>
              Make A Withdrawl Request
            </Button>
          )}

          {/* )} */}

          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Make A Withdrawl Request</DialogTitle>
            <DialogContent>
              <form action="" onSubmit={createNewRequestHandler}>
                <div className="dialog-form-part" style={{ width: "100%" }}>
                  <div className="input-group">
                    <label htmlFor="amount">
                      Withdrawl Amount <span>*</span>
                    </label>
                    <input
                      type="number"
                      placeholder="Enter Subscription Name"
                      name="amount"
                      min="200"
                      // max={walletBalance}
                      value={widthdrawlAmount}
                      onChange={(e) => {
                        setWithdrawlAmount(e.target.value);
                      }}
                      required
                    />
                  </div>

                  <div className="input-group">
                    <Button onClick={handleClose} color="error" style={{ marginRight: "10px" }}>
                      Cancel
                    </Button>
                    <Button type="submit" color="secondary">
                      Submit
                    </Button>
                  </div>
                </div>
              </form>
            </DialogContent>
          </Dialog>
        </div>
      </div>
      <div className="transaction-table">
        <BasicTable
          heading="Wallet Transactions"
          width="100%"
          type="walletTransactionsTable"
          data={walletTransactions}
        />
      </div>
      <ToastContainer />
    </div>
  );
};

export default WalletComponent;
