import { useState, useContext, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import siteLogo from "../../logo.png";
import mobSiteLogo from "../../mobile-logo.png";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Diversity3OutlinedIcon from "@mui/icons-material/Diversity3Outlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import DnsOutlinedIcon from "@mui/icons-material/DnsOutlined"; // import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined";
import RequestPageOutlinedIcon from "@mui/icons-material/RequestPageOutlined";
import AuthContext from "../../context/authContext";
import defaultProfileImg from "../../images/profile.png";

import "./navigation.css";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { FcGenealogy } from "react-icons/fc";
import { GrDirections } from "react-icons/gr";

const Navigation = (props) => {
  const [userDetails, setUserDetails] = useState([]);

  const [selected, setSelected] = useState(false);
  const [isTreeOptionVisible, setIsTreeOptionVisible] = useState(false);
  const handleTreeOption = () => {
    isTreeOptionVisible ? setIsTreeOptionVisible(false) : setIsTreeOptionVisible(true);
  };
  const clickHandler = () => {
    selected ? setSelected(false) : setSelected(true);
  };

  const AuthCntx = useContext(AuthContext);

  const userName = localStorage.getItem("username");
  const userData = JSON.parse(localStorage.getItem("userdata"));

  const userId = localStorage.getItem("id");

  const getUserDetails = useCallback(async () => {
    try {
      const response = await axios.get(`https://api.ykfuture.in/api/v1/user/${userId}`);
      setUserDetails(response.data.user);
    } catch (err) {
      console.log(err);
    }
  }, [userId]);

  useEffect(() => {
    getUserDetails();
  }, [getUserDetails]);

  return (
    <>
      <div className="nav" id="nav">
        <div className="nav-wrapper">
          <div className="left-side">
            <Link to="/">
              <div className="logo">
                <img src={mobSiteLogo} alt="site-logo" />
              </div>
            </Link>
            <div className="__edit">
              <Link to="/profile">
                <Tooltip title="Edit Profile" arrow>
                  <Button>
                    <EditIcon />
                  </Button>
                </Tooltip>
              </Link>
              <div onClick={() => clickHandler(!selected)}>{selected ? <CloseIcon /> : <MenuIcon />}</div>
            </div>
          </div>
          <div className="right-side">
            <div className="account-name">
              <div className="profileImgContainer">
                <img src={userDetails.profileImg ? userDetails.profileImg : defaultProfileImg} alt="profile-img" />
              </div>
              <span style={{ color: "#1976d2" }}>{userName}</span>
              {/* <ArrowDropDownOutlinedIcon color="primary" /> */}
              <Link to="/profile">
                <Tooltip title="Edit Profile" arrow>
                  <Button>
                    <EditIcon />
                  </Button>
                </Tooltip>
              </Link>

              <Button variant="contained" color="secondary" onClick={AuthCntx.logout}>
                Logout
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* <div className={selected ? "sidebar show" : "sidebar none"}> */}
      <div className={selected ? "mobile-nav show" : "mobile-nav none"}>
        <div className="top">
          <img src={siteLogo} alt="site-logo" />
        </div>
        <div className="bottom">
          <ul>
            {/* Both */}
            <li>
              <Link to="/">
                <DashboardIcon /> Dashboard
              </Link>
            </li>

            {userData.isActive && (
              <>
                {" "}
                <li>
                  <Link to="/adduser">
                    <AccountTreeOutlinedIcon /> Add New User
                  </Link>
                </li>
                <li onClick={handleTreeOption} className="treeContainer has-dropdown">
                  <span>
                    {" "}
                    <Diversity3OutlinedIcon />
                    Tree
                  </span>
                  {isTreeOptionVisible ? <BiChevronUp /> : <BiChevronDown />}
                </li>
                {isTreeOptionVisible && (
                  <>
                    {" "}
                    <li className="pl_1em">
                      <Link to="/genelogy">
                        <FcGenealogy /> Genelogy
                      </Link>
                    </li>
                    <li className="pl_1em">
                      <Link to="/direct">
                        <GrDirections /> Direct
                      </Link>
                    </li>
                  </>
                )}
              </>
            )}

            {AuthCntx.role === "admin" && (
              <>
                <li>
                  <Link to="/packages">
                    <DnsOutlinedIcon /> Packages
                  </Link>
                </li>

                <li>
                  <Link to="/epinpaymentmethods">
                    <SimCardDownloadOutlinedIcon />
                    ePin Payment Methods
                  </Link>
                </li>

                <li>
                  <Link to="/allepinrequests">
                    <SimCardDownloadOutlinedIcon />
                    ePin Requests
                  </Link>
                </li>

                <li>
                  <Link to="/redeemrequests">
                    <SimCardDownloadOutlinedIcon />
                    Withdrawl Request
                  </Link>
                </li>
                <li>
                  <Link to="/kycrequests">
                    <SimCardDownloadOutlinedIcon />
                    KYC Request
                  </Link>
                </li>
                <li>
                  <Link to="/allusers">
                    <SimCardDownloadOutlinedIcon />
                    All users
                  </Link>
                </li>

                <li>
                  <Link to="/allboards">
                    <SimCardDownloadOutlinedIcon />
                    All Boards
                  </Link>
                </li>
              </>
            )}

            {AuthCntx.role === "user" && (
              <li>
                <Link to="/epins">
                  <RequestPageOutlinedIcon />
                  Epins
                </Link>
              </li>
            )}

            {AuthCntx.role === "user" && (
              <li>
                <Link to="/wallet">
                  <RequestPageOutlinedIcon />
                  Wallet
                </Link>
              </li>
            )}

            {AuthCntx.role === "user" && (
              <li>
                <Link to="/pvrecord">
                  <RequestPageOutlinedIcon />
                  PV Record
                </Link>
              </li>
            )}

            <div
              className="logoutBtn"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                bottom: 0,
                borderTop: "2px lightgray solid",
              }}
            >
              <Button color="secondary" onClick={AuthCntx.logout}>
                Logout
              </Button>
            </div>
          </ul>
        </div>
      </div>

      <div
        className={selected ? "sidebar-overlay show" : "sidebar-overlay none"}
        onClick={() => clickHandler(false)}
      ></div>
    </>
  );
};

export default Navigation;
