import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import Sidebar from "../../components/Sidebar/Sidebar";
import Navigation from "../../components/navigation/Navigation";
import AddNewPackage from "../../components/Forms/AddNewPackage";
import Footer from "../../components/Footer/Footer";
import PaymentForm from "../../components/Forms/PaymentForm";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MakeAEpinRequest = () => {
  const history = useNavigate();

  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [totalAmount, setTotalAmount] = useState();
  const [amountEach, setAmountEach] = useState();
  const [numEPin, setNumEPin] = useState();
  const [file, setFile] = useState();

  const [epinPaymentMethods, seEpinPaymentMethods] = useState([]);
  const fetchAllEpinPaymentMethods = async () => {
    await axios
      .get("https://api.ykfuture.in/api/v1/epinpaymentmethod/getall")
      .then((res) => {
        seEpinPaymentMethods(res.data.ePinPaymentMethods[0]);
      })
      .catch((err) =>
        toast.error(err.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        })
      );
  };
  useEffect(() => {
    fetchAllEpinPaymentMethods();
  }, []);

  const paymentFormShow = (data, amountEach, numEPin, totalAmount) => {
    setShowPaymentForm(data);
    setTotalAmount(totalAmount);
    setAmountEach(amountEach);
    setNumEPin(numEPin);
  };

  const setReceiptHandler = (file) => {
    setFile(file);
  };

  const bankTransferEPinRequest = async (e) => {
    e.preventDefault();
    try {
      const requestData = new FormData();

      requestData.append("requestedBy", localStorage.getItem("username"));
      requestData.append("requestedAmount", amountEach);
      requestData.append("numberOfEpins", numEPin);
      requestData.append("totalAmount", totalAmount);
      requestData.append("paymentMethod", "bank-transfer");
      requestData.append("receipt", file);
      requestData.append("status", "pending");

      const token = localStorage.getItem("token");

      const data = await axios.post(
        "https://api.ykfuture.in/api/v1/epinrequests/make-request/online-payment",
        requestData,
        {
          headers: {
            token: token,
          },
        }
      );

      if (data.status === 200) {
        toast.success("Epin Request Sent To Admin", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });

        setTimeout(() => {
          history("/epins");
        }, 2300);
      }
    } catch (err) {
      toast.error(err.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });

      setTimeout(() => {
        history("/epins");
      }, 2300);
    }
  };

  const cashPaymentRequest = async (e) => {
    e.preventDefault();
    try {
      const requestData = new FormData();

      requestData.append("requestedBy", localStorage.getItem("username"));
      requestData.append("requestedAmount", amountEach);
      requestData.append("numberOfEpins", numEPin);
      requestData.append("totalAmount", totalAmount);
      requestData.append("paymentMethod", "cash");
      requestData.append("receipt", file);
      requestData.append("status", "pending");

      const token = localStorage.getItem("token");

      const res = await axios.post(
        "https://api.ykfuture.in/api/v1/epinrequests/make-request/online-payment",
        requestData,
        {
          headers: {
            token: token,
          },
        }
      );

      if (res.status === 200) {
        toast.success("Epins Request Sent To Admin", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (err) {
      toast.error(err.message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  return (
    <div id="boardpage" className="page">
      <Sidebar />
      <div className="right-container">
        <Navigation />
        <div className="rest">
          <div className="epinrequestcontainer">
            <AddNewPackage type="requestForEPin" paymentForm={paymentFormShow} showPaymentForm={showPaymentForm} />
          </div>
          <div className={showPaymentForm ? "" : "displayNone"}>
            <PaymentForm
              data={epinPaymentMethods}
              bankTransferEPinRequest={bankTransferEPinRequest}
              cashPaymentHandler={cashPaymentRequest}
              receiptHandler={setReceiptHandler}
              amountTotal={totalAmount}
              amountEach={amountEach}
              numEPin={numEPin}
            />
          </div>
        </div>

        <Footer />
        <ToastContainer />
      </div>
    </div>
  );
};

export default MakeAEpinRequest;
