import React, { useRef } from "react";
import generatePDF from "react-to-pdf";
import siteLogo from "../../logo.png";

import "./invoiceComponent.css";

const InvoiceComponent = () => {
  const targetRef = useRef();
  return (
    <div className="invoice_component_container" ref={targetRef}>
      <button onClick={() => generatePDF(targetRef, { filename: "page.pdf" })}>Download PDF</button>
      <div className="top_of_invoice">
        <div className="left_side">
          <div className="logo">
            <img src={siteLogo} alt="" />
          </div>
        </div>
        <div className="right_side">
          <div className="company_details">company details</div>
          <div className="company_details">Billing Details</div>
        </div>
      </div>
      <div className="mid_of_invoice">
        <table>
          <tbody>
            <tr>
              <th>S.No</th>
              <th>Subscription Name</th>
              <th>Subscription Details</th>
              <th>Subscription Amount</th>
              <th>Gst</th>
              <th>Total Amount</th>
            </tr>
            <tr>
              <td>1</td>
              <td>Smart</td>
              <td>Details Will BE Here</td>
              <td>10000</td>
              <td>300</td>
              <td>10300</td>
            </tr>
            <tr>
              <td colSpan={5} style={{ textAlign: "right", paddingRight: "30px" }}>
                Total
              </td>
              <td>10300</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="foot_of_invoice">
        <p>
          This is an computer generated bill. No Need to be signed. <span>*</span>
        </p>
      </div>
    </div>
  );
};

export default InvoiceComponent;
