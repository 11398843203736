import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import siteLogo from "../../logo.png";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import DnsOutlinedIcon from "@mui/icons-material/DnsOutlined";
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined";
import RequestPageOutlinedIcon from "@mui/icons-material/RequestPageOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { ImTree } from "react-icons/im";
import { FcGenealogy } from "react-icons/fc";
import { GrDirections } from "react-icons/gr";
import "./sidebar.css";
import AuthContext from "../../context/authContext";

const Sidebar = () => {
  const userData = JSON.parse(localStorage.getItem("userdata"));

  const AuthCntx = useContext(AuthContext);
  const [isTreeOptionVisible, setIsTreeOptionVisible] = useState(false);
  const handleTreeOption = () => {
    isTreeOptionVisible ? setIsTreeOptionVisible(false) : setIsTreeOptionVisible(true);
  };
  return (
    <div className="sidebar">
      <div className="top">
        <img src={siteLogo} alt="site-logo" />
      </div>
      <div className="bottom">
        <ul>
          {/* Both */}
          <li>
            <Link to="/">
              <DashboardIcon /> Dashboard
            </Link>
          </li>

          {userData.isActive && (
            <>
              <li>
                <Link to="/adduser">
                  <AccountTreeOutlinedIcon /> Add New User
                </Link>
              </li>

              <li onClick={handleTreeOption} className="treeContainer">
                <span>
                  {" "}
                  <ImTree />
                  Tree
                </span>
                {isTreeOptionVisible ? <BiChevronUp /> : <BiChevronDown />}
              </li>

              {isTreeOptionVisible && (
                <>
                  {" "}
                  <li>
                    <Link to="/genelogy">
                      <FcGenealogy /> Genelogy
                    </Link>
                  </li>
                  <li>
                    <Link to="/direct">
                      <GrDirections /> Direct
                    </Link>
                  </li>
                </>
              )}
            </>
          )}

          {AuthCntx.role === "admin" && (
            <>
              <li>
                <Link to="/packages">
                  <DnsOutlinedIcon /> Packages
                </Link>
              </li>

              <li>
                <Link to="/epinpaymentmethods">
                  <SimCardDownloadOutlinedIcon />
                  ePin Payment Methods
                </Link>
              </li>

              <li>
                <Link to="/allepinrequests">
                  <SimCardDownloadOutlinedIcon />
                  ePin Requests
                </Link>
              </li>

              <li>
                <Link to="/redeemrequests">
                  <SimCardDownloadOutlinedIcon />
                  Withdrawl Request
                </Link>
              </li>

              <li>
                <Link to="/kycrequests">
                  <SimCardDownloadOutlinedIcon />
                  KYC Request
                </Link>
              </li>

              <li>
                <Link to="/allusers">
                  <SimCardDownloadOutlinedIcon />
                  All users
                </Link>
              </li>

              <li>
                <Link to="/allboards">
                  <SimCardDownloadOutlinedIcon />
                  All Boards
                </Link>
              </li>

              <li>
                <Link to="/tickets">
                  <AccountTreeOutlinedIcon /> Support
                </Link>
              </li>
            </>
          )}

          {userData.isActive && AuthCntx.role === "user" && (
            <>
              <li>
                <Link to="/wallet">
                  <AccountBalanceWalletOutlinedIcon />
                  Wallet
                </Link>
              </li>
              <li>
                <Link to="/pvrecord">
                  <RequestPageOutlinedIcon />
                  LeaderShip Units
                </Link>
              </li>
            </>
          )}

          {AuthCntx.role === "user" && (
            <>
              <li>
                <Link to="/epins">
                  <RequestPageOutlinedIcon />
                  EPins
                </Link>
              </li>
              <li>
                <Link to="/buynewsubscription">
                  <RequestPageOutlinedIcon />
                  Subscriptions
                </Link>
              </li>

              <li>
                <Link to="/tickets">
                  <AccountTreeOutlinedIcon /> Support
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
