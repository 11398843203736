import { useContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import AddUser from "./pages/AddUser/AddUser";
import BoardPage from "./pages/Board/BoardPage";
import Payment from "./pages/Payment/Payment";
import EPinPaymentMethods from "./pages/Payment/EPinPaymentMethods";
import Packages from "./pages/Packages/Packages";
import Login from "./pages/login/Login";
import AuthContext from "./context/authContext";
import Profile from "./pages/Profile/Profile";
import MakeAEpinRequest from "./pages/EpinRequests/MakeAEpinRequest";
import AllEpinRequests from "./pages/EpinRequests/AllEpinRequestes";
import EPinRequestDeails from "./pages/EpinRequests/EPinRequestDeails";
import WalletPage from "./pages/Wallet/WalletPage";
import PVRecordPage from "./pages/PVRecord/PVRecordPage";
import BuyNewSubscriptionPage from "./pages/BuyNewSubscription/BuyNewSubscription";
import AllUser from "./pages/AllUserPage/AllUser";
import UserDetails from "./pages/AllUserPage/UserDetails";
import NotFound from "./pages/NotFound/NotFound";
import Epins from "./pages/EpinRequests/Epins";
import Genelogy from "./pages/Genelogy/Genelogy";
import AllBoardsPage from "./pages/Board/AllBoardsPage";
import RedeemRequests from "./pages/Redeem/RedeemRequests";
import RequestDetailsPage from "./pages/Redeem/RequestDetailsPage";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import AllKycRequests from "./pages/KycRequests/AllKycRequests";
import KycDetails from "./components/KYC/KycDetails";
import Invoice from "./pages/Invoice/Invoice";
import SupportTicket from "./pages/supportTicket/SupportTicket";
import TicketDetails from "./pages/supportTicket/TicketDetails";
import "./App.css";

function App() {
  const AuthCntxt = useContext(AuthContext);
  let userData;

  if (AuthCntxt.isLoggedIn) {
    userData = JSON.parse(localStorage.getItem("userdata"));
  }

  return (
    <Router>
      <Routes>
        {!AuthCntxt.isLoggedIn && <Route path="/reset-password/:token" element={<ResetPassword />} />}

        {/* Both */}
        {/* {!AuthCntxt.isLoggedIn && <Route path="/" element={<Login />} />}
        {AuthCntxt.isLoggedIn && <Route path="/" element={<Home />} />} */}
        {AuthCntxt.isLoggedIn ? <Route path="/" element={<Home />} /> : <Route path="/" element={<Login />} />}
        {/* {AuthCntxt.isLoggedIn && userData.isActive && <Route path="adduser" element={<AddUser />} />} */}

        {AuthCntxt.isLoggedIn ? (
          userData.isActive && <Route path="adduser" element={<AddUser />} />
        ) : (
          <Route path="/" element={<Login />} />
        )}

        {AuthCntxt.isLoggedIn ? (
          userData.isActive && <Route path="genelogy" element={<Genelogy />} />
        ) : (
          <Route path="/" element={<Login />} />
        )}

        {AuthCntxt.isLoggedIn ? (
          <Route path="profile" element={<Profile />} />
        ) : (
          <Route path="profile" element={<Login />} />
        )}

        {AuthCntxt.isLoggedIn ? (
          userData.isActive && (
            <Route path="board">
              <Route path="boardview/:id" element={<BoardPage />} />
            </Route>
          )
        ) : (
          <Route path="board" element={<Login />} />
        )}

        {AuthCntxt.isLoggedIn && userData.isActive && <Route path="payment" element={<Payment />} />}
        {AuthCntxt.isLoggedIn && userData.isActive && <Route path="tickets" element={<SupportTicket />} />}
        {AuthCntxt.isLoggedIn && userData.isActive && (
          <Route path="tickets">
            <Route path=":id" element={<TicketDetails />} />
          </Route>
        )}

        {/* {AuthCntxt.isLoggedIn && } */}

        {/* ADMIN ------- ADMIN */}

        {AuthCntxt.isLoggedIn && AuthCntxt.role === "admin" && (
          <>
            <Route path="allepinrequests">
              <Route index element={<AllEpinRequests />} />
              <Route path=":id" element={<EPinRequestDeails />} />
            </Route>

            <Route path="redeemrequests">
              <Route index element={<RedeemRequests />} />
              <Route path=":id" element={<RequestDetailsPage />} />
            </Route>

            <Route path="packages" element={<Packages />} />

            <Route path="allusers">
              <Route index element={<AllUser />} />
              <Route path="user/:id" element={<UserDetails />} />
            </Route>

            <Route path="allboards">
              <Route index element={<AllBoardsPage />} />
              <Route path="boardview/:id" element={<BoardPage />} />
            </Route>
            <Route path="epinpaymentmethods" element={<EPinPaymentMethods />} />

            <Route path="kycrequests">
              <Route index element={<AllKycRequests />} />
              <Route path=":id" element={<KycDetails />} />
            </Route>
          </>
        )}
        {/* USER ------------------- USER ---------------- */}

        {AuthCntxt.isLoggedIn && AuthCntxt.role === "user" && (
          <>
            <Route path="buynewsubscription" element={<BuyNewSubscriptionPage />} />

            {userData.isActive && <Route path="pvrecord" element={<PVRecordPage />} />}

            {userData.isActive && <Route path="invoice" element={<Invoice />} />}

            {userData.isActive && <Route path="wallet" element={<WalletPage />} />}

            <Route path="makeaepinrequest" element={<MakeAEpinRequest />} />
            <Route path="epins" element={<Epins />} />
          </>
        )}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
