import React from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import Navigation from "../../components/navigation/Navigation";
import EpinPaymentMethods from "../../components/utils/EpinPaymentMethods";

const EPinPaymentMethods = () => {
  return (
    <div id="epinpaymentmethods" className="page">
      <Sidebar />
      <div className="right-container">
        <Navigation />
        <div className="rest">
          <EpinPaymentMethods />
        </div>
      </div>
    </div>
  );
};

export default EPinPaymentMethods;
