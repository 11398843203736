import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const AddNewPackage = (props) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const [amountEach, setAmountEach] = useState();
  const [ePinsNumber, setEPinsNumber] = useState();
  const [totalAmount, setTotalAmount] = useState();
  const [hideMe, setHideMe] = useState(props.showPaymentForm);

  const amountHandler = (e) => {
    setAmountEach(e.target.value);
  };

  const ePinsNumberHandler = (e) => {
    setEPinsNumber(e.target.value);
  };

  useEffect(() => {
    setTotalAmount(parseInt(amountEach) * parseInt(ePinsNumber));
  }, [amountEach, ePinsNumber, totalAmount]);

  const submitHandler = (e) => {
    e.preventDefault();
    setHideMe(true);
    props.paymentForm(true, amountEach, ePinsNumber, totalAmount);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (props.type === "addCategory") {
    return (
      <form action="">
        <div className="dialog-form-part">
          <div className="input-group">
            <label htmlFor="name">
              Subscription Name <span>*</span>
            </label>
            <input type="text" placeholder="Enter Subscription Name" name="name" required />
          </div>
          <div className="input-group">
            <label htmlFor="amount">
              Subscription Amount <span>*</span>
            </label>
            <input type="number" inputMode="numeric" placeholder="Enter Amount" name="amount" required />
          </div>
          <div className="input-group">
            <label htmlFor="validity">
              Subscription Validity <span>*</span>
            </label>
            <input type="number" placeholder="Enter Validity" name="validity" required />
          </div>
          <div className="input-group">
            <label htmlFor="limit">
              Gold Limit <span>*</span>
            </label>
            <input type="number" placeholder="Enter Gold Limit" name="limit" required />
          </div>
          <div className="input-group">
            <label htmlFor="discount">
              Gold Discount <span>*</span>
            </label>
            <input type="number" placeholder="Enter Gold Discount" name="discount" required />
          </div>
          <div className="input-group">
            <label htmlFor="commission">
              Referral Commision <span>*</span>
            </label>
            <input type="number" placeholder="Enter Referral Commision" name="commission" required />
          </div>
          <div className="input-group">
            <Button onClick={handleClose} color="error" style={{ marginRight: "10px" }}>
              Cancel
            </Button>
            <Button type="submit" color="secondary">
              Submit
            </Button>
          </div>
        </div>
      </form>
    );
  } else if (props.type === "requestForEPin") {
    return (
      <form action="" onSubmit={submitHandler} className={hideMe ? "displayNone" : ""}>
        <div className="epin-form-part">
          <span className="part-heading">Request For Epins</span>
          <div className="input-group">
            <label htmlFor="amount-one">
              Amount (for each ePin) <span>*</span>
            </label>
            <input
              type="number"
              inputMode="numeric"
              placeholder="Enter Amount For One Epin"
              name="amount-one"
              required
              onChange={amountHandler}
            />
          </div>
          <div className="input-group">
            <label htmlFor="number">
              Number Of Epins <span>*</span>
            </label>
            <input
              type="number"
              inputMode="numeric"
              placeholder="Number of Epins You Need"
              name="number"
              required
              onChange={ePinsNumberHandler}
            />
          </div>
          <div className="input-group">
            <p>
              Total Amount:
              {totalAmount ? totalAmount : " 00"}
            </p>
          </div>
          <div className="input-group gap-1em">
            <Button onClick={() => navigate(-1)} color="error" variant="outlined" type="submit">
              Back
            </Button>
            <Button type="submit" color="success" variant="outlined">
              Next
            </Button>
          </div>
        </div>
      </form>
    );
  }
};

export default AddNewPackage;
